export const headCellsContributors = [
    {
        id: 'contactID',
        label: 'N°',
        isSortable: true
    },
    {
        id: 'contributor',
        label: 'Collaborateur',
        isSortable: true
    },
    {
        id: 'email',
        label: 'Email',
        isSortable: true
    },
    {
        id: 'creationDate',
        label: 'Date de création',
        isSortable: true
    },
    {
        id: 'validated',
        label: 'Validé',
    },
    {
        id: 'send-email',
        label: "Renvoyer l'email de validation",
    },
    {
        id: 'modify',
        label: 'Modifier le profil',
    },
    {
        id: 'suppr',
        label: 'Supprimer',
    },
]

export const headCellsAccess = [
    {
        id: 'type-icon',
    },
    {
        id: 'type',
        label: 'Type',
        isSortable: true
    },
    {
        id: 'pdl',
        label: 'PDL',
        isSortable: true
    },
    {
        id: 'label',
        label: 'Libellé',
        isSortable: true
    },
    {
        id: 'address',
        label: 'Adresse',
        isSortable: true
    },
    {
        id: 'access',
        label: 'Accès',
    },
]

export const headCellsMessages = [
    {
        id: 'messageID',
        label: 'N°',
        isSortable: true
    },
    {
        id: 'sujet',
        label: 'Objet',
        isSortable: true
    },
    {
        id: 'message',
        label: 'Description',
        isSortable: true
    },
    {
        id: 'dateCreation',
        label: 'Début de la discussion',
        isSortable: true
    },
    {
        id: 'dateModification',
        label: 'Dernier échange',
        isSortable: true
    },
    {
        id: 'discussion',
        label: 'Discussion',
    },
    {
        id: 'isNew',
        label: '',
    },
]

export const headCellsMandates = [
    {
        id: 'libelle',
        label: 'Référence AO',
        isSortable: true
    },
    {
        id: 'fichier',
        label: 'Mandat(s)',
        isSortable: true
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        isSortable: true
    },
    {
        id: 'dateDebutFourniture',
        label: 'Date de début',
        isSortable: true
    },
    {
        id: 'dateFinNouveauContrat',
        label: 'Date de fin',
        isSortable: true
    },
    {
        id: 'clientDateSignature',
        label: 'Date de signature',
        isSortable: true
    },
]

export const headCellsWattvalueInvoices = [
    {
        id: 'type',
        label: 'Type de facture',
        isSortable: true
    },
    {
        id: 'periode',
        label: 'Période',
        isSortable: true
    },
    {
        id: 'libelle',
        label: 'Libellé',
        isSortable: true
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        isSortable: true
    },
    {
        id: 'numFac',
        label: 'N° de la facture ',
        isSortable: true
    },
    {
        id: 'fichier',
        label: 'Facture',
        isSortable: true
    },
    {
        id: 'dateSignature',
        label: "Date d'émission de la facture",
        isSortable: true
    },
    {
        id: 'montantTTC',
        label: 'Montant TTC',
        isSortable: true
    },
]

export const headCellsSepaMandates = [
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        isSortable: true
    },
    {
        id: 'sites',
        label: 'Site(s) concerné(s)',
        isSortable: true
    },
    {
        id: 'adresse',
        label: 'Adresse',
        isSortable: true
    },
    {
        id: 'ribFichier',
        label: 'RIB',
        isSortable: true
    },
    {
        id: 'mandatFichier',
        label: 'Mandat SEPA',
        isSortable: true
    },
    {
        id: 'dateCreation',
        label: 'Date de création',
        isSortable: true
    },
]

export const headCellsGreenEnergy = [
    {
        id: 'annee',
        label: 'Année conso.',
        isSortable: true
    },
    {
        id: 'libelle',
        label: 'Libellé',
        isSortable: true
    },
    {
        id: 'period',
        label: 'Période',
        isSortable: true
    },
    {
        id: 'nombreGO',
        label: 'Nombre GO',
        isSortable: true
    },
    {
        id: 'attestationID',
        label: 'Attestation',
    },
]

export const headCellsInvoiceVerification = [
    {
        id: 'link',
        label: 'Consulter',
    },
    {
        id: 'statut',
        label: 'Statut',
        isSortable: true
    },
    {
        id: 'site',
        label: 'Site',
        isSortable: true
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        isSortable: true
    },
    {
        id: 'adresse',
        label: 'Adresse',
        isSortable: true
    },
    {
        id: 'prm',
        label: 'PRM',
        isSortable: true
    },
    {
        id: 'elecFournisseurNom',
        label: 'Fournisseur',
        isSortable: true
    },
    {
        id: 'dateDebutFourniture',
        label: 'Début de contrat',
        isSortable: true
    },
    {
        id: 'dateFinNouveauContrat',
        label: 'Fin de contrat',
        isSortable: true
    },
    {
        id: 'derniereFacture',
        label: 'Dernière facture vérifiée',
        isSortable: true
    },
    {
        id: 'anomalies',
        label: 'Détails',
        isSortable: true
    },
]

export const headCellsInvoiceVerificationDetail = (isDetail) => {
    let cells = [
        {
            id: 'fournisseurNom',
            label: 'Fournisseur',
            isSortable: true
        },
        {
            id: 'dateDebut',
            label: 'Début de relève',
            isSortable: true
        },
        {
            id: 'dateFin',
            label: 'Fin de relève',
            isSortable: true
        },
        {
            id: 'consommation',
            label: 'Conso. (kWh)',
            isSortable: true
        },
        {
            id: '',
            label: '',
        },
        {
            id: 'montantFacHT',
            label: 'Montant HTVA',
            colSpan: 2,
            isSortable: true
        },
        {
            id: 'montantFacTTC',
            label: 'Montant TTC',
            isSortable: true
        },
        {
            id: 'pourcentageEcartHT',
            label: 'Écart en %',
            isSortable: true,
            withPlus: true
        },
    ]

    if (isDetail) {
        cells.push(
            {
                id: 'statut',
                label: 'Statut',
                isSortable: true
            },
            {
                id: 'statutTraitement',
                label: 'Traitement',
                isSortable: true
            },
            {
                id: 'partiesListe',
                label: 'Parties avec anommalie',
                isSortable: true
            },
            {
                id: 'erreurListe',
                label: "Types d'anomalies",
                isSortable: true
            },)
    }

    if (isDetail) {
        cells.splice(1, 0, {
            id: 'reference',
            label: 'Réf. fournisseur',
            isSortable: true
        },)
    }

    return cells
}

export const headCellsInvoiceSitesVerification = [
    {
        id: '',
        label: 'Consulter',
    },
    {
        id: 'libelle',
        label: 'Site',
        isSortable: true
    },
    {
        id: 'raisonSociale',
        label: 'Raison sociale',
        isSortable: true
    },
    {
        id: 'prm',
        label: 'PRM',
        isSortable: true
    },
    {
        id: 'adresse',
        label: 'Adresse',
        isSortable: true
    },
    {
        id: 'fournisseur',
        label: 'Fournisseur',
        isSortable: true
    },
    {
        id: 'dateDebutFourniture',
        label: 'Date de début fourniture',
        isSortable: true
    },
    {
        id: 'dateFinNouveauContrat',
        label: 'Date de fin nouveau contrat',
        isSortable: true
    },
]

export const headCellsInvoices = [
    {
        id: 'fichier',
        label: 'Nom du fichier',
        isSortable: true
    },
    {
        id: 'dateCreation',
        label: "Date d'envoi",
        isSortable: true
    },
    {
        id: 'statut',
        label: 'Statut',
        isSortable: true
    },
    {
        id: 'commentaire',
        label: 'Commentaire',
        isSortable: true
    },
    {
        id: 'download',
        label: 'Télécharger',
    },
    {
        id: 'suppr',
        label: 'Supprimer',
    }
]

export const headCellsSites = (isElec, isDashboard) => {
    let cells = [
        {
            id: 'icon',
            label: '',
        },
        {
            id: 'appelOffre',
            label: "Appel d'offres",
            isSortable: !isDashboard
        },
        {
            id: 'libelle',
            label: 'Nom du site',
            isSortable: !isDashboard
        },
        {
            id: 'adresse',
            label: 'Adresse du site',
            isSortable: !isDashboard
        },
        {
            id: 'fournisseur',
            label: 'Fournisseur',
            isSortable: !isDashboard
        },
        {
            id: 'dateDebutFourniture',
            label: 'Date de début de fourniture',
            isSortable: !isDashboard
        },
        {
            id: 'dateFinNouveauContrat',
            label: 'Échéance',
            isSortable: !isDashboard
        },
        {
            id: 'prixParMWh',
            label: 'Prix unitaire HTVA',
            isSortable: !isDashboard
        },
        {
            id: 'prix',
            label: 'Budget annuel moyen estimé HTVA',
            isSortable: !isDashboard
        },
        {
            id: 'car',
            label: 'CAR N-1',
            isSortable: !isDashboard
        },
    ]

    if (isElec) {
        cells.splice(4, 0, {
            id: 'prm',
            label: 'PRM',
            isSortable: !isDashboard
        },)

        cells.splice(9, 0, {
            id: 'consommation',
            label: 'Consommation actuelle',
            isSortable: !isDashboard
        }, {
            id: 'facturation',
            label: 'Facturation depuis le 01/01',
            isSortable: !isDashboard
        },)
    }
    else {
        cells.splice(4, 0, {
            id: 'pce',
            label: 'PCE',
            isSortable: !isDashboard
        },)
    }

    if (!isDashboard) {
        cells.splice(1, 0, {
            id: 'modify',
            label: 'Modifier',
        }, {
            id: 'suppr',
            label: 'Supprimer',
        },)
    }

    return cells
}

export const headCellsAppelOffre = (isElec, isReengagement) => {
    let cells = [
        {
            id: 'libelle',
            label: 'Nom du site',
            isSortable: true
        },
        {
            id: 'adresse',
            label: "Adresse du site",
            isSortable: true
        },
        {
            id: 'pce',
            label: 'PCE',
            isSortable: true
        },
        {
            id: 'fournisseur',
            label: 'Fournisseur',
            isSortable: true
        },
        {
            id: 'dateDebutFourniture',
            label: 'Date de début du contrat',
            isSortable: true
        },
        {
            id: 'dateFinNouveauContrat',
            label: 'Date de fin du contrat',
            isSortable: true
        },
        {
            id: '',
            label: isReengagement ? 'Réengager le site' : 'Voir le site',
        },
        {
            id: '',
            label: 'Voir le récapitulatif',
        },
    ]

    if (isElec) {
        cells.splice(2, 1, {
            id: 'prm',
            label: 'PRM',
            isSortable: true
        },)
    }

    if (isReengagement) {
        cells.pop()
    }

    return cells
}

export const headCellsContracts = [
    {
        id: 'statut',
        label: 'Statut',
        isSortable: true
    },
    {
        id: 'fournisseur',
        label: "Fournisseur",
        isSortable: true
    },
    {
        id: 'contratFichier',
        label: 'Contrat de fourniture',
        isSortable: true
    },
    {
        id: 'cgvFichier',
        label: 'CGV du fournisseur',
        isSortable: true
    },
    {
        id: 'dateSignatureWattunity',
        label: "Date de négociation de votre appel d'offres",
        isSortable: true
    },
    {
        id: 'dateDebutFourniture',
        label: 'Date de début de contrat',
        isSortable: true
    },
    {
        id: 'dateFinNouveauContrat',
        label: 'Date de fin de contrat',
        isSortable: true
    },
]

export const headCellsConsoSites = [
    {
        id: 'siteID',
        label: 'ID du site',
        isSortable: true
    },
    {
        id: 'libelle',
        label: 'Site',
        isSortable: true
    },
    {
        id: 'raisonSociale',
        label: "Raison sociale",
        isSortable: true
    },
    {
        id: 'adresse',
        label: 'Adresse',
        isSortable: true
    },
    {
        id: 'periode',
        label: "Période d'activation",
        isSortable: true
    }
]

export const headCellsTurpeSites = [
    {
        id: 'icon',
        label: '',
    },
    {
        id: 'modify',
        label: 'Visualiser',
    },
    {
        id: 'libelle',
        label: "Site",
        isSortable: true
    },
    {
        id: 'adresse',
        label: 'Adresse',
        isSortable: true
    },
    {
        id: 'prm',
        label: 'PRM',
        isSortable: true
    },
    {
        id: 'totalEconomie',
        label: 'Economie réalisable',
        isSortable: true
    },
    {
        id: 'datePublication',
        label: 'Date de publication',
        isSortable: true
    }
]

export const headCellsTurpePuissance = [
    {
        id: '',
        label: '',
    },
    {
        id: 'pointe',
        label: "Pointe",
    },
    {
        id: 'hph',
        label: 'HPH',
    },
    {
        id: 'hch',
        label: 'HCH',
    },
    {
        id: 'hpe',
        label: 'HPE',
    },
    {
        id: 'hce',
        label: 'HCE',
    }
]

export const headCellsBudgetSitesTable = [
    {
        id: '',
        label: '',
    },
    {
        id: 'janvier',
        label: "Janvier",
    },
    {
        id: 'fevrier',
        label: 'Février',
    },
    {
        id: 'mars',
        label: 'Mars',
    },
    {
        id: 'avril',
        label: 'Avril',
    },
    {
        id: 'mai',
        label: 'Mai',
    },
    {
        id: 'juin',
        label: "Juin",
    },
    {
        id: 'juillet',
        label: 'Juillet',
    },
    {
        id: 'aout',
        label: 'Août',
    },
    {
        id: 'septembre',
        label: 'Septembre',
    },
    {
        id: 'octobre',
        label: 'Octobre',
    },
    {
        id: 'novembre',
        label: 'Novembre',
    },
    {
        id: 'decembre',
        label: 'Décembre',
    },
    {
        id: 'total',
        label: 'Total',
    },
    {
        id: 'ecart',
        label: 'Ecart',
    }
]