import { Fragment, useCallback, useEffect, useState } from "react"
import { useSearchParams, Link } from 'react-router-dom'

import SideBar from "../../../Components/SideBar/SideBar"
import axios from "axios"
import Button from "../../../Components/Button"
import FormInput from "../../../Components/Form/FormInput"
import CircleIcon from '@mui/icons-material/Circle'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label, LineChart, Line } from 'recharts'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { monthsOptions } from "../../../Utils/GraphUtils/monthsOptions"
import { daysOptions } from "../../../Utils/GraphUtils/daysOptions"
import { hoursOptions } from "../../../Utils/GraphUtils/hoursOptions"
import { Tooltip as GreyTooltip } from "@mui/material"
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Switch, { switchClasses } from '@mui/joy/Switch'
import { optionsSeuilConso } from "../../../Utils/DropDownOptions/optionsSeuilConso"
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { validEmail } from '../../../Utils/Regex/vallidEmail'
import { ConsoCustomTooltip } from "./ConsoCustomTooltip"
import InformationPopup from "../../../Components/InformationPopup"
import { lineColorsEnergy, lineColorsEuros } from "../../../Utils/GraphUtils/lineColors"

const apiUrl = process.env.REACT_APP_API_URL

function ConsoDetail() {
    const [isDataLoading, setIsDataLoading] = useState(false)
    const [isGraphDataLoading, setIsGraphDataLoading] = useState(false)
    const [isDataLoadingExport, setIsDataLoadingExport] = useState(false)

    const [searchParams] = useSearchParams()
    const [type, setType] = useState(searchParams.get('type'))
    const siteID = searchParams.get('siteID')
    const SuiviHebdo = searchParams.get('SuiviHebdo')

    const [data, setData] = useState([])
    const [comparisonData, setComparisonData] = useState([])
    const [detailData, setDetailData] = useState([])
    const [libelle, setLibelle] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [prm, setPrm] = useState(0)
    const [souscriptionType, setSouscriptionType] = useState('')

    const [monthsNumber, setmonthsNumber] = useState(0)
    const valueOptions = ['Euros', 'kWh']
    const [yearOptions, setYearOptions] = useState([])
    const [weekOptions, setWeekOptions] = useState([])
    const [dropdownOptions, setDropdownOptions] = useState([])

    const [hasBase, setHasBase] = useState(false)
    const [hasHp, setHasHp] = useState(false)
    const [hasHc, setHasHc] = useState(false)
    const [hasPointe, setHasPointe] = useState(false)
    const [hasPm, setHasPm] = useState(false)
    const [hasHph, setHasHph] = useState(false)
    const [hasHch, setHasHch] = useState(false)
    const [hasHpe, setHasHpe] = useState(false)
    const [hasHce, setHasHce] = useState(false)

    const [duree, setDuree] = useState("")
    const [lastDateModification, setLastDateModification] = useState(0)
    const [consoTotal, setConsoTotal] = useState(0)
    const [consoTotalPrecedent, setConsoTotalPrecedent] = useState(0)

    const [dateActivation, setDateActivation] = useState(0)
    const [dateNow, setDateNow] = useState(0)
    const [dateNowWeek, setDateNowWeek] = useState(0)
    const [dateMin, setDateMin] = useState(0)
    const [dateMinWeek, setDateMinWeek] = useState(0)
    const [dateMax, setDateMax] = useState(0)
    const [dateMaxWeek, setDateMaxWeek] = useState(0)
    const [horosaison, setHorosaison] = useState("")

    const [vueQuery, setVueQuery] = useState(type === "CDC" ? "Horaire" : "Mensuel")
    const [monthsQuery, setMonthsQuery] = useState(['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'])
    const [weeksQuery, setWeeksQuery] = useState([])
    const [daysQuery, setDaysQuery] = useState([])
    const [yearsQuery, setYearsQuery] = useState([])
    const [valueQuery, setValueQuery] = useState(['kWh'])

    const [showFilters, setShowFiltrers] = useState(false)
    const [showPopup, setShowPopup] = useState(false)

    const [isAlertActive, setIsAlertActive] = useState(false)
    const [seuilEnPourcentageValeur, setSeuilEnPourcentageValeur] = useState(0)
    const [seuilEnPourcentageLabel, setSeuilEnPourcentageLabel] = useState(0)
    const [alertRecipients, setAlertRecipients] = useState([])
    const [recipientEmail, setRecipientEmail] = useState("")
    const [errorMessageRecipient, setErrorMessageRecipient] = useState("Une erreur est survenue, veuillez réessayer plus tard")
    const [showErrorMessageRecipient, setShowErrorMessageRecipient] = useState(false)
    const [showConfirmationMessageRecipient, setShowConfirmationMessageRecipient] = useState(false)
    const [confirmationMessageRecipient, setConfirmationMessageRecipient] = useState("")

    const getConsoData = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/Conso/Graph/${siteID}${type === "CDC" ? "/CDC" : ""}/${vueQuery === "Mensuel" ? "Mensuel" : "Hebdo"}/Info`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                if (type === "Mensuel") {
                    response.data?.ville && setCity(response.data.ville)
                    response.data?.libelle && setLibelle(response.data.libelle)
                    response.data?.codePostal && setPostalCode(response.data.codePostal)
                    response.data?.prm && setPrm(response.data.prm)
                    response.data?.moisDisponibles && setmonthsNumber(response.data.moisDisponibles)
                    response.data?.lastDateModification && setLastDateModification(response.data.lastDateModification)
                }
                else {
                    response.data?.info?.ville && setCity(response.data.info.ville)
                    response.data?.info?.libelle && setLibelle(response.data.info.libelle)
                    response.data?.info?.codePostal && setPostalCode(response.data.info.codePostal)
                    response.data?.info?.prm && setPrm(response.data.info.prm)

                    response.data?.info?.dateActivation && setDateActivation(response.data.info.dateActivation)
                    response.data?.info?.dateNow?.date && setDateNow(response.data.info.dateNow.date)
                    response.data?.info?.dateNow?.week && setDateNowWeek(response.data.info.dateNow.week)
                    response.data?.info?.dateMin?.date && setDateMin(response.data.info.dateMin.date)
                    response.data?.info?.dateMin?.week && setDateMinWeek(response.data.info.dateMin.week)
                    response.data?.info?.dateMax?.date && setDateMax(response.data.info.dateMax.date)
                    response.data?.info?.dateMax?.week && setDateMaxWeek(response.data.info.dateMax.week)
                    response.data?.info?.horosaison && setHorosaison(response.data.info.horosaison)

                    if (response.data?.filtres?.annees?.length > 0) {
                        if (response.data.filtres.annees.length === 1) {
                            setYearsQuery(response.data.filtres.annees)
                        }
                        else {
                            setYearsQuery([response.data.filtres.annees[0]])
                        }
                        setYearOptions(response.data.filtres.annees)
                    }

                    if (response.data?.filtres?.semaines?.length > 0) {
                        SuiviHebdo ? setWeeksQuery([Number(SuiviHebdo), Number(SuiviHebdo) - 1]) : setWeeksQuery([dateMaxWeek, dateMaxWeek - 1])
                        setWeekOptions(response.data.filtres.semaines)
                    }

                    if (response.data?.filtres?.jours?.length > 0) {
                        setDaysQuery(response.data.filtres.jours)
                    }
                }

            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [siteID, type, vueQuery, dateMaxWeek, SuiviHebdo])

    const getGraphData = useCallback((nbMonths) => {
        setIsGraphDataLoading(true)
        axios.get(`${apiUrl}/Conso/Graph/${siteID}/Mensuel/Data?mois=${nbMonths}`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                let data = [...response.data?.data]
                data.forEach(element => {
                    let year = new Date(element.debut).getYear().toString()
                    if (year[0] === "1" && year.length === 3) year = year.slice(1)
                    element.yearLabel = `${monthsOptions[new Date(element.debut).getMonth()].moisLabel} ${year ? year : ""}`
                })
                setData(data)

                if (data.some(element => element.base !== 0)) setHasBase(true)
                if (data.some(element => element.hp !== 0)) setHasHp(true)
                if (data.some(element => element.hc !== 0)) setHasHc(true)
                if (data.some(element => element.pointe !== 0)) setHasPointe(true)
                if (data.some(element => element.pm !== 0)) setHasPm(true)
                if (data.some(element => element.hph !== 0)) setHasHph(true)
                if (data.some(element => element.hch !== 0)) setHasHch(true)
                if (data.some(element => element.hpe !== 0)) setHasHpe(true)
                if (data.some(element => element.hce !== 0)) setHasHce(true)

                setConsoTotal(response.data.total)
                setConsoTotalPrecedent(response.data.compTotal)

            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsGraphDataLoading(false))
    }, [siteID])

    const getCDCGraphData = useCallback(() => {
        const yearString = yearsQuery.join('&annee=')
        const monthString = monthsQuery.join('&mois=')
        const weekString = weeksQuery.join('&semaine=')
        const dayString = daysQuery.join('&jour=')

        axios.get(`${apiUrl}/Conso/Graph/${siteID}/CDC/${vueQuery}/Data?annee=${yearString}${vueQuery === "Mensuel" ? `&mois=${monthString}` : ""}${(vueQuery === "Journalier" || vueQuery === "Horaire") ? `&semaine=${weekString}&jour=${dayString}` : ""}`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                const graphData = []
                const detailData = []
                if (vueQuery === "Mensuel") {
                    monthsOptions.forEach(element => {
                        if (monthsQuery.includes(element.mois)) graphData.push(element)
                    })
                    response.data.forEach((year => {
                        detailData.push({ year: year.annee, totalEnergie: year.totalEnergie, totalEuros: year.totalEuros, isComplet: year.isComplet })

                        year.consommationParMois.forEach((element) => {
                            graphData.forEach(line => {
                                if (element.mois === line.moisNombre) {
                                    line[`energie${year.annee}`] = element["energie"]
                                    line[`euros${year.annee}`] = element["euros"]
                                }
                            })
                        })
                    }))
                }
                if (vueQuery === "Journalier") {
                    daysOptions.forEach(element => {
                        if (daysQuery.includes(element.jourNombre)) graphData.push(element)
                    })
                    response.data.forEach((day => {
                        detailData.push({ year: day.annee, week: day.semaine, totalEnergie: day.totalEnergie, totalEuros: day.totalEuros, isComplet: day.isComplet })

                        day.consommationParJour.forEach((element) => {
                            graphData.forEach(line => {
                                if (element.jour === line.jourNombre) {
                                    line[`energieS${day.semaine}A${day.annee}`] = element["energie"]
                                    line[`eurosS${day.semaine}A${day.annee}`] = element["euros"]
                                }
                            })
                        })
                    }))
                }
                if (vueQuery === "Horaire") {
                    hoursOptions.forEach(element => {
                        graphData.push(element)
                    })
                    response.data.forEach((hour => {
                        detailData.push({ year: hour.annee, week: hour.semaine, totalEnergie: hour.totalEnergie, totalEuros: hour.totalEuros, isComplet: hour.isComplet })

                        hour.consommationParHeure.forEach((element) => {
                            graphData.forEach(line => {
                                if (element.heure === line.heureNombre) {
                                    line[`energieS${hour.semaine}A${hour.annee}`] = element["energie"]
                                    line[`eurosS${hour.semaine}A${hour.annee}`] = element["euros"]
                                }
                            })
                        })
                    }))
                }
                setData(graphData)
                setDetailData(detailData)

                if (response.data.length === 2) {
                    let dataArray = [
                        {
                            totalEnergie: response.data[0].totalEnergie,
                            totalEuros: response.data[0].totalEuros,
                            isComplet: response.data[0].isComplet,
                            annee: response.data[0].annee
                        },
                        {
                            totalEnergie: response.data[1].totalEnergie,
                            totalEuros: response.data[1].totalEuros,
                            isComplet: response.data[1].isComplet,
                            annee: response.data[1].annee
                        }
                    ]
                    if (response.data[0].semaine) dataArray[0].semaine = response.data[0].semaine
                    if (response.data[1].semaine) dataArray[1].semaine = response.data[1].semaine
                    setComparisonData(dataArray)
                }
                if (response.data.length === 1 || response.data.length > 2) {
                    let dataArray = [
                        {
                            totalEnergie: response.data[response.data.length - 1].totalEnergie,
                            totalEuros: response.data[response.data.length - 1].totalEuros,
                            isComplet: response.data[response.data.length - 1].isComplet,
                            annee: response.data[response.data.length - 1].annee
                        }
                    ]
                    if (response.data[0].semaine) dataArray[0].semaine = response.data[0].semaine
                    setComparisonData(dataArray)
                }

            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [monthsQuery, siteID, vueQuery, yearsQuery, daysQuery, weeksQuery])

    const getAlertData = useCallback(() => {
        axios.get(`${apiUrl}/Conso/Alerte/${siteID}`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                setIsAlertActive(response.data.isActif)
                setSeuilEnPourcentageValeur(response.data.seuilEnPourcentage)
                setSeuilEnPourcentageLabel(`${response.data.seuilEnPourcentage}%`)
                setAlertRecipients(response.data.destinataires)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [siteID])

    const getContractData = useCallback(() => {
        axios.get(`${apiUrl}/Conso/Site/${siteID}`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                response.data?.souscriptionType && setSouscriptionType(response.data.souscriptionType)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [siteID])

    useEffect(() => {
        getConsoData()
    }, [getConsoData, vueQuery])

    useEffect(() => {
        if (type === "Mensuel") {
            let dropdownOptions = []
            if (monthsNumber > 12) {
                dropdownOptions.push({ label: "12 mois", value: 12 })
                setDuree("12 mois")
                getGraphData(12)
            }
            if (monthsNumber > 24) dropdownOptions.push({ label: "24 mois", value: 24 })
            if (monthsNumber > 34) dropdownOptions.push({ label: "36 mois", value: 36 })
            if (monthsNumber < 12 || monthsNumber === 12) {
                dropdownOptions.push({ label: `${monthsNumber} mois`, value: monthsNumber })
                setDuree(`${monthsNumber} mois`)
                getGraphData(monthsNumber)
            }
            setDropdownOptions(dropdownOptions)
        }
    }, [monthsNumber, getGraphData, type])

    useEffect(() => {
        if (type === "CDC") getCDCGraphData()
    }, [type, yearsQuery, getCDCGraphData])

    useEffect(() => {
        if (type === "CDC") getAlertData()
    }, [getAlertData, type])

    useEffect(() => {
        getContractData()
        if (type === "Mensuel") {
            setTimeout(() => {
                setShowPopup(true)
            }, 3000)
        }
    }, [getContractData, type])

    const handleDataExport = () => {
        setIsDataLoadingExport(true)
        axios.get(`${apiUrl}/Conso/Graph/${siteID}/${type === "CDC" ? "CDC/" : ""}${vueQuery}/${type === "CDC" ? "Data/" : ""}Export`,
            {
                withCredentials: true,
                responseType: 'blob',
            })
            .then((response) => {
                const href = window.URL.createObjectURL(new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))

                const link = document.createElement('a')
                link.href = href

                link.setAttribute('download', `Donnes-conso-elec`)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(href)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoadingExport(false))
    }

    const changeYearQuery = (year) => {
        let yearArray = [...yearsQuery]
        if (yearsQuery.includes(year)) {
            yearArray.splice(yearsQuery.indexOf(year), 1)
        }
        else {
            yearArray.push(year)
        }
        setYearsQuery(yearArray)
    }

    const changeMonthQuery = (month) => {
        let monthsArray = [...monthsQuery]
        if (monthsQuery.includes(month)) {
            monthsArray.splice(monthsQuery.indexOf(month), 1)
        }
        else {
            monthsArray.push(month)
        }
        setMonthsQuery(monthsArray)
    }

    const changeWeekQuery = (week) => {
        let weekArray = [...weeksQuery]
        if (weeksQuery.includes(week)) {
            weekArray.splice(weeksQuery.indexOf(week), 1)
        }
        else {
            weekArray.push(week)
        }
        setWeeksQuery(weekArray)
    }

    const changeValueQuery = (value) => {
        let valueArray = [...valueQuery]
        if (valueQuery.includes(value)) {
            valueArray.splice(valueQuery.indexOf(value), 1)
        }
        else {
            valueArray.push(value)
        }
        setValueQuery(valueArray)
    }

    const changeDayQuery = (day) => {
        let dayArray = [...daysQuery]
        if (daysQuery.includes(day)) {
            dayArray.splice(daysQuery.indexOf(day), 1)
        }
        else {
            dayArray.push(day)
        }
        setDaysQuery(dayArray)
    }

    const selectAllYears = () => {
        if (yearOptions.every(year => yearsQuery.includes(year))) {
            setYearsQuery([])
        }
        else {
            let newYearsQuery = []
            yearOptions.forEach(year => newYearsQuery.push(year))
            setYearsQuery(newYearsQuery)
        }
    }

    const selectAllMonths = () => {
        if (monthsOptions.every(month => monthsQuery.includes(month.mois))) {
            setMonthsQuery([])
        }
        else {
            let newMonthsQuery = []
            monthsOptions.forEach(month => newMonthsQuery.push(month.mois))
            setMonthsQuery(newMonthsQuery)
        }
    }

    const selectAllWeeks = () => {
        if (weekOptions.every(week => weeksQuery.includes(week))) {
            setWeeksQuery([])
        }
        else {
            let newWeeksQuery = []
            weekOptions.forEach(week => newWeeksQuery.push(week))
            setWeeksQuery(newWeeksQuery)
        }
    }

    const selectAllDays = () => {
        if (daysOptions.every(day => daysQuery.includes(day.jourNombre))) {
            setDaysQuery([])
        }
        else {
            let newDaysQuery = []
            daysOptions.forEach(day => newDaysQuery.push(day.jourNombre))
            setDaysQuery(newDaysQuery)
        }
    }

    const updateAlertSubscription = (isAlertActive, seuilEnPourcentageValeur) => {
        axios.put(`${apiUrl}/Conso/Alerte/${siteID}`,
            {
                "isActif": isAlertActive,
                "seuil": seuilEnPourcentageValeur,
            },
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then(() => {
                getAlertData()
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const deleteRecipient = (deleteEmail) => {
        setShowErrorMessageRecipient(false)
        setShowConfirmationMessageRecipient(false)
        axios.delete(`${apiUrl}/Conso/Alerte/${siteID}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: deleteEmail,
                withCredentials: true,
            })
            .then(() => {
                setConfirmationMessageRecipient("L'adresse a bien été supprimée")
                setShowConfirmationMessageRecipient(true)
                getAlertData()
                setTimeout(() => {
                    setShowConfirmationMessageRecipient(false)
                }, 1500)
            })
            .catch(error => {
                if (error?.response?.data?.message) setErrorMessageRecipient(error.response.data.message)
                setShowErrorMessageRecipient(true)
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const addRecipient = () => {
        setShowErrorMessageRecipient(false)
        setShowConfirmationMessageRecipient(false)
        if (!validEmail.test(recipientEmail)) {
            setErrorMessageRecipient("Veuillez renseigner une adresse email valide")
            setShowErrorMessageRecipient(true)
        }
        else {
            axios.post(`${apiUrl}/Conso/Alerte/${siteID}`,
                recipientEmail,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
                .then(() => {
                    setConfirmationMessageRecipient("L'adresse a bien été enregistrée")
                    setShowConfirmationMessageRecipient(true)
                    setRecipientEmail("")
                    getAlertData()
                    setTimeout(() => {
                        setShowConfirmationMessageRecipient(false)
                    }, 1500)
                })
                .catch(error => {
                    if (error?.response?.data?.message) setErrorMessageRecipient(error.response.data.message)
                    setShowErrorMessageRecipient(true)
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
        }
    }

    useEffect(() => {
        if (SuiviHebdo) {
            setVueQuery("Journalier")
            if (souscriptionType === "Renouveler") {
                setType("Mensuel")
                setVueQuery("Mensuel")
            }
        }
    }, [SuiviHebdo, setVueQuery, souscriptionType])

    return (
        <>
            <SideBar
                active="Conso"
                openElec />
            <div className="homepage--container privatepages--container">
                <div className="privatepages--header__container">
                    <h3 className="privatepages--title privatepages--text">Visualisation des consommations d'électricité {type === "Mensuel" ? "mensuelles (année glissante)" : ""}</h3>
                    <Link to={`/conso`}><span className="privatepages--header__return">{`< Retour à la liste des sites`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                <div className="table--tablecontainer">
                    {showFilters &&
                        <div className="conso--filters__container">
                            <h6 className="conso--filters__title">Filtres</h6>
                            <span className="conso--filters__subtitle">Vues</span>
                            <div className="privatepages--separator"></div>
                            <label className="homepage--popup__radioinputcontainer conso--filters__radioinputcontainer" htmlFor="mensuel">Mensuel
                                <input type="radio" checked={vueQuery === "Mensuel"} id="mensuel" name="vue" value="Mensuel" onChange={e => setVueQuery(e.target.value)} />
                                <span className="homepage--popup__checkmark conso--filters__checkmark"></span>
                            </label>
                            <label className="homepage--popup__radioinputcontainer conso--filters__radioinputcontainer" htmlFor="journalier">Journalier
                                <input type="radio" name="vue" checked={vueQuery === "Journalier"} id="journalier" value="Journalier" onChange={e => setVueQuery(e.target.value)} />
                                <span className="homepage--popup__checkmark conso--filters__checkmark"></span>
                            </label>
                            <label className="homepage--popup__radioinputcontainer conso--filters__radioinputcontainer" htmlFor="horaire">Horaire
                                <input type="radio" name="vue" checked={vueQuery === "Horaire"} id="horaire" value="Horaire" onChange={e => setVueQuery(e.target.value)} />
                                <span className="homepage--popup__checkmark conso--filters__checkmark"></span>
                            </label>
                            <span className="conso--filters__subtitle">Valeurs</span>
                            <div className="privatepages--separator"></div>
                            <div className="conso--filters__valuecontainers">
                                {valueOptions.map((value, index) => {
                                    return (
                                        <FormInput
                                            type="checkbox"
                                            key={`checkbox-valueoption-${index}`}
                                            nom={value}
                                            isSelected={valueQuery.includes(value)}
                                            selectBox={() => changeValueQuery(value)}
                                            additionnalLabelClassName="homepage--checkbox__label conso--filters__label"
                                        />
                                    )
                                })}
                            </div>
                            {yearOptions.length > 0 &&
                                <>
                                    <span className="conso--filters__subtitle">Années<span className="conso--filters__selectall" onClick={() => selectAllYears()}>Tout {yearOptions.every(year => yearsQuery.includes(year)) ? "dé" : ""}sélectionner</span></span>
                                    <div className="privatepages--separator"></div>
                                    <div className="conso--filters__valuecontainers">
                                        {yearOptions.map((year, index) => {
                                            return (
                                                <FormInput
                                                    type="checkbox"
                                                    key={`checkbox-yearoption-${index}`}
                                                    nom={year}
                                                    isSelected={yearsQuery.includes(year)}
                                                    selectBox={() => changeYearQuery(year)}
                                                    additionnalLabelClassName="homepage--checkbox__label conso--filters__label"
                                                />
                                            )
                                        }
                                        )}
                                    </div>
                                </>}
                            {(vueQuery === "Mensuel" && monthsOptions.length > 0) &&
                                <>
                                    <span className="conso--filters__subtitle">Mois<span className="conso--filters__selectall" onClick={() => selectAllMonths()}>Tout {monthsOptions.every(month => monthsQuery.includes(month.mois)) ? "dé" : ""}sélectionner</span></span>
                                    <div className="privatepages--separator"></div>
                                    <div className="conso--filters__valuecontainers">
                                        {monthsOptions.map((month, index) => {
                                            return (
                                                <FormInput
                                                    type="checkbox"
                                                    key={`checkbox-monthoption-${index}`}
                                                    nom={month.moisLabel}
                                                    isSelected={monthsQuery.includes(month.mois)}
                                                    selectBox={() => changeMonthQuery(month.mois)}
                                                    additionnalLabelClassName="homepage--checkbox__label conso--filters__label"
                                                />
                                            )
                                        }
                                        )}
                                    </div>
                                </>}
                            {((vueQuery === "Journalier" || vueQuery === "Horaire") && weekOptions.length > 0) &&
                                <>
                                    <span className="conso--filters__subtitle">Semaines<span className="conso--filters__selectall" onClick={() => selectAllWeeks()}>Tout {weekOptions.every(week => weeksQuery.includes(week)) ? "dé" : ""}sélectionner</span></span>
                                    <div className="privatepages--separator"></div>
                                    <div className="conso--filters__valuecontainers conso--filters__valuecontainersweeks">
                                        {weekOptions.map((week, index) => {
                                            return (
                                                <FormInput
                                                    type="checkbox"
                                                    key={`checkbox-weekoption-${index}`}
                                                    nom={`Semaine ${week}`}
                                                    isSelected={weeksQuery.includes(week)}
                                                    selectBox={() => changeWeekQuery(week)}
                                                    additionnalLabelClassName="homepage--checkbox__label conso--filters__label"
                                                />
                                            )
                                        }
                                        )}
                                    </div>
                                </>}
                            {((vueQuery === "Journalier" || vueQuery === "Horaire") && daysOptions.length > 0) &&
                                <>
                                    <span className="conso--filters__subtitle">Jours<span className="conso--filters__selectall" onClick={() => selectAllDays()}>Tout {daysOptions.every(day => daysQuery.includes(day.jourNombre)) ? "dé" : ""}sélectionner</span></span>
                                    <div className="privatepages--separator"></div>
                                    <div className="conso--filters__valuecontainers conso--filters__valuecontainersweeks">
                                        {daysOptions.map((day, index) => {
                                            return (
                                                <FormInput
                                                    type="checkbox"
                                                    key={`checkbox-weekoption-${index}`}
                                                    nom={`${day.jourLabel}`}
                                                    isSelected={daysQuery.includes(day.jourNombre)}
                                                    selectBox={() => changeDayQuery(day.jourNombre)}
                                                    additionnalLabelClassName="homepage--checkbox__label conso--filters__label"
                                                />
                                            )
                                        }
                                        )}
                                    </div>
                                </>}
                        </div>
                    }
                    {(isDataLoading ||
                        (isGraphDataLoading && duree === "12 mois" && type === "Mensuel") ||
                        (isGraphDataLoading && vueQuery === "Mensuel" && type === "CDC")) ?
                        <>
                            <span className="global--loader"></span>
                        </>
                        :
                        data.length > 0 ?
                            <>
                                <div className="appeloffre--header__container conso--containers__bottom conso--containers__legend">
                                    <div className="conso--sitename">
                                        {libelle ? <h4 className="privatepages--title">Site : {libelle}{postalCode ? `, ${postalCode}` : ""} {city}</h4> : ""}
                                        {prm ? <span className="addsites--choosetype__title">PRM : {prm}</span> : ""}
                                    </div>
                                    {type === "Mensuel" && <div className="conso--legend__container">
                                        {hasPointe ? <div className="conso--legend"><div className="conso--legend__mark conso--legend__mark1"></div> Pointe</div> : ""}
                                        {hasPm ? <GreyTooltip title="Pointe mobile"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark1"></div> PM</div></GreyTooltip> : ""}
                                        {hasHph ? <GreyTooltip title="Heure Pleine Hiver"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark2"></div> HPH</div></GreyTooltip> : ""}
                                        {hasHch ? <GreyTooltip title="Heure Creuse Hiver"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark3"></div> HCH</div></GreyTooltip> : ""}
                                        {hasHpe ? <GreyTooltip title="Heure Pleine Eté"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark4"></div> HPE</div></GreyTooltip> : ""}
                                        {hasHce ? <GreyTooltip title="Heure Creuse Hiver"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark5"></div> HCE</div></GreyTooltip> : ""}
                                        {hasBase ? <div className="conso--legend"><div className="conso--legend__mark conso--legend__mark1"></div> Base</div> : ""}
                                        {hasHp ? <GreyTooltip title="Heure Pleine"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark2"></div> HP</div></GreyTooltip> : ""}
                                        {hasHc ? <GreyTooltip title="Heure Creuse"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark3"></div> HC</div></GreyTooltip> : ""}
                                    </div>}
                                    {vueQuery === "Mensuel" &&
                                        <div className="conso--legend__container">
                                            {yearsQuery.map((year, index) => {
                                                return (
                                                    <Fragment key={`round-legend-${year}-${index}`}>
                                                        {valueQuery.includes('kWh') && <div className="conso--legend"><div className="conso--legend__mark conso--legend__markenergy" style={{ backgroundColor: lineColorsEnergy[year % lineColorsEnergy.length] }}></div> {`Energie ${year}`}</div>}
                                                        {valueQuery.includes('Euros') && <div className="conso--legend"><div className="conso--legend__mark conso--legend__markenergy" style={{ backgroundColor: lineColorsEuros[year % lineColorsEuros.length] }}></div> {`Euro ${year}`}</div>}
                                                    </Fragment>
                                                )
                                            }
                                            )}
                                        </div>
                                    }
                                    {(vueQuery === "Journalier" || vueQuery === "Horaire") &&
                                        <div className="conso--legend__container">
                                            {weeksQuery.map((week, index) => {
                                                return (
                                                    yearsQuery.map((year) => {
                                                        return (
                                                            <Fragment key={`round-legend-${year}-${week}-${index}`}>
                                                                {valueQuery.includes('kWh') && <div className="conso--legend"><div className="conso--legend__mark conso--legend__markenergy" style={{ backgroundColor: lineColorsEnergy[year % 2 === 0 ? index % lineColorsEnergy.length : (year + index) % lineColorsEnergy.length] }}></div> {`Energie S${week}-${year}`}</div>}
                                                                {valueQuery.includes('Euros') && <div className="conso--legend"><div className="conso--legend__mark conso--legend__markenergy" style={{ backgroundColor: lineColorsEuros[year % 2 === 0 ? index % lineColorsEuros.length : (year + index) % lineColorsEuros.length] }}></div> {`Euros S${week}-${year}`}</div>}
                                                            </Fragment>
                                                        )
                                                    })
                                                )
                                            })}
                                        </div>
                                    }
                                    <div className="conso--header__periodedropdowns">
                                        {(type === "Mensuel" && (souscriptionType === "Renouveler" || souscriptionType === "Souscrire")) && <Button
                                            cta="Activer la courbe de charge"
                                            additionnalClassName="conso--activatebutton"
                                            link={`/subscription?siteId=${siteID}`} />}
                                        <Button
                                            cta="Exporter"
                                            additionnalClassName="global--button__black conso--export__button"
                                            handleSubmit={() => handleDataExport()}
                                            isLoading={isDataLoadingExport} />
                                        {type === "CDC" &&
                                            <div className="conso--filters__button" onClick={() => setShowFiltrers(!showFilters)}>
                                                Voir les filtres
                                                {showFilters
                                                    ?
                                                    <CloseIcon style={{ marginLeft: "5px" }} />
                                                    :
                                                    <MenuIcon style={{ marginLeft: "5px" }} />}
                                            </div>}
                                        {type === "Mensuel" && <div className="conso--header__subtitle conso--header__periodedropdowns">
                                            <FormInput
                                                type="dropdown"
                                                id="duree"
                                                onChangeInput={e => {
                                                    setDuree(e.label)
                                                    getGraphData(e.value)
                                                }}
                                                placeholder="Choisir..."
                                                value={duree}
                                                isSearchable={false}
                                                options={dropdownOptions}
                                                divClassName="conso--header__periodedropdownsdiv"
                                            />
                                        </div>}
                                    </div>
                                </div>
                                <div className="conso--chart__container">
                                    <ResponsiveContainer>
                                        {type === "Mensuel" ?
                                            <BarChart data={data} >
                                                <CartesianGrid strokeDasharray="2 2" />
                                                <XAxis
                                                    dataKey="yearLabel"
                                                    style={{
                                                        fontSize: '11px',
                                                        fontFamily: 'Plus Jakarta Sans',
                                                        color: '#52525B'
                                                    }} />
                                                <YAxis
                                                    style={{
                                                        fontSize: '11px',
                                                        fontFamily: 'Plus Jakarta Sans',
                                                        color: '#52525B'
                                                    }}>
                                                    <Label
                                                        value="kWh"
                                                        position="insideTopLeft"
                                                        offset={10}
                                                        dx={-15}
                                                        dy={10}
                                                        style={{
                                                            fontSize: '11px',
                                                            fontFamily: 'Plus Jakarta Sans',
                                                            color: '#52525B'
                                                        }}
                                                    />
                                                </YAxis>
                                                <Tooltip content={<ConsoCustomTooltip datakey="yearLabel" />} cursor={{ fill: '#EFEFEF' }} />
                                                <Bar dataKey="pointe" stackId="a" fill="#0a6273" />
                                                <Bar dataKey="base" stackId="a" fill="#0a6273" />
                                                <Bar dataKey="pm" stackId="a" fill="#0a6273" />
                                                <Bar dataKey="hph" stackId="a" fill="#007c7e" />
                                                <Bar dataKey="hp" stackId="a" fill="#007c7e" />
                                                <Bar dataKey="hch" stackId="a" fill="#009578" />
                                                <Bar dataKey="hc" stackId="a" fill="#009578" />
                                                <Bar dataKey="hpe" stackId="a" fill="#47ac63" />
                                                <Bar dataKey="hce" stackId="a" fill="#8fbe44" />
                                            </BarChart>
                                            :
                                            ""
                                        }
                                        {((vueQuery === "Mensuel" && yearsQuery.length > 0 && monthsQuery.length > 0 && valueQuery.length > 0) ||
                                            ((vueQuery === "Journalier" || vueQuery === "Horaire") && weeksQuery.length > 0 && yearsQuery.length > 0 && valueQuery.length > 0 && daysQuery.length > 0)) ?
                                            <LineChart data={data}>
                                                <CartesianGrid strokeDasharray="2 2" />
                                                <XAxis
                                                    dataKey={vueQuery === "Mensuel" ? "moisLabel" : vueQuery === "Journalier" ? "jourLabel" : "heureLabel"}
                                                    style={{
                                                        fontSize: '11px',
                                                        fontFamily: 'Plus Jakarta Sans',
                                                        color: '#52525B'
                                                    }} />
                                                <YAxis

                                                    yAxisId="left"
                                                    style={{
                                                        fontSize: '11px',
                                                        fontFamily: 'Plus Jakarta Sans',
                                                        color: '#52525B'
                                                    }}>
                                                    <Label
                                                        value="kWh"
                                                        position="insideTopLeft"
                                                        offset={10}
                                                        dx={-15}
                                                        dy={10}
                                                        style={{
                                                            fontSize: '11px',
                                                            fontFamily: 'Plus Jakarta Sans',
                                                            color: '#52525B'
                                                        }}
                                                    />
                                                </YAxis>
                                                <YAxis
                                                    yAxisId="right"
                                                    orientation="right"
                                                    style={{
                                                        fontSize: '11px',
                                                        fontFamily: 'Plus Jakarta Sans',
                                                        color: '#52525B'
                                                    }}>
                                                    <Label
                                                        value="Euros"
                                                        position="insideTopLeft"
                                                        offset={10}
                                                        dx={10}
                                                        dy={10}
                                                        style={{
                                                            fontSize: '11px',
                                                            fontFamily: 'Plus Jakarta Sans',
                                                            color: '#52525B'
                                                        }}
                                                    />
                                                </YAxis>

                                                <Tooltip content={<ConsoCustomTooltip datakey={vueQuery === "Mensuel" ? "moisLabel" : vueQuery === "Journalier" ? "jourLabel" : "heureLabel"} />} cursor={{ fill: '#EFEFEF' }} />
                                                {vueQuery === "Mensuel" && yearsQuery.map((year, index) => {
                                                    return (
                                                        <Fragment key={`line-graph-year-${index}`}>
                                                            {valueQuery.includes('kWh') && <Line strokeWidth={2} yAxisId="left" type="monotone" unit="kWh" name={`${year}`} dataKey={`energie${year}`} stroke={lineColorsEnergy[year % lineColorsEnergy.length]} />}
                                                            {valueQuery.includes('Euros') && <Line strokeWidth={2} yAxisId="right" type="monotone" unit="€" name={`${year}`} dataKey={`euros${year}`} stroke={lineColorsEuros[year % lineColorsEuros.length]} />}
                                                        </Fragment>
                                                    )
                                                }
                                                )}
                                                {(vueQuery === "Journalier" || vueQuery === "Horaire") && weeksQuery.map((week, index) => {
                                                    return (
                                                        yearsQuery.map((year) => {
                                                            return (
                                                                <Fragment key={`line-graph-week-${index}`}>
                                                                    {valueQuery.includes('kWh') && <Line strokeWidth={2} yAxisId="left" type="monotone" unit="kWh" name={`S${week}-${year}`} dataKey={`energieS${week}A${year}`} stroke={lineColorsEnergy[year % 2 === 0 ? index % lineColorsEnergy.length : (year + index) % lineColorsEnergy.length]} />}
                                                                    {valueQuery.includes('Euros') && <Line strokeWidth={2} yAxisId="right" type="monotone" unit="€" name={`S${week}-${year}`} dataKey={`eurosS${week}A${year}`} stroke={lineColorsEuros[year % 2 === 0 ? index % lineColorsEuros.length : (year + index) % lineColorsEuros.length]} />}
                                                                </Fragment>
                                                            )
                                                        })
                                                    )

                                                })}
                                            </LineChart>
                                            :
                                            type === "CDC" ?
                                                <span>Pas de donnée</span>
                                                :
                                                ""
                                        }
                                    </ResponsiveContainer>
                                </div>
                            </>
                            :
                            <span>Aucune donnée trouvée</span>
                    }
                </div>
                <div className={`conso--detail__container ${type === "Mensuel" ? "conso--detail__containerwithalert" : ""}`}>
                    <div className="conso--detail__block">
                        <span className="conso--detail__title">Résumé et enseignements</span>
                        {type === "Mensuel" && <span className="conso--detail__title conso--detail__subtitle">Consommation sur les {duree} derniers mois glissants&nbsp;{consoTotal ? `: ${consoTotal} kWh` : ""}</span>}
                        {consoTotalPrecedent ? <span className="conso--detail__title conso--detail__text">Période précédente : {consoTotalPrecedent} kWh</span> : ""}
                        {(consoTotal && consoTotalPrecedent) ? <p className="conso--detail__title conso--detail__paragraph">Votre consommation moyenne a <span className="conso--detail__enedisbold">{(consoTotal - consoTotalPrecedent) < 0 ? "diminué" : "augmenté"} de {Math.round(Math.abs((consoTotal - consoTotalPrecedent) / consoTotalPrecedent * 100))}%</span> par rapport à la période précédente.</p> : ""}

                        {comparisonData.length === 2 ? <p className="conso--detail__title conso--detail__paragraph">Votre consommation moyenne a <span className="conso--detail__enedisbold">{(comparisonData[1].totalEnergie - comparisonData[0].totalEnergie) < 0 ? "diminué" : "augmenté"} de {Math.round(Math.abs((comparisonData[1].totalEnergie - comparisonData[0].totalEnergie) / comparisonData[0].totalEnergie * 100))}%</span> entre les deux périodes affichées.</p> : ""}
                        {detailData.length > 0 &&
                            <div className="conso--detail__datacontainer">
                                {detailData.map(((element, index) => {
                                    return (
                                        <span key={`detail-data-${index}`} className="conso--detail__data">
                                            Année {element.year} {vueQuery === "Mensuel" && (element.isComplet ? "(complète)" : "(incomplète)")}{vueQuery !== "Mensuel" ? ` S${element.week} ${element.isComplet ? "(complète)" : "(incomplète)"}` : ""} : <span className="conso--detail__databold">{new Intl.NumberFormat('fr-FR').format(element.totalEnergie)} kWh | {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(element.totalEuros)}</span>
                                        </span>
                                    )
                                }))}
                            </div>
                        }

                        {dateActivation ? <span className="conso--detail__enedis conso--detail__activation">Service activé le {new Date(dateActivation).toLocaleDateString()}</span> : ""}
                        {(dateNow && dateNowWeek) ? <span className="conso--detail__enedis conso--detail__today">Aujourd'hui :
                            <span className="conso--detail__enedisbold">{new Date(dateNow).toLocaleDateString()} - Semaine {dateNowWeek}
                            </span>
                        </span> : ""}
                        <span className="conso--detail__enedis">Source : Enedis {lastDateModification ? `du ${new Date(lastDateModification).toLocaleDateString()}` : ""}{(dateMin && dateMax && dateMinWeek && dateMaxWeek) ? `du ${new Date(dateMin).toLocaleDateString()} (semaine ${dateMinWeek}) au ${new Date(dateMax).toLocaleDateString()} (semaine ${dateMaxWeek})` : ""}</span>
                    </div>
                    <div className="conso--detail__block conso--detail__blocksecond">
                        <span className="conso--detail__title">Détails</span>
                        <div className="conso--detail__list conso--detail__paragraph">
                            <CircleIcon sx={{ fontSize: "9px", color: "#48AB4D", marginRight: "4px", marginTop: "2px" }} />
                            {horosaison !== "" ?
                                horosaison
                                :
                                <>
                                    <p>Plages horosaisonnières :<br />
                                        Hiver, de novembre à mars. Eté, de avril à octobre.</p>
                                </>}
                        </div>
                        {type === "Mensuel" ?
                            <>
                                <div className="conso--detail__list conso--detail__paragraph">
                                    <CircleIcon sx={{ fontSize: "9px", color: "#48AB4D", marginRight: "4px", marginTop: "2px" }} />
                                    <p>Données de consommation Enedis également utilisées pour la facturation par votre fournisseur.</p>
                                </div>
                                <div className="conso--detail__list conso--detail__paragraph">
                                    <CircleIcon sx={{ fontSize: "9px", color: "#48AB4D", marginRight: "4px", marginTop: "2px" }} />
                                    <p>{duree} dernières relèves sur les {duree} derniers mois glissants. Par convention, le mois correspond au 1er jour du mois de la relève.</p>
                                </div>
                            </>
                            :
                            <>
                                <div className="conso--detail__list conso--detail__paragraph conso--detail__linklist" onClick={() => window.open('https://youtu.be/aSwHRnYkUVQ')}>
                                    <div className="mandates--link__container">
                                        <div>
                                            <CircleIcon sx={{ fontSize: "9px", color: "#48AB4D", marginRight: "4px", marginTop: "2px" }} />
                                            <p className="conso--detail__linklistparagraph">Voir une vidéo de démonstration</p>
                                        </div>
                                    </div>
                                    <OpenInNewIcon sx={{ color: "#71717A", width: "12px", height: "12px", cursor: "pointer" }} />
                                </div>
                                <div className="conso--detail__list conso--detail__paragraph conso--detail__linklist" onClick={() => window.open('https://www.wattunity.com/documents/CG_CDC_12mois_VF.pdf')}>
                                    <div className="mandates--link__container">
                                        <CircleIcon sx={{ fontSize: "9px", color: "#48AB4D", marginRight: "4px", marginTop: "2px" }} />
                                        <p className="conso--detail__linklistparagraph">Consulter nos conditions générales d'abonnement</p>
                                    </div>
                                    <OpenInNewIcon sx={{ color: "#71717A", width: "12px", height: "12px", cursor: "pointer" }} />
                                </div>
                            </>
                        }
                    </div>
                    {type === "CDC" &&
                        <div className="conso--detail__block conso--detail__blockthird">
                            <div className="addsites--choosetype__switchcontainer conso--detail__switchcontainer">
                                <span className="conso--detail__title conso--detail__alerttitle">Paramétrer vos alertes
                                </span>
                                <Switch
                                    sx={{
                                        margin: '0 12px',
                                        '--Switch-thumbSize': '19px',
                                        '--Switch-trackWidth': '45px',
                                        '--Switch-trackHeight': '21px',
                                        '--Switch-trackBackground': '#EFEFEF',
                                        '--Switch-thumbBackground': '#71717A',
                                        '&:hover': {
                                            '--Switch-trackBackground': '#EFEFEF',
                                        },
                                        [`&.${switchClasses.checked}`]: {
                                            '--Switch-thumbBackground': '#48AB4D',
                                            '--Switch-trackBackground': '#EFEFEF',
                                            '&:hover': {
                                                '--Switch-trackBackground': '#EFEFEF',
                                            },
                                        },
                                    }}
                                    checked={isAlertActive}
                                    onChange={() => {
                                        updateAlertSubscription(!isAlertActive, seuilEnPourcentageValeur)
                                    }}
                                />
                            </div>
                            {!isAlertActive && <span className="conso--detail__title conso--detail__paragraph">Recevez une alerte par email si votre consommation d'électricité dépasse un certain seuil par rapport à la semaine précédente. Cela vous permet de surveiller facilement et de réagir rapidement en cas de surconsommation.</span>}
                            {isAlertActive ?
                                <>
                                    <div className="conso--detail__paragraph">
                                        <span>Si la consommation d'une journée dépasse de</span>
                                        <FormInput
                                            type="dropdown"
                                            id="conso-seuil"
                                            options={optionsSeuilConso}
                                            placeholder="Choisir..."
                                            onChangeInput={(e) => {
                                                updateAlertSubscription(isAlertActive, e.value)
                                            }}
                                            isSearchable={false}
                                            value={seuilEnPourcentageLabel}
                                            dropdownClassName="conso--alert__dropdown"
                                            divClassName="conso--alert__dropdowncontainer"
                                        />
                                        <span>la consommation du même jour la semaine précédente, vous êtes alerté par email.</span>
                                    </div>
                                    <div className="conso--recipients__block">
                                        {alertRecipients.length > 0 ?
                                            alertRecipients.map((recipient, index) => {
                                                return (
                                                    <div key={`recipient-email-${index}`} className="sepa--recipients__container conso--recipients__container">
                                                        <span className="sepa--recipients conso--recipients">{recipient}</span>
                                                        <HighlightOffIcon onClick={() => deleteRecipient(recipient)} sx={{ fontSize: "16px", marginLeft: "5px", cursor: "pointer" }} />
                                                    </div>
                                                )
                                            })
                                            :
                                            <span className="sepa--emptyrecipients">Aucun destinaire trouvé</span>
                                        }
                                        <div className="privatepages--separator"></div>
                                        <FormInput
                                            label="Destinataire"
                                            type="text"
                                            id="email-recipient"
                                            onChangeInput={value => {
                                                setShowErrorMessageRecipient(false)
                                                setShowConfirmationMessageRecipient(false)
                                                setRecipientEmail(value)
                                            }}
                                            placeholder="seamus.schmidt@gmail.com"
                                            value={recipientEmail}
                                            maxLength={255}
                                            additionnalClassName="sepa--emailinput"
                                        />
                                        <AddCircleOutlineIcon onClick={addRecipient} sx={{ color: "#48AB4D", position: "relative", bottom: "-5px", right: "-10px", cursor: "pointer" }} />
                                        {showConfirmationMessageRecipient && <span className="homepage--popup__message homepage--popup__messageconfirmation">{confirmationMessageRecipient}</span>}
                                        {showErrorMessageRecipient && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessageRecipient}</span>}
                                    </div>
                                </>
                                :
                                ""}
                        </div>}
                </div>
            </div>
            {(showPopup && type === "Mensuel" && (souscriptionType === "Renouveler" || souscriptionType === "Souscrire")) &&
                <InformationPopup
                    title="Réduisez vos coûts énergétiques dès aujourd'hui !"
                    subtitle="Une vision claire de votre consommation pour des économies immédiates."
                    subtitle2="Grâce à notre outil de visualisation quotidien, identifiez facilement les pics de consommation et optimisez vos dépenses énergétiques. Maîtrisez vos coûts pour une meilleure performance de votre entreprise."
                    cta="Je souscris"
                    link={`/subscription?siteId=${siteID}`}
                    closeInformationPopup={setShowPopup}
                />
            }
        </>
    )
}

export default ConsoDetail;