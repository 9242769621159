import * as React from 'react'
import { Link } from 'react-router-dom'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import Modify from '../../../img/icons/modify.svg'
import Suppr from '../../../img/icons/suppr.svg'
import { getComparator } from '../../../Utils/SortingUtils/getComparator'
import { stableSort } from '../../../Utils/SortingUtils/stableSort'
import EnhancedTableHead from '../../../Components/TableComponents/EnhancedTableHead'
import { StyledTableCell } from '../../../Components/TableComponents/StyledTableCell'
import { headCellsContributors, headCellsAccess } from '../../../Utils/TableUtils/headCells'
import Gaz from '../../../img/icons/gaz.svg'
import Elec from '../../../img/icons/elec.svg'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'
import SendIcon from '@mui/icons-material/Send'
import { Tooltip } from '@mui/material'

function ContributorsTable(props) {
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('id')
    const [rowsPerPage, setRowsPerPage] = React.useState(props.type === "contributors" ? 10 : 5)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        props.setPage(0)
    }

    const visibleRows = React.useMemo(
        () =>
            stableSort(props.rows, getComparator(order, orderBy)).slice(
                props.page * rowsPerPage,
                props.page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, props.page, rowsPerPage, props.rows],
    )

    return (
        <Box sx={{
            width: '100%',
            [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
            }
        }}>
            <TableContainer>
                <Table
                    aria-labelledby="tableTitle"
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.rows.length}
                        headCells={props.type === "contributors" ? headCellsContributors : headCellsAccess}
                    />
                    <TableBody>
                        {visibleRows.map((row, index) => {
                            const labelId = `enhanced-table-checkbox-${index}`

                            return (
                                props.type === "contributors" ?
                                    <TableRow key={`table-${index}`}>
                                        <StyledTableCell>{row.contactID}</StyledTableCell>
                                        <StyledTableCell>
                                            <Tooltip title={row.contributor} enterTouchDelay={0}>
                                                <span>{row.contributor}</span>
                                            </Tooltip>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Tooltip title={row.email} enterTouchDelay={0}>
                                                <span>{row.email}</span>
                                            </Tooltip>
                                        </StyledTableCell>
                                        <StyledTableCell>{row.creationDate && new Date(row.creationDate).toLocaleString()}</StyledTableCell>
                                        <StyledTableCell>{row.isValidated && <div className="table--checkicon"><CheckIcon /></div>}</StyledTableCell>
                                        <StyledTableCell>{(!row.isValidated && !row.emailSent) ? <div className="table--checkicon table--sendicon" onClick={() => props.sendEmailValidation(row.contactID, index)}><SendIcon fontSize="small" /></div> : row.emailSent ? <div className="table--sent">Envoyé</div> : <div></div>}</StyledTableCell>
                                        <Link to={`/contributors-update/?id=${row.contactID}`}><StyledTableCell><div><span className="table--link">Modifier</span><img src={Modify} alt="Modifier" className="table--icon" /></div></StyledTableCell></Link>
                                        <StyledTableCell>{row.canBeDeleted && <div onClick={() => props.showSupprContributorPopup(row.contactID)}><span className="table--link">Supprimer</span><img src={Suppr} alt="Supprimer" className="table--icon" /></div>}</StyledTableCell>
                                    </TableRow>
                                    :
                                    <TableRow key={`accesstable-${index}`}>
                                        <StyledTableCell><img src={row.type === "Gaz" ? Gaz : Elec} alt="Type du site" /></StyledTableCell>
                                        <StyledTableCell>{row.type}</StyledTableCell>
                                        <StyledTableCell>{row.pdl}</StyledTableCell>
                                        <StyledTableCell>{row.label}</StyledTableCell>
                                        <StyledTableCell>{row.address}</StyledTableCell>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={row.access}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                                onClick={(event) => props.handleCheckboxClick(event, row.index)}
                                                sx={{
                                                    borderColor: "#3F3F46",
                                                    padding: "0 18px",
                                                    '&.Mui-checked': {
                                                        padding: "0 18px",
                                                        color: "#48AB4D",

                                                    },
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                            )
                        })}
                        <TableRow>
                            <TableCell sx={{ padding: "5px" }} colSpan={6} />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage="Lignes par page"
                rowsPerPageOptions={props.type === "contributors" ? [10, 25, 50] : [5, 10, 25]}
                component="div"
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={props.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) =>
                    `Lignes ${from} à ${to} sur ${count !== -1 ? count : `PLUS DE ${to}`}`}
                sx={{
                    '.MuiTablePagination-toolbar': {
                        color: "#18181b !important",
                    },
                    '.MuiTablePagination-selectLabel': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-select': {
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                    },
                    '.MuiTablePagination-displayedRows': {
                        fontFamily: "Plus Jakarta Sans, sans-serif !important",
                        color: "#18181b !important",
                    },
                }}
            />
        </Box>
    );
}

export default ContributorsTable;