import { useState, useEffect, useCallback, useRef } from "react"
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import useOnClickOutside from "../../Utils/ModalUtils/useOnClickOutside"

import SideBar from "../../Components/SideBar/SideBar"
import axios from "axios"
import Button from "../../Components/Button"
import FormInput from '../../Components/Form/FormInput'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import dayjs from 'dayjs'
import "dayjs/locale/fr"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import CloseIcon from '@mui/icons-material/Close'
import ToolTip from "../../Components/ToolTip"
import { validNumber } from "../../Utils/Regex/validNumber"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

const apiUrl = process.env.REACT_APP_API_URL

function AddSitesStep2() {
    const ref = useRef()

    useOnClickOutside(ref, () => setShowCalendar(false), "date1", "date2")

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [siteID] = useState(searchParams.get('id') || "")
    const [isElec] = useState(searchParams.get('type') === "elec")
    const [isGaz] = useState(searchParams.get('type') === "gaz")
    const [fromAppelOffre] = useState(searchParams.get('from') === "appels-offre")
    const isAdd = window.location.pathname.includes('add')

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("Une erreur est survenue, veuillez réessayer plus tard")
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [car, setCar] = useState("")
    const [pce, setPce] = useState("")
    const [conso, setConso] = useState(0)

    const [prm, setPrm] = useState("")
    const [consoAnnuelle, setConsoAnnuelle] = useState(0)
    const [hasGreenContract, setHasGreenContract] = useState(false)
    const [trvChoix, setTrvChoix] = useState("")

    const [selectedPdlName, setSelectedPdlName] = useState("")
    const [selectedPdlId, setSelectedPdlId] = useState("")
    const [selectedBilanName, setSelectedBilanName] = useState("")
    const [selectedBilanId, setSelectedBilanId] = useState("")
    const [selectedFeuilletName, setSelectedFeuilletName] = useState("")
    const [selectedFeuilletId, setSelectedFeuilletId] = useState("")
    const [showConfirmationMessageElec, setShowConfirmationMessageElec] = useState(false)
    const [confirmationMessageElec, setConfirmationMessageElec] = useState("Le fichier a bien été envoyé")
    const [showErrorMessageElec, setShowErrorMessageElec] = useState(false)
    const [errorMessageElec, setErrorMessageElec] = useState("Une erreur est survenue, veuillez réessayer plus tard")

    const [courbeArray, setCourbeArray] = useState([])

    const [contract, setContract] = useState("OffreMarché")
    const [contratDateDebut, setContratDateDebut] = useState(new Date())
    const [formatedContratDateDebut, setFormatedContratDateDebut] = useState(null)
    const [contratDateEcheance, setContratDateEcheance] = useState(new Date())
    const [formatedContratDateEcheance, setFormatedContratDateEcheance] = useState(null)
    const [showCalendar, setShowCalendar] = useState(false)

    const [selectedInvoiceName, setSelectedInvoiceName] = useState("")
    const [selectedInvoiceId, setSelectedInvoiceId] = useState("")
    const [selectedContractName, setSelectedContractName] = useState("")
    const [selectedContractId, setSelectedContractId] = useState("")

    const [isConfirmed, setIsConfirmed] = useState(false)

    const [showConfirmationMessageInvoice, setShowConfirmationMessageInvoice] = useState(false)
    const [confirmationMessageInvoice, setConfirmationMessageInvoice] = useState("Le fichier a bien été envoyé")
    const [showErrorMessageInvoice, setShowErrorMessageInvoice] = useState(false)
    const [errorMessageInvoice, setErrorMessageInvoice] = useState("Une erreur est survenue, veuillez réessayer plus tard")

    const [showConfirmationMessageContract, setShowConfirmationMessageContract] = useState(false)
    const [confirmationMessageContract, setConfirmationMessageContract] = useState("Le fichier a bien été envoyé")
    const [showErrorMessageContract, setShowErrorMessageContract] = useState(false)
    const [errorMessageContract, setErrorMessageContract] = useState("Une erreur est survenue, veuillez réessayer plus tard")

    const [showErrorMessageCar, setShowErrorMessageCar] = useState(false)
    const [showErrorMessagePce, setShowErrorMessagePce] = useState(false)
    const [showErrorMessagePrm, setShowErrorMessagePrm] = useState(false)
    const [showErrorMessageHasGreenContract, setShowErrorMessageHasGreenContract] = useState(false)
    const [showErrorMessageConsoAnnuelle, setShowErrorMessageConsoAnnuelle] = useState(false)
    const [showErrorMessageContratDateDebut, setShowErrorMessageContratDateDebut] = useState(false)
    const [showErrorMessageContratDateEcheance, setShowErrorMessageContratDateEcheance] = useState(false)

    const handleSubmitStep2 = () => {
        let today = new Date()
        today.setHours(0, 0, 0, 0)

        let contractDebut = new Date(contratDateDebut)
        contractDebut.setHours(0, 0, 0, 0)

        let contractEcheance = new Date(contratDateEcheance)
        contractEcheance.setHours(0, 0, 0, 0)

        if (isConfirmed && siteID) {
            navigate(`/${isAdd ? "add" : "modify"}-sites-3?type=${isGaz ? "gaz" : "elec"}&id=${siteID}${fromAppelOffre ? "&from=appels-offre" : ""}`)
        }
        else if ((!isGaz) && ((!prm || prm === "" || !consoAnnuelle || consoAnnuelle === ""
            || (contract === "PasDeContrat" && (selectedPdlName === "" || !selectedPdlName || (contractDebut.getTime() < today.getTime() || contractDebut.getTime() === today.getTime())))
            || (contract === "TRV" && (selectedFeuilletName === "" || !selectedFeuilletName || selectedInvoiceName === "" || !selectedInvoiceName))
            || (contract === "OffreMarché" && (selectedInvoiceName === "" || !selectedInvoiceName || hasGreenContract === null || contractEcheance.getTime() < today.getTime() || contractEcheance.getTime() === today.getTime())))
        )) {
            resetErrorMessages()
            if (!prm || prm === "") {
                setShowErrorMessagePrm(true)
            }
            if (!consoAnnuelle || consoAnnuelle === "") {
                setShowErrorMessageConsoAnnuelle(true)
            }
            if (contract === "PasDeContrat" && (selectedPdlName === "" || !selectedPdlName)) {
                setErrorMessageElec("Veuillez télécharger le document PDL")
                setShowErrorMessageElec(true)
            }
            if (contract === "PasDeContrat" && (contractDebut.getTime() < today.getTime() || contractDebut.getTime() === today.getTime())) {
                setShowErrorMessageContratDateDebut(true)
            }
            if (contract === "OffreMarché" && (contractEcheance.getTime() < today.getTime() || contractEcheance.getTime() === today.getTime())) {
                setShowErrorMessageContratDateEcheance(true)
            }
            if (contract === "TRV" && (selectedFeuilletName === "" || !selectedFeuilletName || selectedInvoiceName === "" || !selectedInvoiceName)) {
                setErrorMessageElec("La dernière facture complète de moins de 3 mois et le feuillet de gestion sont obligatoires")
                setShowErrorMessageElec(true)
            }
            if (contract === "OffreMarché" && (hasGreenContract === null)) {
                setShowErrorMessageHasGreenContract(true)
            }
            if (contract === "OffreMarché" && (selectedInvoiceName === "" || !selectedInvoiceName)) {
                setErrorMessageElec("Veuillez télécharger la dernière facture complète de moins de 3 mois")
                setShowErrorMessageElec(true)
            }
        }
        else if (isGaz && (
            !car || car === "" || !pce || pce === ""
            || ((contract === "OffreMarché" || contract === "TRV") && (selectedInvoiceName === "" || !selectedInvoiceName || contractEcheance.getTime() < today.getTime() || contractEcheance.getTime() === today.getTime()))
            || (contract === "PasDeContrat" && ((contractDebut.getTime() < today.getTime() || contractDebut.getTime() === today.getTime() || (!selectedInvoiceName || selectedInvoiceName === ""))))
        )) {
            resetErrorMessages()
            if (!pce || pce === "") {
                setShowErrorMessagePce(true)
            }
            if (!car || car === "") {
                setShowErrorMessageCar(true)
            }
            if (contract === "PasDeContrat" && (contractDebut.getTime() < today.getTime() || contractDebut.getTime() === today.getTime())) {
                setShowErrorMessageContratDateDebut(true)
            }
            if (contract === "OffreMarché" && (contractEcheance.getTime() < today.getTime() || contractEcheance.getTime() === today.getTime())) {
                setShowErrorMessageContratDateEcheance(true)
            }
            if (contract === "PasDeContrat" && (!selectedInvoiceName || selectedInvoiceName === "")) {
                setErrorMessageInvoice("Veuillez télécharger le document PDL")
                setShowErrorMessageInvoice(true)
            }
            if ((contract === "OffreMarché" || contract === "TRV") && (selectedInvoiceName === "" || !selectedInvoiceName)) {
                setErrorMessageInvoice("Veuillez télécharger la facture")
                setShowErrorMessageInvoice(true)
            }
        }
        else {
            setErrorMessage("Une erreur est survenue, veuillez réessayer plus tard")
            setShowErrorMessage(false)
            setIsLoading(true)
            const formData = JSON.stringify({
                "car": `${car}`,
                "pce": `${pce}`,
                "contratType": `${contract}`,
                "contratDateEcheance": `${new Date(contratDateEcheance).toISOString()}`,
                "dateDebutFourniture": `${new Date(contratDateDebut).toISOString()}`,
                "consommationEstimee": conso,
                "prm": `${prm}`,
                "isContratEnergieRenouvelable": (hasGreenContract === "true" || hasGreenContract === true),
                "consommationTotale": consoAnnuelle,
                "trvChoix": `${trvChoix}`
            })

            axios.post(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/Site/${siteID}/2`,
                formData,
                {
                    headers: {
                        'Accept': 'text/plain',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
                .then(() => {
                    navigate(`/${isAdd ? "add" : "modify"}-sites-3?type=${isGaz ? "gaz" : "elec"}&id=${siteID}${fromAppelOffre ? "&from=appels-offre" : ""}`)
                })
                .catch(error => {
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                    if (error?.response?.data?.message) {
                        setErrorMessage(error.response.data.message)
                    }
                    setShowErrorMessage(true)
                })
                .finally(() => setIsLoading(false))
        }
    }

    const resetErrorMessages = () => {
        setShowErrorMessageCar(false)
        setShowErrorMessagePce(false)
        setShowErrorMessagePrm(false)
        setShowErrorMessageHasGreenContract(false)
        setShowErrorMessageConsoAnnuelle(false)
        setShowErrorMessageContratDateDebut(false)
        setShowErrorMessageContratDateEcheance(false)
        setShowErrorMessageElec(false)
    }

    const getSiteData = useCallback((docsOnly) => {
        axios.get(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/Site/${siteID}/2`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                if (!docsOnly) {
                    response.data?.car && setCar(response.data.car)
                    response.data?.pce && setPce(response.data.pce)
                    response.data?.consommationEstimee && setConso(response.data.consommationEstimee)

                    response.data?.contratDateEcheance && setContratDateEcheance(response.data.contratDateEcheance)
                    response.data?.contratDateEcheance && setFormatedContratDateEcheance(new Date(response.data.contratDateEcheance).toLocaleDateString())
                    response.data?.contratType && setContract(response.data.contratType)

                    response.data?.dateDebutFourniture && setContratDateDebut(response.data.dateDebutFourniture)
                    response.data?.dateDebutFourniture && setFormatedContratDateDebut(new Date(response.data.dateDebutFourniture).toLocaleDateString())

                    response.data?.prm && setPrm(response.data.prm)
                    setHasGreenContract(response.data.isContratEnergieRenouvelable)
                    response.data?.consommationTotale && setConsoAnnuelle(response.data.consommationTotale)

                    response.data?.isConfirmed && setIsConfirmed(response.data.isConfirmed)
                }

                response.data?.contratFichier && setSelectedContractName(response.data.contratFichier)
                response.data?.contratID && setSelectedContractId(response.data.contratID)

                response.data?.factureFichier && setSelectedInvoiceName(response.data.factureFichier)
                response.data?.factureID && setSelectedInvoiceId(response.data.factureID)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [siteID, isGaz])

    const addFile = (file, fileType) => {
        hideFileMessages()
        const formData = new FormData()
        formData.append('file', file)
        formData.append('fileName', file.name)

        axios.post(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/Site/${siteID}/2${!isGaz ? "/Document" : ""}/${fileType}`,
            formData,
            {
                headers: {
                    'accept': '*/*',
                    'content-type': 'multipart/form-data',
                },
                withCredentials: true,
            })
            .then((response) => {
                if (fileType === "Facture") {
                    setSelectedInvoiceId(response.data)
                }
                if (fileType === "Contrat") {
                    setSelectedContractId(response.data)
                }
                if (fileType === "DocumentDePDL") {
                    setSelectedPdlId(response.data)
                }
                if (fileType === "BilanAnnuel") {
                    setSelectedBilanId(response.data)
                }
                if (fileType === "FeuilletsGestion") {
                    setSelectedFeuilletId(response.data)
                }
                if (fileType === "CourbeCharge") {
                    let array = [...courbeArray]
                    array.push({ fichier: file.name, documentID: response.data })
                    setCourbeArray(array)
                }

                if (!isGaz) {
                    setConfirmationMessageElec("Le fichier a bien été ajouté")
                    setShowConfirmationMessageElec(true)
                }
                else if (fileType === "Facture") {
                    setConfirmationMessageInvoice("Le fichier a bien été ajouté")
                    setShowConfirmationMessageInvoice(true)
                }
                else {
                    setConfirmationMessageContract("Le fichier a bien été ajouté")
                    setShowConfirmationMessageContract(true)
                }

            })
            .then(() => { if (!isGaz) fetchElecDocs() })
            .catch(error => {
                if (error?.response?.status === 401) window.location.reload()
                if (error?.response?.data?.message && !isGaz) {
                    setErrorMessageElec(error.response.data.message)
                    setShowErrorMessageElec(true)
                }
                if (error?.response?.data?.message && fileType === "Facture") {
                    setErrorMessageInvoice(error.response.data.message)
                    setShowErrorMessageInvoice(true)
                }
                if (error?.response?.data?.message && fileType === "Contrat") {
                    setErrorMessageContract(error.response.data.message)
                    setShowErrorMessageContract(true)
                }
            })
    }

    const removeFile = (fileType, fileId) => {
        hideFileMessages()
        axios.delete(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/Site/${siteID}/2${!isGaz ? "/Document" : ""}/${isGaz ? fileType : fileId}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(() => {
                if (!isGaz) {
                    setConfirmationMessageElec("Le fichier a bien été supprimé")
                    setShowConfirmationMessageElec(true)
                    fetchElecDocs()
                }
                else {
                    getSiteData(true)
                    if (fileType === "Facture") {
                        setConfirmationMessageInvoice("Le fichier a bien été supprimé")
                        setShowConfirmationMessageInvoice(true)
                    }
                    else {
                        setConfirmationMessageContract("Le fichier a bien été supprimé")
                        setShowConfirmationMessageContract(true)
                    }
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) window.location.reload()
                if (error?.response?.data?.message && !isGaz) {
                    setErrorMessageElec(error.response.data.message)
                    setShowErrorMessageElec(true)
                }
                else if (error?.response?.data?.message && fileType === "Facture") {
                    setErrorMessageInvoice(error.response.data.message)
                    setShowErrorMessageInvoice(true)
                }
                else if (error?.response?.data?.message && fileType === "Contrat") {
                    setErrorMessageContract(error.response.data.message)
                    setShowErrorMessageContract(true)
                }
            })
    }

    const hideFileMessages = () => {
        setShowConfirmationMessageInvoice(false)
        setShowConfirmationMessageContract(false)
        setShowErrorMessageInvoice(false)
        setShowErrorMessageContract(false)
        setShowConfirmationMessageElec(false)
        setShowErrorMessageElec(false)
    }

    const fetchElecDocs = useCallback(() => {
        resetFiles()
        axios.get(`${apiUrl}/Elec/Site/${siteID}/2/Document/${contract}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                if (response && response.data) {
                    let documentArray = []
                    for (let i = 0; i < response.data.length; i++) {
                        if (response.data[i].type === "Facture") {
                            setSelectedInvoiceName(response.data[i].fichier)
                            setSelectedInvoiceId(response.data[i].documentID)
                        }
                        if (response.data[i].type === "Contrat") {
                            setSelectedContractName(response.data[i].fichier)
                            setSelectedContractId(response.data[i].documentID)
                        }
                        if (response.data[i].type === "DocumentDePDL") {
                            setSelectedPdlName(response.data[i].fichier)
                            setSelectedPdlId(response.data[i].documentID)
                        }
                        if (response.data[i].type === "BilanAnnuel") {
                            setSelectedBilanName(response.data[i].fichier)
                            setSelectedBilanId(response.data[i].documentID)
                        }
                        if (response.data[i].type === "CourbeCharge") {
                            documentArray.push(response.data[i])
                            setCourbeArray(documentArray)
                        }
                        if (response.data[i].type === "FeuilletsGestion") {
                            setSelectedFeuilletName(response.data[i].fichier)
                            setSelectedFeuilletId(response.data[i].documentID)
                        }
                    }

                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [siteID, contract])

    const resetFiles = () => {
        setSelectedInvoiceName("")
        setSelectedInvoiceId("")
        setSelectedContractName("")
        setSelectedContractId("")
        setSelectedPdlName("")
        setSelectedPdlId("")
        setSelectedBilanName("")
        setSelectedBilanId("")
        setSelectedFeuilletName("")
        setSelectedFeuilletId("")
        setCourbeArray([])
    }

    const getDocument = (documentID, fileName) => {
        axios.get(`${apiUrl}/document/${documentID}`,
            {
                withCredentials: true,
                ContentType: 'application/json',
                responseType: 'blob',
            })
            .then((response) => {
                const href = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }))

                const link = document.createElement('a')
                link.href = href

                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(href)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    useEffect(() => {
        getSiteData()
    }, [getSiteData])

    useEffect(() => {
        if (!isGaz) fetchElecDocs()
    }, [fetchElecDocs, isGaz])

    return (
        <>
            <SideBar />
            <div className="homepage--container privatepages--container">
                <div className="privatepages--header__container">
                    <h3 className="privatepages--title privatepages--text">{isAdd ? "Ajouter un nouveau site à votre compte" : isConfirmed ? "Visualiser le site" : "Modifier le site"}</h3>
                    <Link to={fromAppelOffre ? `/appels-offre-${isElec ? "elec" : "gaz"}` : `/${isElec ? "elec" : "gaz"}-sites`}><span className="privatepages--header__return">{`< Retour à la liste des sites ${isGaz ? "Gaz" : "Électricité"}`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                <div className="addsites--step__titlecontainer">
                    <h4 className="addsites--step__title">Étape 2/3 : <span className="addsites--step__titleright">Caractéristiques Consommation {isGaz ? "Gaz" : "Électricité"}</span></h4>
                    <div className="sepa--buttonscontainer">
                        <Button
                            cta="Revenir à l'étape précédente"
                            link={`/${isAdd ? "add" : "modify"}-sites-1?type=${isGaz ? "gaz" : "elec"}&id=${siteID}${fromAppelOffre ? "&from=appels-offre" : ""}`}
                            additionnalClassName="global--button__goback" />
                        <Button
                            cta="Étape suivante"
                            additionnalClassName="addsites--step__button"
                            handleSubmit={() => handleSubmitStep2()}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
                {showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror sepa--messageinfo">{errorMessage}</span>}
                {isConfirmed && <span className="homepage--popup__message homepage--popup__messageconfirmation sepa--messageinfo">Votre site est confirmé. Si vous souhaitez le modifier, merci de contacter l'équipe WattValue.</span>}
                <div className="privatepages--columnscontainer">
                    <div className={`privatepages--twocolumns ${!isGaz ? "privatepages--twocolumns__left" : ""}`}>
                        <div>
                            <span className="privatepages--asterisk privatepages--text">*champ obligatoire</span>
                            <form className="privatepages--form__block  sepa--form__block">
                                <h4 className="privatepages--form__title">{isGaz ? "Votre site" : "Votre contrat actuel"}</h4>
                                {isGaz ? <>
                                    <ToolTip
                                        text={"Consommation Annuelle de Référence en kWh. Information sur votre facture."}
                                        position="right">
                                        <FormInput
                                            label="CAR (kWh)*"
                                            type="text"
                                            id="car"
                                            value={car}
                                            onChangeInput={value => {
                                                if (validNumber.test(value) || value === "") {
                                                    setShowErrorMessage(false)
                                                    setCar(value)
                                                }
                                            }}
                                            maxLength={255}
                                            placeholder="123"
                                            disabled={isConfirmed}
                                        />
                                    </ToolTip>
                                    {showErrorMessageCar && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner la CAR</span>}
                                    <ToolTip
                                        text={"Point de Comptage et d'Estimation (information sur votre facture)."}
                                        position="right">
                                        <FormInput
                                            type="text"
                                            label="PCE*"
                                            id="pce"
                                            onChangeInput={value => {
                                                setShowErrorMessage(false)
                                                setPce(value)
                                            }}
                                            placeholder="12345678912345"
                                            value={pce}
                                            disabled={isConfirmed}
                                        />
                                    </ToolTip>
                                    {showErrorMessagePce && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner le PCE</span>}
                                </>
                                    :
                                    <>
                                        <ToolTip
                                            text={"ou Référence d'Acheminement d'Electricité (RAE)"}
                                            position="right">
                                            <FormInput
                                                type="text"
                                                label="Point de référence et mesure (PRM)*"
                                                id="prm"
                                                onChangeInput={value => {
                                                    setShowErrorMessage(false)
                                                    setPrm(value)
                                                }}
                                                placeholder="12345678912345"
                                                value={prm}
                                                disabled={isConfirmed}
                                            />
                                        </ToolTip>
                                        {showErrorMessagePrm && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner le PRM</span>}
                                    </>
                                }
                                <FormControl>
                                    <FormLabel
                                        id="contract-radio-button"
                                        sx={{
                                            fontFamily: "Raleway",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            lineHeight: "14px",
                                            textAlign: "left",
                                            color: "#566F8F !important",
                                            marginTop: "12px",
                                            marginBottom: "7px",
                                        }}
                                    >Votre contrat actuel</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="contract-radio-button"
                                        name="contract-radio-button"
                                        value={contract}
                                        onChange={(e) => {
                                            setContract(e.target.value)
                                            resetErrorMessages()
                                            hideFileMessages()
                                            setShowCalendar(false)
                                        }}

                                    >
                                        <FormControlLabel
                                            value="PasDeContrat"
                                            sx={{
                                                ".MuiFormControlLabel-label": {
                                                    fontFamily: "Raleway",
                                                    fontSize: "12px",
                                                    fontWeight: 400,
                                                    lineHeight: "14px",
                                                    textAlign: "left",
                                                    color: "#566F8F",
                                                },
                                            }}
                                            control={
                                                <Radio sx={{
                                                    color: "#566F8F",
                                                    padding: "2px 9px",
                                                    '&.Mui-checked': {
                                                        color: "#566F8F",
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 15,
                                                    },
                                                    ".MuiFormControlLabel-label": {
                                                        fontFamily: "Raleway",
                                                        fontSize: "10px",
                                                        fontWeight: 400,
                                                        lineHeight: "14px",
                                                        textAlign: "left",

                                                    },
                                                }}
                                                    disabled={isConfirmed} />
                                            } label="Pas de contrat en cours (nouveau site)" />
                                        <FormControlLabel
                                            value="OffreMarché"
                                            sx={{
                                                ".MuiFormControlLabel-label": {
                                                    fontFamily: "Raleway",
                                                    fontSize: "12px",
                                                    fontWeight: 400,
                                                    lineHeight: "14px",
                                                    textAlign: "left",
                                                    color: "#566F8F",
                                                },
                                            }}
                                            control={
                                                <Radio sx={{
                                                    color: "#566F8F",
                                                    padding: "2px 9px",
                                                    '&.Mui-checked': {
                                                        color: "#566F8F",
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 15,
                                                    },
                                                    ".MuiFormControlLabel-label": {
                                                        fontFamily: "Raleway",
                                                        fontSize: "10px",
                                                        fontWeight: 400,
                                                        lineHeight: "14px",
                                                        textAlign: "left",

                                                    },
                                                }}
                                                    disabled={isConfirmed} />
                                            } label="Offre de marché" />
                                        <FormControlLabel
                                            value="TRV"
                                            sx={{
                                                ".MuiFormControlLabel-label": {
                                                    fontFamily: "Raleway",
                                                    fontSize: "12px",
                                                    fontWeight: 400,
                                                    lineHeight: "14px",
                                                    textAlign: "left",
                                                    color: "#566F8F",
                                                },
                                            }}
                                            control={
                                                <Radio sx={{
                                                    color: "#566F8F",
                                                    padding: "2px 9px",
                                                    '&.Mui-checked': {
                                                        color: "#566F8F",
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 15,
                                                    },
                                                    ".MuiFormControlLabel-label": {
                                                        fontFamily: "Raleway",
                                                        fontSize: "10px",
                                                        fontWeight: 400,
                                                        lineHeight: "14px",
                                                        textAlign: "left",

                                                    },
                                                }}
                                                    disabled={isConfirmed} />
                                            } label="Tarifs réglementés de vente (TRV)" />
                                    </RadioGroup>
                                </FormControl>
                                {(!isGaz && contract === "TRV") &&
                                    <div className="addsites--radiogroup__trv">
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="trv-radio-button"
                                                name="trv-radio-button"
                                                value={trvChoix}
                                                onChange={(e) => {
                                                    hideFileMessages()
                                                    setTrvChoix(e.target.value)
                                                }}

                                            >
                                                <FormControlLabel
                                                    value="Bleu"
                                                    sx={{
                                                        ".MuiFormControlLabel-label": {
                                                            fontFamily: "Raleway",
                                                            fontSize: "12px",
                                                            fontWeight: 400,
                                                            lineHeight: "14px",
                                                            textAlign: "left",
                                                            color: "#566F8F",
                                                        },
                                                    }}
                                                    control={
                                                        <Radio sx={{
                                                            color: "#566F8F",
                                                            padding: "2px 9px",
                                                            '&.Mui-checked': {
                                                                color: "#566F8F",
                                                            },
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 15,
                                                            },
                                                            ".MuiFormControlLabel-label": {
                                                                fontFamily: "Raleway",
                                                                fontSize: "10px",
                                                                fontWeight: 400,
                                                                lineHeight: "14px",
                                                                textAlign: "left",

                                                            },
                                                        }}
                                                            disabled={isConfirmed} />
                                                    } label="Bleu" />
                                                <FormControlLabel
                                                    value="Jaune"
                                                    sx={{
                                                        ".MuiFormControlLabel-label": {
                                                            fontFamily: "Raleway",
                                                            fontSize: "12px",
                                                            fontWeight: 400,
                                                            lineHeight: "14px",
                                                            textAlign: "left",
                                                            color: "#566F8F",
                                                        },
                                                    }}
                                                    control={
                                                        <Radio sx={{
                                                            color: "#566F8F",
                                                            padding: "2px 9px",
                                                            '&.Mui-checked': {
                                                                color: "#566F8F",
                                                            },
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 15,
                                                            },
                                                            ".MuiFormControlLabel-label": {
                                                                fontFamily: "Raleway",
                                                                fontSize: "10px",
                                                                fontWeight: 400,
                                                                lineHeight: "14px",
                                                                textAlign: "left",

                                                            },
                                                        }}
                                                            disabled={isConfirmed} />
                                                    } label="Jaune" />
                                                <FormControlLabel
                                                    value="Vert"
                                                    sx={{
                                                        ".MuiFormControlLabel-label": {
                                                            fontFamily: "Raleway",
                                                            fontSize: "12px",
                                                            fontWeight: 400,
                                                            lineHeight: "14px",
                                                            textAlign: "left",
                                                            color: "#566F8F",
                                                        },
                                                    }}
                                                    control={
                                                        <Radio sx={{
                                                            color: "#566F8F",
                                                            padding: "2px 9px",
                                                            '&.Mui-checked': {
                                                                color: "#566F8F",
                                                            },
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 15,
                                                            },
                                                            ".MuiFormControlLabel-label": {
                                                                fontFamily: "Raleway",
                                                                fontSize: "10px",
                                                                fontWeight: 400,
                                                                lineHeight: "14px",
                                                                textAlign: "left",

                                                            },
                                                        }}
                                                            disabled={isConfirmed} />
                                                    } label="Vert" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                }
                                {contract === "PasDeContrat" &&
                                    <>
                                        <span className="addsites--enddate__label">Date début fourniture souhaitée*</span>
                                        <span id="date2" className={`addsites--enddate__disabled addsites--date ${!isConfirmed ? "addsites--enddate__cliquable" : ""}`} onClick={!isConfirmed ? () => setShowCalendar(!showCalendar) : null}>
                                            <CalendarMonthIcon sx={{ fontSize: "18px", color: "#566F8F", marginRight: "5px" }} />
                                            {(formatedContratDateDebut)}
                                        </span>
                                        {showErrorMessageContratDateDebut && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner une date de début de fourniture valable</span>}

                                    </>}
                                {(!isGaz && contract === "OffreMarché") && <FormControl>
                                    <FormLabel
                                        id="contract-radio-button"
                                        sx={{
                                            fontFamily: "Raleway",
                                            fontSize: "12px",
                                            fontWeight: 500,
                                            lineHeight: "14px",
                                            textAlign: "left",
                                            color: "#566F8F !important",
                                            marginTop: "12px",
                                            marginBottom: "7px",
                                        }}
                                    >Avez-vous un contrat intégrant de l'énergie renouvelable (par exemple kWh Equilibre de EDF, ou autre) ?*</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="contract-radio-button"
                                        name="contract-radio-button"
                                        value={hasGreenContract}
                                        onChange={(e) => setHasGreenContract(e.target.value)}

                                    >
                                        <FormControlLabel
                                            value={true}
                                            sx={{
                                                ".MuiFormControlLabel-label": {
                                                    fontFamily: "Raleway",
                                                    fontSize: "12px",
                                                    fontWeight: 400,
                                                    lineHeight: "14px",
                                                    textAlign: "left",
                                                    color: "#566F8F",
                                                },
                                            }}
                                            control={
                                                <Radio sx={{
                                                    color: "#566F8F",
                                                    padding: "2px 9px",
                                                    '&.Mui-checked': {
                                                        color: "#566F8F",
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 15,
                                                    },
                                                    ".MuiFormControlLabel-label": {
                                                        fontFamily: "Raleway",
                                                        fontSize: "10px",
                                                        fontWeight: 400,
                                                        lineHeight: "14px",
                                                        textAlign: "left",

                                                    },
                                                }}
                                                    disabled={isConfirmed} />
                                            } label="Oui" />
                                        <FormControlLabel
                                            value={false}
                                            sx={{
                                                ".MuiFormControlLabel-label": {
                                                    fontFamily: "Raleway",
                                                    fontSize: "12px",
                                                    fontWeight: 400,
                                                    lineHeight: "14px",
                                                    textAlign: "left",
                                                    color: "#566F8F",
                                                },
                                            }}
                                            control={
                                                <Radio sx={{
                                                    color: "#566F8F",
                                                    padding: "2px 9px",
                                                    '&.Mui-checked': {
                                                        color: "#566F8F",
                                                    },
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 15,
                                                    },
                                                    ".MuiFormControlLabel-label": {
                                                        fontFamily: "Raleway",
                                                        fontSize: "10px",
                                                        fontWeight: 400,
                                                        lineHeight: "14px",
                                                        textAlign: "left",

                                                    },
                                                }}
                                                    disabled={isConfirmed} />
                                            } label="Non" />
                                    </RadioGroup>
                                </FormControl>}
                                {showErrorMessageHasGreenContract && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez répondre à la question</span>}
                                {(contract === "PasDeContrat" && isGaz) &&
                                    <FormInput
                                        type="number"
                                        label="Consommation estimé (kWh)"
                                        id="conso"
                                        onChangeInput={value => {
                                            setShowErrorMessage(false)
                                            setConso(value)
                                        }}
                                        placeholder="12000"
                                        value={conso}
                                        disabled={isConfirmed}
                                    />
                                }
                                {!isGaz &&
                                    <>
                                        <FormInput
                                            type="text"
                                            label="Consommation totale d'énergie annuelle (kWh)*"
                                            id="conso"
                                            onChangeInput={value => {
                                                if (validNumber.test(value) || value === "") {
                                                    setShowErrorMessage(false)
                                                    setConsoAnnuelle(value)
                                                }
                                            }}
                                            placeholder="12000"
                                            value={consoAnnuelle}
                                            disabled={isConfirmed}
                                        />
                                        {showErrorMessageConsoAnnuelle && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner la consommation totale d'énergie annuelle</span>}
                                    </>
                                }
                                {contract === "OffreMarché" &&
                                    <>
                                        <span className="addsites--enddate__label">Date d'échéance du contrat*</span>
                                        <span id="date1" className={`addsites--enddate__disabled  addsites--date ${!isConfirmed ? "addsites--enddate__cliquable" : ""}`} onClick={!isConfirmed ? () => setShowCalendar(!showCalendar) : null} >
                                            <CalendarMonthIcon sx={{ fontSize: "18px", color: "#566F8F", marginRight: "5px" }} />
                                            {formatedContratDateEcheance}
                                        </span>
                                        {showErrorMessageContratDateEcheance && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner une date d'échéance valable</span>}
                                    </>}
                                {showCalendar &&
                                    <div className="addsites--calendar__container" ref={ref}>
                                        <CloseIcon sx={{ cursor: "pointer", fontSize: "medium" }} onClick={() => setShowCalendar(false)} />
                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                            <DateCalendar value={contract === "PasDeContrat" ? dayjs(contratDateDebut) : dayjs(contratDateEcheance)}
                                                sx={{
                                                    backgroundColor: "white",
                                                    zIndex: "100",
                                                    position: "relative",
                                                    width: "280px",
                                                    "& .MuiPickersDay-root": {
                                                        "&.Mui-selected": {
                                                            backgroundColor: "#48AB4D",
                                                        },
                                                    },
                                                    "&.MuiPickersDay-root.Mui-selected": {
                                                        backgroundColor: "red",
                                                    },
                                                }}
                                                onChange={(newValue) => {
                                                    if (contract === "PasDeContrat") {
                                                        setContratDateDebut(newValue)
                                                        setFormatedContratDateDebut(new Date(dayjs(newValue)).toLocaleDateString())
                                                    }
                                                    else {
                                                        setContratDateEcheance(newValue)
                                                        setFormatedContratDateEcheance(new Date(dayjs(newValue)).toLocaleDateString())
                                                    }

                                                }} />
                                        </LocalizationProvider>
                                    </div>
                                }
                                {(isGaz && contract === "PasDeContrat") &&
                                    <div className="sepa--form__uploadcontainer">
                                        <ToolTip
                                            text={"Document (courrier ou email) du gestionnaire de réseau ou de votre contact technique indiquant le numéro de PDL."}
                                            type="headcell"
                                            marginTop="0"
                                            top={20}
                                            left={20}>
                                            <span className="elec--add__invoicetitle">
                                                Document PDL*
                                                <InfoOutlinedIcon sx={{ fontSize: "15px", padding: "0 0 0 5px" }} />
                                            </span>
                                        </ToolTip>
                                        <span className="elec--add__invoicesubtitle">N° de PDL fourni le gestionnaire de réseau</span>
                                        <div className="sepa--form__inputcontainer elec--form__inputcontainer">
                                            <FormInput
                                                type="file"
                                                id="pdl-file"
                                                fileName={selectedInvoiceName ? selectedInvoiceName : ""}
                                                disabled={(selectedInvoiceName && selectedInvoiceName !== "") || isConfirmed}
                                                onClick={(event) => {
                                                    event.target.value = null
                                                }}
                                                placeholder="Choisir un fichier"
                                                onChangeInput={file => {
                                                    if (file) {
                                                        addFile(file, "Facture")
                                                        setSelectedInvoiceName(file.name)
                                                    }
                                                }}
                                                fileId={selectedInvoiceId}
                                                getDocument={getDocument}
                                            />
                                            {!isConfirmed && <HighlightOffIcon onClick={() => {
                                                setSelectedInvoiceName("")
                                                removeFile("Facture", selectedInvoiceId)
                                            }}
                                                sx={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }} />}
                                        </div>
                                        {showConfirmationMessageInvoice && <span className="homepage--popup__message homepage--popup__messageconfirmation">{confirmationMessageInvoice}</span>}
                                        {showErrorMessageInvoice && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessageInvoice}</span>}
                                    </div>
                                }
                                {(isGaz && (contract === "TRV" || contract === "OffreMarché")) &&
                                    <div className="sepa--form__uploadcontainer">
                                        <span className="elec--add__invoicetitle">Facture*</span>
                                        <span className="elec--add__invoicesubtitle">(Fichier .pdf)</span>
                                        <div className="sepa--form__inputcontainer elec--form__inputcontainer">
                                            <FormInput
                                                type="file"
                                                id="invoice-file"
                                                fileName={selectedInvoiceName ? selectedInvoiceName : ""}
                                                disabled={(selectedInvoiceName && selectedInvoiceName !== "") || isConfirmed}
                                                onClick={(event) => {
                                                    event.target.value = null
                                                }}
                                                placeholder="Choisir un fichier"
                                                onChangeInput={file => {
                                                    if (file) {
                                                        addFile(file, "Facture")
                                                        setSelectedInvoiceName(file.name)
                                                    }
                                                }}
                                                fileId={selectedInvoiceId}
                                                getDocument={getDocument}
                                            />
                                            {!isConfirmed && <HighlightOffIcon onClick={() => {
                                                setSelectedInvoiceName("")
                                                removeFile("Facture", selectedInvoiceId)
                                            }}
                                                sx={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }} />}
                                        </div>
                                        {showConfirmationMessageInvoice && <span className="homepage--popup__message homepage--popup__messageconfirmation">{confirmationMessageInvoice}</span>}
                                        {showErrorMessageInvoice && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessageInvoice}</span>}
                                    </div>}
                                {(contract === "OffreMarché" && isGaz) &&
                                    <div className="sepa--form__uploadcontainer">
                                        <span className="elec--add__invoicetitle">Contrat de marché</span>
                                        <span className="elec--add__invoicesubtitle">(Fichier .pdf)</span>
                                        <div className="sepa--form__inputcontainer elec--form__inputcontainer">
                                            <FormInput
                                                type="file"
                                                id="contract-file"
                                                fileName={selectedContractName ? selectedContractName : ""}
                                                disabled={(selectedContractName && selectedContractName !== "") || isConfirmed}
                                                onClick={(event) => {
                                                    event.target.value = null
                                                }}
                                                placeholder="Choisir un fichier"
                                                onChangeInput={file => {
                                                    if (file) {
                                                        setSelectedContractName(file.name)
                                                        addFile(file, "Contrat")
                                                    }
                                                }}
                                                fileId={selectedContractId}
                                                getDocument={getDocument}
                                            />
                                            {!isConfirmed && <HighlightOffIcon onClick={() => {
                                                setSelectedContractName("")
                                                removeFile("Contrat", selectedContractId)
                                            }}
                                                sx={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }} />}
                                        </div>
                                    </div>
                                }
                                {showConfirmationMessageContract && <span className="homepage--popup__message homepage--popup__messageconfirmation">{confirmationMessageContract}</span>}
                                {showErrorMessageContract && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessageContract}</span>}
                            </form>
                        </div>
                    </div>
                    {!isGaz && <div className="privatepages--twocolumns privatepages--twocolumns__right">
                        <form className="privatepages--form__block">
                            <h4 className="privatepages--form__title">Document(s) à télécharger</h4>
                            <span className="privatepages--form__subtitle">Vous devez télécharger les documents suivants :</span>
                            {(contract === "OffreMarché" || contract === "TRV") &&
                                <>
                                    <div className="sepa--form__uploadcontainer">
                                        <span className="elec--add__invoicetitle">Dernière facture complète de moins de 3 mois*</span>
                                        <div className="sepa--form__inputcontainer elec--form__inputcontainer">
                                            <FormInput
                                                type="file"
                                                id="elec-invoice-file"
                                                fileName={selectedInvoiceName ? selectedInvoiceName : ""}
                                                disabled={(selectedInvoiceName && selectedInvoiceName !== "") || isConfirmed}
                                                onClick={(event) => {
                                                    event.target.value = null
                                                }}
                                                placeholder="Choisir un fichier"
                                                onChangeInput={file => {
                                                    if (file) {
                                                        addFile(file, "Facture")
                                                        setSelectedInvoiceName(file.name)
                                                    }
                                                }}
                                                fileId={selectedInvoiceId}
                                                getDocument={getDocument}
                                            />
                                            {!isConfirmed && <HighlightOffIcon onClick={() => {
                                                setSelectedInvoiceName("")
                                                removeFile("Facture", selectedInvoiceId)
                                            }}
                                                sx={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }} />}
                                        </div>
                                    </div>
                                </>
                            }
                            {contract === "PasDeContrat" &&
                                <div className="sepa--form__uploadcontainer">
                                    <ToolTip
                                        text={"Document (courrier ou email) du gestionnaire de réseau ou de votre contact technique indiquant le numéro de PDL."}
                                        type="headcell"
                                        marginTop="0"
                                        top={20}
                                        left={20}>
                                        <span className="elec--add__invoicetitle">
                                            Document PDL*
                                            <InfoOutlinedIcon sx={{ fontSize: "15px", padding: "0 0 0 5px" }} />
                                        </span>
                                    </ToolTip>
                                    <span className="elec--add__invoicesubtitle">N° de PDL fourni le gestionnaire de réseau</span>
                                    <div className="sepa--form__inputcontainer elec--form__inputcontainer">
                                        <FormInput
                                            type="file"
                                            id="pdl-file"
                                            fileName={selectedPdlName ? selectedPdlName : ""}
                                            disabled={(selectedPdlName && selectedPdlName !== "") || isConfirmed}
                                            onClick={(event) => {
                                                event.target.value = null
                                            }}
                                            placeholder="Choisir un fichier"
                                            onChangeInput={file => {
                                                if (file) {
                                                    addFile(file, "DocumentDePDL")
                                                    setSelectedPdlName(file.name)
                                                }
                                            }}
                                            fileId={selectedPdlId}
                                            getDocument={getDocument}
                                        />
                                        {!isConfirmed && <HighlightOffIcon onClick={() => {
                                            setSelectedPdlName("")
                                            removeFile("DocumentDePDL", selectedPdlId)
                                        }}
                                            sx={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }} />}
                                    </div>
                                </div>
                            }
                            {contract === "OffreMarché" &&
                                <>
                                    <div className="sepa--form__uploadcontainer">
                                        <span className="elec--add__invoicetitle">Bilan annuel de facturation</span>
                                        <span className="elec--add__invoicesubtitle">(1 fichier PDF, idéalement au format excel)</span>
                                        <div className="sepa--form__inputcontainer elec--form__inputcontainer">
                                            <FormInput
                                                type="file"
                                                id="pdl-file"
                                                fileName={selectedBilanName ? selectedBilanName : ""}
                                                disabled={(selectedBilanName && selectedBilanName !== "") || isConfirmed}
                                                onClick={(event) => {
                                                    event.target.value = null
                                                }}
                                                placeholder="Choisir un fichier"
                                                onChangeInput={file => {
                                                    if (file) {
                                                        addFile(file, "BilanAnnuel")
                                                        setSelectedBilanName(file.name)
                                                    }
                                                }}
                                                fileId={selectedBilanId}
                                                getDocument={getDocument}
                                            />
                                            {!isConfirmed && <HighlightOffIcon onClick={() => {
                                                setSelectedBilanName("")
                                                removeFile("BilanAnnuel", selectedBilanId)
                                            }}
                                                sx={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }} />}
                                        </div>
                                    </div>
                                    <div className="sepa--form__uploadcontainer">
                                        <span className="elec--add__invoicetitle">Contrat</span>
                                        <div className="sepa--form__inputcontainer elec--form__inputcontainer">
                                            <FormInput
                                                type="file"
                                                id="elec-contract-file"
                                                fileName={selectedContractName ? selectedContractName : ""}
                                                disabled={(selectedContractName && selectedContractName !== "") || isConfirmed}
                                                onClick={(event) => {
                                                    event.target.value = null
                                                }}
                                                placeholder="Choisir un fichier"
                                                onChangeInput={file => {
                                                    if (file) {
                                                        addFile(file, "Contrat")
                                                        setSelectedContractName(file.name)
                                                    }
                                                }}
                                                fileId={selectedContractId}
                                                getDocument={getDocument}
                                            />
                                            {!isConfirmed && <HighlightOffIcon onClick={() => {
                                                setSelectedContractName("")
                                                removeFile("Contrat", selectedContractId)
                                            }}
                                                sx={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }} />}
                                        </div>
                                    </div>
                                </>
                            }
                            {contract === "TRV" &&
                                <>
                                    <div className="sepa--form__uploadcontainer">
                                        <span className="elec--add__invoicetitle">Feuillet de gestion*</span>
                                        <span className="elec--add__invoicesubtitle">Dernier feuillet de gestion (fichier PDF, ou au format excel)</span>
                                        <div className="sepa--form__inputcontainer elec--form__inputcontainer">
                                            <FormInput
                                                type="file"
                                                id="elec-feuillet-file"
                                                fileName={selectedFeuilletName ? selectedFeuilletName : ""}
                                                disabled={(selectedFeuilletName && selectedFeuilletName !== "") || isConfirmed}
                                                onClick={(event) => {
                                                    event.target.value = null
                                                }}
                                                placeholder="Choisir un fichier"
                                                onChangeInput={file => {
                                                    if (file) {
                                                        addFile(file, "FeuilletsGestion")
                                                        setSelectedFeuilletName(file.name)
                                                    }
                                                }}
                                                fileId={selectedFeuilletId}
                                                getDocument={getDocument}
                                            />
                                            {!isConfirmed && <HighlightOffIcon onClick={() => {
                                                setSelectedFeuilletName("")
                                                removeFile("FeuilletsGestion", selectedFeuilletId)
                                            }}
                                                sx={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }} />}
                                        </div>
                                    </div>
                                </>}
                            {(contract === "OffreMarché" || (contract === "TRV" && trvChoix === "Vert")) &&
                                <div className="sepa--form__uploadcontainer">
                                    <span className="elec--add__invoicetitle">Courbe de charge au pas de 10 minutes</span>
                                    <span className="elec--add__invoicesubtitle">(12 fichiers - 1 par mois)</span>
                                    {(courbeArray.length < 10 && !isConfirmed) &&
                                        <div className="sepa--form__inputcontainer elec--form__inputcontainer">
                                            <FormInput
                                                type="file"
                                                id="courbe-file"
                                                fileName={""}
                                                disabled={isConfirmed}
                                                onClick={(event) => {
                                                    event.target.value = null
                                                }}
                                                placeholder="Choisir un fichier"
                                                onChangeInput={file => {
                                                    if (file) {
                                                        addFile(file, "CourbeCharge")
                                                    }
                                                }}
                                            />
                                        </div>}
                                    {courbeArray.map((file, index) => {
                                        return (
                                            <div key={`courbe-files-${index}`} className="sepa--form__inputcontainer elec--form__inputcontainer">
                                                <FormInput
                                                    type="file"
                                                    id={`courbe-file-${index}`}
                                                    fileName={file.fichier ? file.fichier : ""}
                                                    disabled
                                                    noIcon
                                                    withEyeIcon
                                                    fileId={courbeArray[index].documentID}
                                                    getDocument={getDocument}
                                                />
                                                {!isConfirmed && <HighlightOffIcon onClick={() => {
                                                    removeFile("CourbeCharge", courbeArray[index].documentID)
                                                }}
                                                    sx={{ fontSize: "20px", marginLeft: "10px", cursor: "pointer" }} />}
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            {showConfirmationMessageElec && <span className="homepage--popup__message homepage--popup__messageconfirmation">{confirmationMessageElec}</span>}
                            {showErrorMessageElec && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessageElec}</span>}
                        </form>
                    </div>}
                </div>
            </div>
        </>
    )
}

export default AddSitesStep2;