import SideBar from "../../Components/SideBar/SideBar"
import axios from "axios"
import { useState, useEffect, useCallback } from "react"
import AppelsOffreTable from "./AppelsOffreTable"
import Open from '../../img/icons/suppr.svg'
import CalendarModal from "./CalendarModal"
import { useLocation, useNavigate } from "react-router"
import ArrowUp from '../../img/icons/arrow-up.svg'
import ArrowDown from '../../img/icons/arrow-down.svg'
import Bulb from '../../img/icons/bulb.svg'
import ToolTip from "../../Components/ToolTip"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const apiUrl = process.env.REACT_APP_API_URL

function AppelsOffre() {
    const navigate = useNavigate()
    const [appelsOffre, setAppelsOffre] = useState([])
    const [isDataLoading, setIsDataLoading] = useState(false)
    const location = useLocation()

    const [showCalendarModal, setShowCalendarModal] = useState(false)
    const [dateFinSouscription, setDateFinSouscription] = useState("")
    const [dateB3Diffusion, setDateB3Diffusion] = useState("")
    const [datePrixIndicatif, setDatePrixIndicatif] = useState("")
    const [dateB3DiffusionDefinitif, setDateB3DiffusionDefinitif] = useState("")
    const [datePrixDefinitif, setDatePrixDefinitif] = useState("")
    const [dateFinRemiseB3Indicatif, setDateFinRemiseB3Indicatif] = useState("")
    const [dateLimiteReport, setDateLimiteReport] = useState("")
    const [dateFinRemiseB3, setDateFinRemiseB3] = useState("")

    const [showAppelOffres, setShowAppelOffres] = useState([])

    const isGaz = window.location.pathname.includes('gaz')

    const fetchAppelsOffre = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/AppelOffre`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                setAppelsOffre(response.data)

                let showAppelOffres = []
                response.data.forEach(() => showAppelOffres.push(false))
                showAppelOffres[0] = true
                setShowAppelOffres(showAppelOffres)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [isGaz])

    useEffect(() => {
        fetchAppelsOffre()
    }, [fetchAppelsOffre])

    useEffect(() => {
        setShowCalendarModal(false)
    }, [location])

    const closeCalendarModal = () => {
        setDateFinSouscription("")
        setDateB3Diffusion("")
        setDatePrixIndicatif("")
        setDateB3DiffusionDefinitif("")
        setDatePrixDefinitif("")
        setDateFinRemiseB3Indicatif("")
        setDateLimiteReport("")
        setDateFinRemiseB3("")
        setShowCalendarModal(false)
    }

    const getResults = (appelOffresID, libelle) => {
        axios.get(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/AppelOffre/${appelOffresID}/export`,
            {
                withCredentials: true,
                responseType: 'blob',
            })
            .then((response) => {
                const href = window.URL.createObjectURL(new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))

                const link = document.createElement('a')
                link.href = href

                link.setAttribute('download', `Resultats-AO-${isGaz ? "Gaz" : "Elec"}-${libelle}`)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(href)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const showAppelOffresBlock = (index) => {
        const showAppelOffresCopy = showAppelOffres.map((appelOffre, i) => {
            if (i === index) {
                return !appelOffre
            } else {
                return appelOffre
            }
        })
        setShowAppelOffres(showAppelOffresCopy)
    }

    const handleReengagement = (siteId) => {
        axios.post(`${apiUrl}/${isGaz ? "Gaz" : "Elec"}/Site/${siteId}/1/Reengagement`,
            "",
            {
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            })
            .then((response) => {
                navigate(`/modify-sites-1?type=${isGaz ? "gaz" : "elec"}&id=${response.data}&from=appels-offre`)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    return (
        <>
            <SideBar
                active={isGaz ? "Appels d'offres gaz" : "Appels d'offres elec"}
                openGaz={isGaz}
                openElec={!isGaz} />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Mes appels d'offres {isGaz ? "Gaz" : "Électricité"}</h3>
                <div className="privatepages--separator"></div>
                <div className="table--tablecontainer">
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            {appelsOffre.length > 0 ? appelsOffre.map((appel, index) => {
                                const resultNone = appel.sites.every(element => element.resultType === "None")
                                const hasReengagement = appel.sites.some(element => element.canReengagement)

                                let today = new Date()
                                today.setHours(0, 0, 0, 0)

                                let dateFinSouscription = new Date(appel.calendrier.dateFinSouscription)
                                dateFinSouscription.setHours(0, 0, 0, 0)

                                let dateB3Diffusion = new Date(appel.calendrier.dateB3Diffusion)
                                dateB3Diffusion.setHours(0, 0, 0, 0)

                                let datePrixIndicatif = new Date(appel.calendrier.datePrixIndicatif)
                                datePrixIndicatif.setHours(0, 0, 0, 0)

                                let dateB3DiffusionDefinitif = new Date(appel.calendrier.dateB3DiffusionDefinitif)
                                dateB3DiffusionDefinitif.setHours(0, 0, 0, 0)

                                let datePrixDefinitif = new Date(appel.calendrier.datePrixDefinitif)
                                datePrixDefinitif.setHours(0, 0, 0, 0)

                                let dateFinRemiseB3Indicatif = new Date(appel.calendrier.dateFinRemiseB3Indicatif)
                                dateFinRemiseB3Indicatif.setHours(0, 0, 0, 0)

                                let dateLimiteReport = new Date(appel.calendrier.dateLimiteReport)
                                dateLimiteReport.setHours(0, 0, 0, 0)

                                let sitesCanReengagement = []
                                appel.sites.forEach(site => {
                                    if (site.canReengagement) sitesCanReengagement.push(site)
                                })

                                if (appel.sites.length > 0) {
                                    return (
                                        <div className="mandates--container" key={`appel-offre-site-${appel.appelOffresID}-${index}`}>
                                            <div className="appeloffre--header__container">
                                                <div className="appeloffre--header__containerleft" onClick={() => showAppelOffresBlock(index)}>
                                                    <div className="mandates--link__container appeloffre--header__spacebetween appeloffre--header__width240">
                                                        <h4 className="mandates--site__title appeloffre--header__title">{`Appel d'offres : ${appel.libelle}`}</h4>
                                                        {showAppelOffres[index] ? <img src={ArrowUp} alt="Plier/déplier la catégorie" className="appeloffre--header__dropdownicon" /> : <img src={ArrowDown} alt="Plier/déplier la catégorie" className="appeloffre--header__dropdownicon" />}
                                                    </div>
                                                    {(dateFinSouscription.getTime() > today.getTime() || dateFinSouscription.getTime() === today.getTime()) && <span className="appeloffre--header__pastille appeloffre--header__pastilleinscriptionencours">INSCRIPTION EN COURS</span>}
                                                    {((dateFinSouscription.getTime() < today.getTime() && (dateB3Diffusion.getTime() > today.getTime() || dateFinRemiseB3Indicatif.getTime() > today.getTime()))) && <span className="appeloffre--header__pastille appeloffre--header__pastilletourindicatifencours">TOUR INDICATIF EN COURS</span>}
                                                    {((((dateB3Diffusion.getTime() < today.getTime() || dateFinRemiseB3Indicatif.getTime() < today.getTime()) && (datePrixIndicatif.getTime() > today.getTime() || dateLimiteReport.getTime() > today.getTime())) || (dateB3Diffusion.getTime() === today.getTime() || dateLimiteReport.getTime() === today.getTime()))) && <span className="appeloffre--header__pastille appeloffre--header__pastilleresultatsindicatifs">RESULTATS INDICATIFS DISPONIBLES</span>}
                                                    {(((datePrixIndicatif.getTime() < today.getTime() && dateB3DiffusionDefinitif.getTime() > today.getTime()) || datePrixIndicatif.getTime() === today.getTime())) && <span className="appeloffre--header__pastille appeloffre--header__pastilleresultats">TOUR FINAL EN COURS</span>}
                                                    {(((datePrixDefinitif.getTime() < today.getTime() || datePrixDefinitif.getTime() === today.getTime()) && !resultNone)) && <span className="appeloffre--header__pastille appeloffre--header__pastilleresultats">RESULTATS FINAUX DISPONIBLES</span>}
                                                    {hasReengagement ? <span className="appeloffre--header__pastille appeloffre--header__pastillereengagement">
                                                        REENGAGEMENT OUVERT
                                                        <ToolTip
                                                            text={"Des sites ayant participé à cet appel d'offres peuvent intégrer la prochaine consultation."}
                                                            type="headcell"
                                                            marginTop="0"
                                                            top={38}
                                                            left={5}>
                                                            <InfoOutlinedIcon sx={{ fontSize: "18px", padding: "0 0 0 5px", display: "flex" }} />
                                                        </ToolTip></span> : ""}
                                                </div>
                                                <div className="appeloffre--header__linkscontainer">
                                                    <span
                                                        onClick={() => {
                                                            setDateFinSouscription(appel.calendrier.dateFinSouscription)
                                                            setDateB3Diffusion(appel.calendrier.dateB3Diffusion)
                                                            setDatePrixIndicatif(appel.calendrier.datePrixIndicatif)
                                                            setDateB3DiffusionDefinitif(appel.calendrier.dateB3DiffusionDefinitif)
                                                            setDatePrixDefinitif(appel.calendrier.datePrixDefinitif)
                                                            setDateFinRemiseB3Indicatif(appel.calendrier.dateFinRemiseB3Indicatif)
                                                            setDateLimiteReport(appel.calendrier.dateLimiteReport)
                                                            setDateFinRemiseB3(appel.calendrier.dateFinRemiseB3)
                                                            setShowCalendarModal(true)
                                                        }}
                                                        className="appeloffre--header__link appeloffre--header__linkfirst">
                                                        Voir le calendrier de consultation
                                                        <img src={Open} alt="Ouvrir le calendrier" className="appeloffre--header__icon" />
                                                    </span>
                                                    {appel.reglement && <span
                                                        onClick={() => window.open(`https://api.wattunity.com/${appel.reglement}`, '_blank', 'noopener')}
                                                        className="appeloffre--header__link">
                                                        Télécharger le réglement de la consultation
                                                        <img src={Open} alt="Télécharger le règlement de la consultation" className="appeloffre--header__icon" />
                                                    </span>}
                                                    {!resultNone && <span
                                                        onClick={() => getResults(appel.appelOffresID, appel.libelle)}
                                                        className="appeloffre--header__link">
                                                        Télécharger les résultats
                                                        <img src={Open} alt="Télécharger les résultats" className="appeloffre--header__icon" />
                                                    </span>}
                                                </div>
                                            </div>
                                            {(showAppelOffres[index]) && (
                                                <>
                                                    {appel.sites.length > 0 && <AppelsOffreTable
                                                        rows={appel.sites}
                                                        isGaz={isGaz}
                                                        appelOffresID={appel.appelOffresID}
                                                    />}
                                                    {sitesCanReengagement.length > 0 &&
                                                        <>
                                                            <span className="appeloffre--reengagement__title">
                                                                <img src={Bulb} alt="Attention" className="appeloffre--reengagement__icon" />
                                                                Vos sites éligibles au réengagement
                                                            </span>
                                                            <AppelsOffreTable
                                                                rows={sitesCanReengagement}
                                                                isGaz={isGaz}
                                                                appelOffresID={appel.appelOffresID}
                                                                isReengagement
                                                                handleReengagement={handleReengagement}
                                                            />
                                                        </>
                                                    }
                                                </>
                                            )
                                            }
                                        </div>
                                    )
                                }
                                else return ""
                            })
                                :
                                <span>Aucun appel d'offres trouvé.</span>}
                        </>
                    }
                </div>
            </div>
            {showCalendarModal &&
                <CalendarModal
                    dateFinSouscription={dateFinSouscription}
                    dateB3Diffusion={dateB3Diffusion}
                    datePrixIndicatif={datePrixIndicatif}
                    dateB3DiffusionDefinitif={dateB3DiffusionDefinitif}
                    datePrixDefinitif={datePrixDefinitif}
                    dateFinRemiseB3Indicatif={dateFinRemiseB3Indicatif}
                    dateLimiteReport={dateLimiteReport}
                    dateFinRemiseB3={dateFinRemiseB3}
                    closeModal={closeCalendarModal}
                />}
        </>
    )
}

export default AppelsOffre