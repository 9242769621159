import { useState, useEffect, useCallback } from "react"
import { useLocation, useNavigate, useSearchParams, Link } from 'react-router-dom'

import SideBar from "../../Components/SideBar/SideBar"
import axios from "axios"
import Button from "../../Components/Button"
import Switch, { switchClasses } from '@mui/joy/Switch'
import FormInput from '../../Components/Form/FormInput'
import { validEmail } from "../../Utils/Regex/vallidEmail"
import { validPhone } from "../../Utils/Regex/validPhone"
import { validMobilePhone } from "../../Utils/Regex/validMobilePhone"
import { validSiret } from "../../Utils/Regex/validSiret"
import { validPostalCode } from "../../Utils/Regex/validPostalCode"
import ToolTip from "../../Components/ToolTip"

const apiUrl = process.env.REACT_APP_API_URL

function AddSitesStep1() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [isElec, setIsElec] = useState(searchParams.get('type') === "elec")
    const [isGaz, setIsGaz] = useState(searchParams.get('type') === "gaz")
    const [fromAppelOffre] = useState(searchParams.get('from') === "appels-offre")
    const [isClient, setIsClient] = useState(false)
    const isAdd = window.location.pathname.includes('add')
    const location = useLocation()

    const [dateRef, setDateRef] = useState("")
    const [siteName, setSiteName] = useState("")
    const [siret, setSiret] = useState("")
    const [company, setCompany] = useState("")
    const [status, setStatus] = useState("")
    const [tva, setTVA] = useState("")
    const [invoiceAddress, setInvoiceAddress] = useState("")
    const [invoicePostalCode, setInvoicePostalCode] = useState("")
    const [municipality, setMunicipality] = useState("")

    const [address, setAddress] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [city, setCity] = useState("")

    const [lastname, setLastName] = useState("")
    const [firstname, setFirstName] = useState("")
    const [email, setEmail] = useState("")
    const [emailConfirmation, setEmailConfirmation] = useState("")
    const [fonction, setFonction] = useState("")
    const [landline, setLandline] = useState(0)
    const [mobile, setMobile] = useState(0)

    const [siteOptions, setSiteOptions] = useState([])
    const [siteAccountLabel, setSiteAccountLabel] = useState("")
    const [siteAccountID, setSiteAccountID] = useState("")
    const [siteType, setSiteType] = useState("")
    const [fromReengagement, setFromReengagement] = useState(false)

    const [isConfirmed, setIsConfirmed] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("Une erreur est survenue, veuillez réessayer plus tard")
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    const [showErrorMessageFirstName, setShowErrorMessageFirstName] = useState(false)
    const [showErrorMessageLastName, setShowErrorMessageLastName] = useState(false)
    const [showErrorMessageCompany, setShowErrorMessageCompany] = useState(false)
    const [showErrorMessagePostalCode, setShowErrorMessagePostalCode] = useState(false)
    const [showErrorMessageCity, setShowErrorMessageCity] = useState(false)
    const [showErrorMessageAddress, setShowErrorMessageAddress] = useState(false)
    const [showErrorMessageSiret, setShowErrorMessageSiret] = useState(false)
    const [showErrorMessageFonction, setShowErrorMessageFonction] = useState(false)
    const [showErrorMessageSiteName, setShowErrorMessageSiteName] = useState(false)
    const [showErrorMessageEmail, setShowErrorMessageEmail] = useState(false)
    const [showErrorMessageEmailConfirmation, setShowErrorMessageEmailConfirmation] = useState(false)
    const [showErrorMessageMobile, setShowErrorMessageMobile] = useState(false)
    const [showErrorMessageLandline, setShowErrorMessageLandline] = useState(false)
    const [showErrorMessageInvoicePostalCode, setShowErrorMessageInvoicePostalCode] = useState(false)

    const handleSubmitStep1 = () => {
        if (isConfirmed) {
            navigate(`/${isAdd ? "add" : "modify"}-sites-2?type=${isElec ? "elec" : "gaz"}&id=${searchParams.get('id')}${fromAppelOffre ? "&from=appels-offre" : ""}`)
        }
        else if ((!firstname || firstname === "") || (!lastname || lastname === "") || (!company || company === "") || (!address || address === "") || (!city || city === "")
            || (!fonction || fonction === "") || (!siteName || siteName === "") || (!validEmail.test(email) || !email || email === "") || (!emailConfirmation || emailConfirmation === "")
            || (email !== emailConfirmation) || (!validMobilePhone.test(mobile) || (!mobile || mobile === "")) || (landline && !validPhone.test(landline) && landline !== "") || ((!validSiret.test(siret)) || !siret || siret === "")
            || ((invoicePostalCode !== "" && !validPostalCode.test(invoicePostalCode))) || (!validPostalCode.test(postalCode) || !postalCode || postalCode === "")) {
            resetErrorMessages()
            if (!firstname || firstname === "") {
                setShowErrorMessageFirstName(true)
            }
            if (!lastname || lastname === "") {
                setShowErrorMessageLastName(true)
            }
            if (!company || company === "") {
                setShowErrorMessageCompany(true)
            }
            if (!address || address === "") {
                setShowErrorMessageAddress(true)
            }
            if (!city || city === "") {
                setShowErrorMessageCity(true)
            }
            if (!fonction || fonction === "") {
                setShowErrorMessageFonction(true)
            }
            if (!siteName || siteName === "") {
                setShowErrorMessageSiteName(true)
            }
            if (!validEmail.test(email) || !email || email === "") {
                setShowErrorMessageEmail(true)
            }
            if (!emailConfirmation || emailConfirmation === "") {
                setShowErrorMessageEmailConfirmation(true)
            }
            if (email !== emailConfirmation) {
                setShowErrorMessageEmailConfirmation(true)
            }
            if (!validMobilePhone.test(mobile) || (!mobile || mobile === "")) {
                setShowErrorMessageMobile(true)
            }
            if (landline && !validPhone.test(landline) && landline !== "") {
                setShowErrorMessageLandline(true)
            }
            if ((!validSiret.test(siret)) || !siret || siret === "") {
                setShowErrorMessageSiret(true)
            }
            if ((invoicePostalCode !== "" && !validPostalCode.test(invoicePostalCode))) {
                setShowErrorMessageInvoicePostalCode(true)
            }
            if (!validPostalCode.test(postalCode) || !postalCode || postalCode === "") {
                setShowErrorMessagePostalCode(true)
            }
        }
        else {
            setErrorMessage("Une erreur est survenue, veuillez réessayer plus tard")
            setIsLoading(true)
            const formData = JSON.stringify({
                "nom": `${siteName}`,
                "siret": `${siret}`,
                "raisonSociale": `${company}`,
                "formeJuridique": `${status}`,
                "numTVA": `${tva}`,
                "adresseFacturation": {
                    "adresse": `${invoiceAddress}`,
                    "codePostal": `${invoicePostalCode}`,
                    "ville": `${municipality}`,
                },
                "adresseConsommation": {
                    "adresse": `${address}`,
                    "codePostal": `${postalCode}`,
                    "ville": `${city}`,
                },
                "contact": {
                    "nom": `${lastname}`,
                    "prenom": `${firstname}`,
                    "email": `${email}`,
                    "fonction": `${fonction}`,
                    "telephoneFixe": `${landline ? landline : ""}`,
                    "telephoneMobile": `${mobile ? mobile : ""}`,
                },
            })
            axios.post(`${apiUrl}/${isElec ? "Elec" : "Gaz"}/Site/${searchParams.get('id') && searchParams.get('id').length > 0 ? `${searchParams.get('id')}/` : ""}1`,
                formData,
                {
                    headers: {
                        'Accept': 'text/plain',
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                })
                .then((response) => {
                    navigate(`/${isAdd ? "add" : "modify"}-sites-${(fromReengagement) ? "3" : "2"}?type=${isElec ? "elec" : "gaz"}&id=${searchParams.get('id') && searchParams.get('id').length > 0 ? searchParams.get('id') : response.data}${fromAppelOffre ? "&from=appels-offre" : ""}${fromReengagement ? "&reengagement" : ""}`)

                })
                .catch(error => {
                    if (error?.response?.data?.message) {
                        setErrorMessage(error.response.data.message)
                    }
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                    setShowErrorMessage(true)
                })
                .finally(() => setIsLoading(false))
        }
    }

    const resetErrorMessages = () => {
        setShowErrorMessage(false)
        setShowErrorMessageFirstName(false)
        setShowErrorMessageLastName(false)
        setShowErrorMessageCompany(false)
        setShowErrorMessagePostalCode(false)
        setShowErrorMessageAddress(false)
        setShowErrorMessageSiret(false)
        setShowErrorMessageFonction(false)
        setShowErrorMessageSiteName(false)
        setShowErrorMessageEmail(false)
        setShowErrorMessageEmailConfirmation(false)
        setShowErrorMessageMobile(false)
        setShowErrorMessageLandline(false)
        setShowErrorMessageInvoicePostalCode(false)
        setShowErrorMessageCity(false)
    }

    const getSiteData = useCallback(() => {
        axios.get(`${apiUrl}/${isElec ? "Elec" : "Gaz"}/Site/${searchParams.get('id').length > 0 ? `${searchParams.get('id')}/` : ""}1`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                response.data?.appelOffre && setDateRef(response.data.appelOffre)
                response.data?.nom && setSiteName(response.data.nom)
                response.data?.siret && setSiret(response.data.siret)
                response.data?.raisonSociale && setCompany(response.data.raisonSociale)
                response.data?.formeJuridique && setStatus(response.data.formeJuridique)
                response.data?.numTVA && setTVA(response.data.numTVA)
                response.data?.fromReengagement && setFromReengagement(response.data?.fromReengagement)

                response.data?.adresseFacturation?.adresse && setInvoiceAddress(response.data.adresseFacturation.adresse)
                response.data?.adresseFacturation?.codePostal && setInvoicePostalCode(response.data.adresseFacturation.codePostal)
                response.data?.adresseFacturation?.ville && setMunicipality(response.data.adresseFacturation.ville)

                response.data?.adresseConsommation?.adresse && setAddress(response.data.adresseConsommation.adresse)
                response.data?.adresseConsommation?.codePostal && setPostalCode(response.data.adresseConsommation.codePostal)
                response.data?.adresseConsommation?.ville && setCity(response.data.adresseConsommation.ville)

                response.data?.contact?.nom && setLastName(response.data.contact.nom)
                response.data?.contact?.prenom && setFirstName(response.data.contact.prenom)
                response.data?.contact?.email && setEmail(response.data.contact.email)
                response.data?.contact?.email && setEmailConfirmation(response.data.contact.email)
                response.data?.contact?.fonction && setFonction(response.data.contact.fonction)
                response.data?.contact?.telephoneFixe && setLandline(response.data.contact.telephoneFixe)
                response.data?.contact?.telephoneMobile && setMobile(response.data.contact.telephoneMobile)

                response.data?.isConfirmed && setIsConfirmed(response.data.isConfirmed)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [searchParams, isElec])

    const getSiteOptions = useCallback(() => {
        axios.get(`${apiUrl}/Elec/Site/1/Import`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            })
            .then((response) => {
                let siteOptions = []
                response.data.map((element) => siteOptions.push({ label: `${element.libelle}${element.type ? ` (${element.type})` : ""}`, value: element.siteID, type: element.type }))
                setSiteOptions(siteOptions)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [])

    const getDateRef = useCallback(() => {
        axios.get(`${apiUrl}/${isElec ? "Elec" : "Gaz"}/AppelOffre/Ongoing`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            })
            .then((response) => {
                setDateRef(response.data.libelle)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [isElec])

    const getClientInfobySiteID = () => {
        if (isClient) {
            getClient()
        }
        else if (!isClient) {
            getCollaborator()
        }
    }

    const checkIsClient = useCallback(() => {
        axios.get(`${apiUrl}/auth/info`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                response.data && setIsClient(response.data.isClient)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [])

    const resetAllData = useCallback(() => {
        setIsElec(window.location.href.includes("elec"))
        setIsGaz(window.location.href.includes("gaz"))
        setIsClient(false)
        setDateRef("")
        setSiteName("")
        setSiret("")
        setCompany("")
        setStatus("")
        setTVA("")
        setInvoiceAddress("")
        setInvoicePostalCode("")
        setMunicipality("")
        setAddress("")
        setPostalCode("")
        setCity("")
        setLastName("")
        setFirstName("")
        setEmail("")
        setEmailConfirmation("")
        setFonction("")
        setLandline(0)
        setMobile(0)
        setSiteOptions([])
        setSiteAccountLabel("")
        setSiteAccountID("")
        setSiteType("")
        setIsConfirmed(false)
        setErrorMessage("Une erreur est survenue, veuillez réessayer plus tard")
        resetErrorMessages()
    }, [])

    useEffect(() => {
        resetAllData()
    }, [resetAllData, location])

    useEffect(() => {
        checkIsClient()
        getSiteOptions()

        if (searchParams.get('id') && searchParams.get('id').length > 0) getSiteData()
        else getDateRef()

        if (isElec) {
            setSiteAccountID("")
            setSiteAccountLabel("")
        }

    }, [getSiteOptions, getSiteData, getDateRef, checkIsClient, resetAllData, searchParams, isElec, location])

    const getClient = async () => {
        axios.get(`${apiUrl}/Client`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                if (response && response.data) {
                    response.data.email ? setEmail(response.data.email) : setEmail("")
                    response.data.email ? setEmailConfirmation(response.data.email) : setEmailConfirmation("")
                    response.data.nom ? setLastName(response.data.nom) : setLastName("")
                    response.data.prenom ? setFirstName(response.data.prenom) : setFirstName("")
                    response.data.fonction ? setFonction(response.data.fonction) : setFonction("")
                    response.data.mobile ? setMobile(response.data.mobile) : setMobile("")
                    response.data.telephone ? setLandline(response.data.telephone) : setLandline("")
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const getCollaborator = async () => {
        axios.get(`${apiUrl}/Contact`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                if (response && response.data) {
                    response.data[0].email ? setEmail(response.data[0].email) : setEmail("")
                    response.data[0].email ? setEmailConfirmation(response.data[0].email) : setEmailConfirmation("")
                    response.data[0].nom ? setLastName(response.data[0].nom) : setLastName("")
                    response.data[0].prenom ? setFirstName(response.data[0].prenom) : setFirstName("")
                    response.data.fonction ? setFonction(response.data[0].fonction) : setFonction("")
                    response.data.mobile ? setMobile(response.data[0].mobile) : setMobile("")
                    response.data.telephone ? setLandline(response.data[0].telephone) : setLandline("")
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const resetInfo = () => {
        getDateRef()
        setSiteAccountID("")
        setSiteAccountLabel("")
        setSiteType("")
    }

    const getSiteDataBySiteId = () => {
        axios.get(`${apiUrl}/Elec/Site/1/Import/${siteType}/${siteAccountID}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                if (response && response.data) {
                    response.data.nom && setSiteName(response.data.nom)
                    response.data.siret && setSiret(response.data.siret)
                    response.data.raisonSociale && setCompany(response.data.raisonSociale)
                    response.data.formeJuridique && setStatus(response.data.formeJuridique)
                    response.data.numTVA && setTVA(response.data.numTVA)
                    if (response.data.adresseFacturation) {
                        response.data.adresseFacturation.adresse && setInvoiceAddress(response.data.adresseFacturation.adresse)
                        response.data.adresseFacturation.codePostal && setInvoicePostalCode(response.data.adresseFacturation.codePostal)
                        response.data.adresseFacturation.ville && setMunicipality(response.data.adresseFacturation.ville)
                    }
                    if (response.data.adresseConsommation) {
                        response.data.adresseConsommation.adresse && setAddress(response.data.adresseConsommation.adresse)
                        response.data.adresseConsommation.codePostal && setPostalCode(response.data.adresseConsommation.codePostal)
                        response.data.adresseConsommation.ville && setCity(response.data.adresseConsommation.ville)
                    }
                    if (response.data.contact) {
                        response.data.contact.nom && setLastName(response.data.contact.nom)
                        response.data.contact.prenom && setFirstName(response.data.contact.prenom)
                        response.data.contact.email && setEmail(response.data.contact.email)
                        response.data.contact.email && setEmailConfirmation(response.data.contact.email)
                        response.data.contact.fonction && setFonction(response.data.contact.fonction)
                        response.data.contact.telephoneFixe && setLandline(response.data.contact.telephoneFixe)
                        response.data.contact.telephoneMobile && setMobile(response.data.contact.telephoneMobile)
                    }
                }

            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    return (
        <>
            <SideBar />
            <div className="homepage--container privatepages--container">
                <div className="privatepages--header__container">
                    <h3 className="privatepages--title privatepages--text">{isAdd ? "Ajouter un nouveau site à votre compte" : isConfirmed ? "Visualiser le site" : "Modifier le site"}</h3>
                    <Link to={fromAppelOffre ? `/appels-offre-${isElec ? "elec" : "gaz"}` : `/${isElec ? "elec" : "gaz"}-sites`}><span className="privatepages--header__return">{`< Retour à la liste des sites ${isElec ? "Électricité" : "Gaz"}`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                <div className="addsites--choosetype__container">
                    <span className="addsites--choosetype__title">Choisissez votre type d'énergie</span>
                    <div className="addsites--choosetype__switchcontainer">
                        <span className={`addsites--choosetype__energy ${isGaz ? "addsites--choosetype__energyactive" : ""}`}>Gaz</span>
                        <Switch
                            sx={{
                                margin: '0 12px',
                                '--Switch-thumbSize': '19px',
                                '--Switch-trackWidth': '45px',
                                '--Switch-trackHeight': '21px',
                                '--Switch-trackBackground': '#EFEFEF',
                                '--Switch-thumbBackground': '#48AB4D',
                                '&:hover': {
                                    '--Switch-trackBackground': '#EFEFEF',
                                },
                                [`&.${switchClasses.checked}`]: {
                                    '--Switch-thumbBackground': '#48AB4D',
                                    '--Switch-trackBackground': '#EFEFEF',
                                    '&:hover': {
                                        '--Switch-trackBackground': '#EFEFEF',
                                    },
                                },
                            }}
                            checked={isElec}
                            disabled={searchParams.get('id')?.length > 0}
                            onChange={() => {
                                if (isElec) {
                                    setIsElec(false)
                                    setIsGaz(true)
                                    resetInfo()
                                }
                                else {
                                    setIsElec(true)
                                    setIsGaz(false)
                                    resetInfo()
                                }
                            }} />
                        <span className={`addsites--choosetype__energy ${isElec ? "addsites--choosetype__energyactive" : ""}`}>Électricité</span>
                    </div>
                </div>
                <div className="privatepages--separator"></div>
                <div className="addsites--step__titlecontainer">
                    <h4 className="addsites--step__title">Étape 1/{fromReengagement ? "2" : "3"} : <span className="addsites--step__titleright">{fromReengagement ? "Confirmation du site" : "Création de votre site"}</span></h4>
                    <Button
                        cta={(fromReengagement && !isConfirmed) ? "Participer au prochain appel d'offres" : "Étape suivante"}
                        additionnalClassName="addsites--step__button"
                        handleSubmit={() => handleSubmitStep1()}
                        isLoading={isLoading} />
                </div>
                {showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror sepa--messageinfo">{errorMessage}</span>}
                {isConfirmed && <span className="homepage--popup__message homepage--popup__messageconfirmation sepa--messageinfo">Votre site est confirmé. Si vous souhaitez le modifier, merci de contacter l'équipe WattValue.</span>}
                <div className="privatepages--columnscontainer">
                    <div className="privatepages--twocolumns">
                        <div>
                            <span className="privatepages--asterisk privatepages--text">*champ obligatoire</span>
                            <form className="privatepages--form__block  sepa--form__block">
                                <h4 className="privatepages--form__title">Habilitation du site</h4>
                                {(siteOptions.length > 0 && !isConfirmed) ?
                                    <div className="addsites--dropdown__eleccontainer">
                                        <FormInput
                                            type="dropdown"
                                            id="siteDropdown"
                                            onChangeInput={(e) => {
                                                setSiteAccountLabel(e.label)
                                                setSiteAccountID(e.value)
                                                setSiteType(e.type)
                                            }}
                                            placeholder="Choisir..."
                                            value={siteAccountID}
                                            label={siteAccountLabel}
                                            isSearchable
                                            options={siteOptions}
                                            divClassName="addsites--dropdown__elecdropdown"
                                        />
                                        <span className={`sepa--form__import ${!siteAccountID ? "sepa--form__importdisabled" : ""}`} onClick={siteAccountID ? () => getSiteDataBySiteId() : null}>Importer le site</span>
                                    </div>
                                    : ""
                                }
                                <FormInput
                                    label="Date de référence"
                                    type="text"
                                    id="date-ref"
                                    disabled
                                    value={dateRef}
                                    maxLength={255}
                                />
                                <ToolTip
                                    text={"Un nom qui vous permet d'identifier le site de consommation."}
                                    position="right">
                                    <FormInput
                                        type="text"
                                        label="Nom du site*"
                                        id="siteName"
                                        onChangeInput={value => {
                                            setShowErrorMessage(false)
                                            setShowErrorMessageSiteName(false)
                                            setSiteName(value)
                                        }}
                                        placeholder="Site 1"
                                        value={siteName}
                                        disabled={isConfirmed}
                                    />
                                </ToolTip>
                                {showErrorMessageSiteName && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner le nom du site</span>}
                                <ToolTip
                                    text={"Identifiant numérique de l'établissement à 14 chiffres."}
                                    position="right">
                                    <FormInput
                                        type="text"
                                        label="SIRET*"
                                        id="siret"
                                        onChangeInput={value => {
                                            setShowErrorMessage(false)
                                            setShowErrorMessageSiret(false)
                                            setSiret(value)
                                        }}
                                        placeholder="120027016"
                                        value={siret}
                                        maxLength={255}
                                        disabled={isConfirmed}
                                    />
                                </ToolTip>
                                {showErrorMessageSiret && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner un SIRET valide</span>}
                                <ToolTip
                                    text={"Entité morale dotée de la personnalité juridique."}
                                    position="right">
                                    <FormInput
                                        type="text"
                                        label="Société*"
                                        id="company"
                                        onChangeInput={value => {
                                            setShowErrorMessage(false)
                                            setShowErrorMessageCompany(false)
                                            setCompany(value)
                                        }}
                                        placeholder="WattValue"
                                        value={company}
                                        maxLength={255}
                                        disabled={isConfirmed}
                                    />
                                </ToolTip>
                                {showErrorMessageCompany && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner la société</span>}
                                <FormInput
                                    type="text"
                                    label="Forme juridique"
                                    id="forme_juridique"
                                    onChangeInput={value => {
                                        setShowErrorMessage(false)
                                        setStatus(value)
                                    }}
                                    placeholder="SARL"
                                    value={status}
                                    maxLength={255}
                                    disabled={isConfirmed}
                                />
                                <FormInput
                                    type="text"
                                    label="N° TVA INTRA"
                                    id="tva"
                                    onChangeInput={value => {
                                        setShowErrorMessage(false)
                                        setTVA(value)
                                    }}
                                    placeholder="FR32 123 456 789"
                                    value={tva}
                                    maxLength={255}
                                    disabled={isConfirmed}
                                />
                                <FormInput
                                    type="text"
                                    label="Adresse de facturation"
                                    id="invoiceAddress"
                                    onChangeInput={value => {
                                        setShowErrorMessage(false)
                                        setInvoiceAddress(value)
                                    }}
                                    placeholder="2 rue du village"
                                    value={invoiceAddress}
                                    maxLength={255}
                                    disabled={isConfirmed}
                                />
                                <FormInput
                                    type="text"
                                    label="Code postal de facturation"
                                    id="invoicePostalCode"
                                    onChangeInput={value => {
                                        setShowErrorMessage(false)
                                        setShowErrorMessageInvoicePostalCode(false)
                                        setInvoicePostalCode(value)
                                    }}
                                    placeholder="31000"
                                    value={invoicePostalCode}
                                    maxLength={255}
                                    disabled={isConfirmed}
                                />
                                {showErrorMessageInvoicePostalCode && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner un code postal valide</span>}
                                <FormInput
                                    type="text"
                                    label="Commune de facturation"
                                    id="municipality"
                                    onChangeInput={value => {
                                        setShowErrorMessage(false)
                                        setMunicipality(value)
                                    }}
                                    placeholder="Toulouse"
                                    value={municipality}
                                    maxLength={255}
                                    disabled={isConfirmed}
                                />
                            </form>
                        </div>
                    </div>
                    <div className="privatepages--twocolumns sepa--middlecolumn">
                        <form className="privatepages--form__block sepa--form__block">
                            <h4 className="privatepages--form__title">Lieu de consommation</h4>
                            <FormInput
                                type="text"
                                label="Adresse*"
                                id="address"
                                onChangeInput={value => {
                                    setShowErrorMessage(false)
                                    setShowErrorMessageAddress(false)
                                    setAddress(value)
                                }}
                                placeholder="2 rue du village"
                                value={address}
                                maxLength={255}
                                disabled={isConfirmed}
                            />
                            {showErrorMessageAddress && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner l'adresse</span>}
                            <FormInput
                                type="text"
                                label="Code postal*"
                                id="postalCode"
                                onChangeInput={value => {
                                    setShowErrorMessage(false)
                                    setShowErrorMessagePostalCode(false)
                                    setPostalCode(value)
                                }}
                                placeholder="31000"
                                value={postalCode}
                                maxLength={255}
                                disabled={isConfirmed}
                            />
                            {showErrorMessagePostalCode && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner un code postal valide</span>}
                            <FormInput
                                type="text"
                                label="Ville*"
                                id="city"
                                onChangeInput={value => {
                                    setShowErrorMessage(false)
                                    setShowErrorMessageCity(false)
                                    setCity(value)
                                }}
                                placeholder="Toulouse"
                                value={city}
                                maxLength={255}
                                disabled={isConfirmed}
                            />
                            {showErrorMessageCity && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner la ville</span>}
                        </form>
                    </div>
                    <div className="privatepages--twocolumns sepa--thirdcolumn">
                        <form className="privatepages--form__block  sepa--form__block">
                            <h4 className="privatepages--form__title">Personne habilitée à engager le site</h4>
                            {!isConfirmed && <span className="sepa--form__import" onClick={() => getClientInfobySiteID()}>Importer les données du compte</span>}
                            <FormInput
                                label="Nom*"
                                type="text"
                                id="lastname"
                                hmlFor="lastname"
                                onChangeInput={value => {
                                    setShowErrorMessage(false)
                                    setShowErrorMessageLastName(false)
                                    setLastName(value)
                                }}
                                placeholder="Emilie"
                                value={lastname}
                                maxLength={255}
                                disabled={isConfirmed}
                            />
                            {showErrorMessageLastName && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner le nom</span>}
                            <FormInput
                                label="Prénom*"
                                type="text"
                                id="firstname"
                                onChangeInput={value => {
                                    setShowErrorMessage(false)
                                    setShowErrorMessageFirstName(false)
                                    setFirstName(value)
                                }}
                                placeholder="Sardou"
                                value={firstname}
                                maxLength={255}
                                disabled={isConfirmed}
                            />
                            {showErrorMessageFirstName && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner le prénom</span>}
                            <ToolTip
                                text={"Attention, la validité de votre adresse email est déterminante pour la suite des opérations d'enregistrement !"}
                                marginTop={16}
                                position="left">
                                <FormInput
                                    label="Email*"
                                    type="email"
                                    id="email"
                                    onChangeInput={value => {
                                        setShowErrorMessage(false)
                                        setShowErrorMessageEmail(false)
                                        setEmail(value)
                                    }}
                                    placeholder="emilie.sardou@gmail.com"
                                    value={email}
                                    maxLength={255}
                                    disabled={isConfirmed}
                                />
                            </ToolTip>
                            {showErrorMessageEmail && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner une adresse email valide</span>}
                            <FormInput
                                label="Confirmation de l'email*"
                                type="email"
                                id="emailConfirmation"
                                onChangeInput={value => {
                                    setShowErrorMessage(false)
                                    setShowErrorMessageEmailConfirmation(false)
                                    setEmailConfirmation(value)
                                }}
                                placeholder="emilie.sardou@gmail.com"
                                value={emailConfirmation}
                                maxLength={255}
                                disabled={isConfirmed}
                            />
                            {showErrorMessageEmailConfirmation && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner la confirmation d'adresse email</span>}
                            <FormInput
                                label="Fonction*"
                                type="text"
                                id="fonction"
                                onChangeInput={value => {
                                    setShowErrorMessage(false)
                                    setShowErrorMessageFonction(false)
                                    setFonction(value)
                                }}
                                placeholder="Chef de projet"
                                value={fonction}
                                disabled={isConfirmed}
                            />
                            {showErrorMessageFonction && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner la fonction</span>}
                            <FormInput
                                label="Téléphone fixe"
                                type="text"
                                pattern="[0-9]*"
                                id="fixe"
                                onChangeInput={value => {
                                    setShowErrorMessage(false)
                                    setShowErrorMessageLandline(false)
                                    setLandline(value)
                                }}
                                placeholder="02 35 12 65 98"
                                value={landline}
                                maxLength={50}
                                disabled={isConfirmed}
                            />
                            {showErrorMessageLandline && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner un numéro de téléphone valide à 10 chiffres</span>}
                            <ToolTip
                                text={"Votre numéro de mobile est requis pour finaliser le contrat. Il sera utilisé pour vous envoyer un code par sms. Format : 0606060606."}
                                marginTop={10}
                                position="left">
                                <FormInput
                                    label="Téléphone mobile*"
                                    type="text"
                                    pattern="[0-9]*"
                                    id="mobile"
                                    onChangeInput={value => {
                                        setShowErrorMessage(false)
                                        setShowErrorMessageMobile(false)
                                        setMobile(value)
                                    }}
                                    placeholder="06 01 02 03 04"
                                    value={mobile}
                                    maxLength={50}
                                    disabled={isConfirmed}
                                />
                            </ToolTip>
                            {showErrorMessageMobile && <span className="homepage--popup__message homepage--popup__messageerror">Veuillez renseigner un numéro de téléphone portable valide à 10 chiffres</span>}
                        </form>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AddSitesStep1;