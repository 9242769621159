import { useState, useEffect, useCallback } from "react"
import { useSearchParams, Link } from 'react-router-dom'

import SideBar from "../../../Components/SideBar/SideBar"
import Info from '../../../img/info.png'
import axios from "axios"
import TurpeDetailTable from "./TurpeDetailTable"
import Button from "../../../Components/Button"
import CircleIcon from '@mui/icons-material/Circle'

const apiUrl = process.env.REACT_APP_API_URL

function TurpeDetail() {
    const [searchParams] = useSearchParams()
    const prm = searchParams.get('prm')

    const [isDataLoading, setIsDataLoading] = useState(false)

    const [libelle, setLibelle] = useState("")
    const [dateDebutTurpe, setDateDebutTurpe] = useState("")
    const [dateDebut, setDateDebut] = useState("")
    const [dateFin, setDateFin] = useState("")
    const [currentPower, setCurrentPower] = useState([])
    const [optimisedPower, setOptimisedPower] = useState([])
    const [coutOptimise, setCoutOptimise] = useState({})
    const [coutActuel, setCoutActuel] = useState({})
    const [depassement, setDepassement] = useState(0)
    const [depassementHeures, setDepassementHeures] = useState(0)
    const [totalEco, setTotalEco] = useState(0)
    const [coutEnedis, setCoutEnedis] = useState(0)
    const [fta, setFta] = useState("")
    const [emailFournisseur, setEmailFournisseur] = useState("")

    const [showDetailsActuel, setShowDetailsActuel] = useState(false)
    const [showDetailsOptimise, setShowDetailsOptimise] = useState(false)

    const [isTravaux, setIsTravaux] = useState(false)
    const [isTravauxOriginal, setIsTravauxOriginal] = useState(false)
    const [showRecommandationTravaux, setShowRecommandationTravaux] = useState(false)
    const [hasValidatedRecommandationTravaux, setHasValidatedRecommandationTravaux] = useState(false)

    const [showErrorMessageIsTravaux, setShowErrorMessageIsTravaux] = useState(false)
    const [showErrorMessageReco, setShowErrorMessageReco] = useState(false)
    const [errorMessageIsTravaux, setErrorMessageIsTravaux] = useState("Une erreur est survenue, veuillez réessayer plus tard")
    const [errorMessageReco, setErrorMessageReco] = useState("Une erreur est survenue, veuillez réessayer plus tard")

    const CircleSx = { fontSize: "9px", marginRight: "5px", color: "#48AB4D" }

    const getSiteData = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/Elec/Turpe/Optimisation/${prm}`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                response.data?.dateDebutTurpe && setDateDebutTurpe(response.data.dateDebutTurpe)
                response.data?.dateDebut && setDateDebut(response.data.dateDebut)
                response.data?.dateFin && setDateFin(response.data.dateFin)
                response.data?.libelle && setLibelle(response.data.libelle)

                response.data?.coutOptimise && setCoutOptimise(response.data.coutOptimise)
                response.data?.coutActuel && setCoutActuel(response.data.coutActuel)
                response.data?.depassement && setDepassement(response.data.depassement)
                response.data?.depassementHeures && setDepassementHeures(response.data.depassementHeures)
                response.data?.totalEco && setTotalEco(response.data.totalEco)
                response.data?.coutEnedis && setCoutEnedis(response.data.coutEnedis)
                setIsTravaux(response.data.isTravaux)
                setIsTravauxOriginal(response.data.isTravaux)
                response.data?.fta && setFta(response.data.fta)
                response.data?.hasValidatedRecommandationTravaux && setHasValidatedRecommandationTravaux(response.data.hasValidatedRecommandationTravaux)
                setShowRecommandationTravaux(response.data.showRecommandationTravaux)

                response.data?.fournisseurContactInfo?.email && setEmailFournisseur(response.data.fournisseurContactInfo.email)

                let puissancesArray = []
                if (response.data?.puissancesSouscritesActuels && Object.keys(response.data?.puissancesSouscritesActuels)?.length > 0) {
                    let puissancesSouscritesActuelsCopy = { ...response.data.puissancesSouscritesActuels }
                    puissancesSouscritesActuelsCopy.title = "Puissances souscrites (en KVA)"
                    puissancesSouscritesActuelsCopy.type = "souscrite"
                    puissancesArray.push(puissancesSouscritesActuelsCopy)
                }
                if (response.data?.puissancesSouscritesAtteintes && Object.keys(response.data?.puissancesSouscritesAtteintes)?.length > 0) {
                    let puissancesSouscritesAtteintesCopy = { ...response.data.puissancesSouscritesAtteintes }
                    puissancesSouscritesAtteintesCopy.title = "Puissances atteintes (en KVA)"
                    puissancesSouscritesAtteintesCopy.type = "souscrite"
                    puissancesArray.push(puissancesSouscritesAtteintesCopy)
                }
                setCurrentPower(puissancesArray)

                if (response.data?.puissancesOptimisesActuels && Object.keys(response.data?.puissancesOptimisesActuels)?.length > 0) {
                    let puissancesOptimisesActuelsCopy = { ...response.data.puissancesOptimisesActuels }
                    puissancesOptimisesActuelsCopy.title = "Puissances souscrites (en KVA)"
                    puissancesOptimisesActuelsCopy.type = "optimisee"
                    setOptimisedPower([puissancesOptimisesActuelsCopy])
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [prm])

    useEffect(() => {
        getSiteData()
    }, [getSiteData])

    const validateTravaux = () => {
        setShowErrorMessageIsTravaux(false)
        axios.post(`${apiUrl}/Elec/Turpe/Optimisation/${prm}/Travaux?state=${isTravaux}`,
            '',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(() => {
                getSiteData()
            })
            .catch(error => {
                if (error.response?.data?.message) setErrorMessageIsTravaux(error.response.data.message)
                setShowErrorMessageIsTravaux(true)
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const validateReco = () => {
        setShowErrorMessageReco(false)
        axios.post(`${apiUrl}/Elec/Turpe/Optimisation/${prm}/Travaux/Recommandation`,
            '',
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(() => {
                getSiteData()
            })
            .catch(error => {
                if (error.response?.data?.message) setErrorMessageReco(error.response.data.message)
                setShowErrorMessageReco(true)
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const displayReco = !(optimisedPower[0]?.pointe === currentPower[0]?.pointe && optimisedPower[0]?.hph === currentPower[0]?.hph && optimisedPower[0]?.hch === currentPower[0]?.hch && optimisedPower[0]?.hpe === currentPower[0]?.hpe && optimisedPower[0]?.hce === currentPower[0]?.hce)

    return (
        <>
            <SideBar
                openElec
                active="TURPE"
            />
            <div className="homepage--container privatepages--container">
                <div className="privatepages--header__container">
                    <h3 className="privatepages--title privatepages--text">Optimisation TURPE {libelle ? `- ${libelle} basée sur le TURPE en vigueur au ${new Date(dateDebutTurpe).toLocaleDateString()}` : ""}</h3>
                    <Link to='/optimisation-turpe'><span className="privatepages--header__return">{`< Retour à la liste des sites`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                {/* Bloc servant à afficher du texte qui peut être renseigné dans le Back Office par WattValue (champ à ajouter dans la base de donnée) */}
                {/* <div className="elec--informationblock">
                    <div>
                        <div className="elec--informationblock__title">
                            <img src={Info} alt="Icone information" className="elec--informationblock__img" />Informations
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                    </div>
                </div> */}
                {isDataLoading ?
                    <span className="global--loader"></span>
                    :
                    <>
                        {(dateDebut && dateFin) && <span className="turpe--subtitle">Calculs et recommandation basés sur 12 mois de consommations relevées par ENEDIS entre {new Date(dateDebut).toLocaleDateString()} et le {new Date(dateFin).toLocaleDateString()}</span>}
                        <div className="turpe--columns__container">
                            <div className="turpe--column__left">
                                <span className="turpe--column__title">Situation actuelle</span>
                                <div className="turpe--block turpe--block__left">
                                    {currentPower.length > 0 &&
                                        <TurpeDetailTable
                                            rows={currentPower}
                                        />
                                    }
                                    <span className="turpe--block__cost">Coût d'acheminement avec vos paramètres de puissance optimisés du {new Date(dateDebut).toLocaleDateString()} au {new Date(dateFin).toLocaleDateString()} :</span>
                                    {coutActuel?.totalEur && <ul>
                                        <li className="turpe--block__cost turpe--block__listitem">
                                            Coût total annuel (en € HT) :
                                            <span className="turpe--block__listitembold">
                                                {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutActuel.totalEur)} soit {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutActuel.totalMWh)} HT/MWh
                                            </span>
                                        </li>
                                        {showDetailsActuel &&
                                            <>
                                                <li className="turpe--block__cost turpe--block__listitem">
                                                    Composante de gestion :
                                                    <span className="turpe--block__listitembold">
                                                        {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutActuel?.composanteGestion)} HT
                                                    </span>
                                                </li>
                                                <li className="turpe--block__cost turpe--block__listitem">
                                                    Composante de comptage :
                                                    <span className="turpe--block__listitembold">
                                                        {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutActuel?.composanteComptage)} HT
                                                    </span>
                                                </li>
                                                <li className="turpe--block__cost turpe--block__listitem">
                                                    Composante de soutirage fixe :
                                                    <span className="turpe--block__listitembold">
                                                        {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutActuel?.composanteSoutirageFixe)} HT
                                                    </span>
                                                </li>
                                                <li className="turpe--block__cost turpe--block__listitem">
                                                    Composante de soutirage variable :
                                                    <span className="turpe--block__listitembold">
                                                        {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutActuel?.composanteSoutirageVariable)} HT
                                                    </span>
                                                </li>
                                                <li className="turpe--block__cost turpe--block__listitem">
                                                    CTA :
                                                    <span className="turpe--block__listitembold">
                                                        {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutActuel?.cta)} HT
                                                    </span>
                                                </li>
                                                <li className="turpe--block__cost turpe--block__listitem">
                                                    Dépassements :
                                                    <span className="turpe--block__listitembold">
                                                        {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(depassement)} HT soit {depassementHeures} heures
                                                    </span>
                                                </li>
                                            </>
                                        }
                                    </ul>}
                                    <span className="turpe--more" onClick={() => setShowDetailsActuel(!showDetailsActuel)}>{showDetailsActuel ? "Moins de détail -" : "Plus de détail +"}</span>
                                </div>
                            </div>
                            <div className="turpe--column__left">
                                <span className="turpe--column__title">Proposition d'optimisation</span>
                                <div className="turpe--block turpe--block__left">
                                    {optimisedPower.length > 0 &&
                                        <TurpeDetailTable
                                            rows={optimisedPower}
                                        />
                                    }
                                    <span className="turpe--block__cost turpe--block__costmargintop">Coût d'acheminement avec vos paramètres de puissance optimisés du {new Date(dateDebut).toLocaleDateString()} au {new Date(dateFin).toLocaleDateString()} :</span>
                                    {coutOptimise?.totalEur && <ul>
                                        <li className="turpe--block__cost turpe--block__listitem">
                                            Coût total annuel (en € HT) :
                                            <span className="turpe--block__listitembold">
                                                {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutOptimise.totalEur)} {coutOptimise?.totalMWh ? `soit ${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutOptimise.totalMWh)} HT/MWh` : ""}
                                            </span>
                                        </li>
                                        {showDetailsOptimise &&
                                            <>
                                                <li className="turpe--block__cost turpe--block__listitem">
                                                    Composante de gestion :
                                                    <span className="turpe--block__listitembold">
                                                        {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutOptimise?.composanteGestion)} HT
                                                    </span>
                                                </li>
                                                <li className="turpe--block__cost turpe--block__listitem">
                                                    Composante de comptage :
                                                    <span className="turpe--block__listitembold">
                                                        {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutOptimise?.composanteComptage)} HT
                                                    </span>
                                                </li>
                                                <li className="turpe--block__cost turpe--block__listitem">
                                                    Composante de soutirage fixe :
                                                    <span className="turpe--block__listitembold">
                                                        {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutOptimise?.composanteSoutirageFixe)} HT
                                                    </span>
                                                </li>
                                                <li className="turpe--block__cost turpe--block__listitem">
                                                    Composante de soutirage variable :
                                                    <span className="turpe--block__listitembold">
                                                        {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutOptimise?.composanteSoutirageVariable)} HT
                                                    </span>
                                                </li>
                                                <li className="turpe--block__cost turpe--block__listitem">
                                                    CTA :
                                                    <span className="turpe--block__listitembold">
                                                        {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutOptimise?.cta)} HT
                                                    </span>
                                                </li>
                                            </>
                                        }
                                    </ul>}
                                    <span className="turpe--more" onClick={() => setShowDetailsOptimise(!showDetailsOptimise)}>{showDetailsOptimise ? "Moins de détail -" : "Plus de détail +"}</span>
                                </div>
                            </div>
                            <div className="turpe--column__right">
                                <span className="turpe--column__title">Économie réalisable</span>
                                <div className="turpe--block turpe--block__right">
                                    <div>
                                        <span className="turpe--economy">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(totalEco)}*</span>
                                        <span className="turpe--economy__text">Le coût de l'intervention pour la modification de la puissance est  facturé {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(coutEnedis)} par le gestionnaire de réseau ENEDIS</span>
                                        <span className="turpe--economy__text turpe--economy__asterisque">*sur la période</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className="turpe--column__title">Avez-vous prévu au cours des 12 prochains mois des travaux ou changements ayant un impact important sur votre consommation et/ou votre besoin en puissance électrique (agrandissement, installation d'équipements supplémentaires, de climatisations ou chauffages électriques...) ?</span>
                        <div>
                            <label className={`homepage--popup__radioinputcontainer turpe--radioinput__container ${isTravauxOriginal !== null ? "turpe--radioinput__containerreadonly" : ""}`} htmlFor="oui">Oui
                                <input type="radio" id="oui" name="travaux" value="oui" checked={isTravaux} readOnly={isTravauxOriginal !== null} onChange={isTravauxOriginal === null ? () => setIsTravaux(!isTravaux) : null} />
                                <span className="homepage--popup__checkmark conso--filters__checkmark turpe--radioinput__checkmark"></span>
                            </label>
                            <label className={`homepage--popup__radioinputcontainer turpe--radioinput__container ${isTravauxOriginal !== null ? "turpe--radioinput__containerreadonly" : ""}`} htmlFor="non">Non
                                <input type="radio" name="travaux" id="non" value="non" checked={!isTravaux} readOnly={isTravauxOriginal !== null} onChange={isTravauxOriginal === null ? () => setIsTravaux(!isTravaux) : null} />
                                <span className="homepage--popup__checkmark conso--filters__checkmark turpe--radioinput__checkmark"></span>
                            </label>
                        </div>
                        {isTravauxOriginal === null ?
                            <>
                                <Button
                                    cta="Valider votre choix"
                                    handleSubmit={() => validateTravaux()}
                                />
                                {showErrorMessageIsTravaux && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessageIsTravaux}</span>}
                            </>
                            :
                            ""
                        }
                        {((isTravauxOriginal === false && displayReco) || (isTravauxOriginal && showRecommandationTravaux)) ?
                            <>
                                <p className="turpe--withouttravaux__paragraph">
                                    Pour mettre en place ce changement, si vous êtes d'accord avec notre recommandation, il vous suffit de communiquer le texte proposé ci-dessous à votre fournisseur actuel aux coordonnées suivantes :
                                </p>
                                <ul>
                                    <li className="turpe--withouttravaux__listitem"><CircleIcon sx={CircleSx} />{emailFournisseur}</li>
                                    <li className="turpe--withouttravaux__listitem"><CircleIcon sx={CircleSx} />Mettre en copie wattunity@wattvalue.fr</li>
                                    <li className="turpe--withouttravaux__listitem"><CircleIcon sx={CircleSx} />Texte à transmettre par email au fournisseur</li>
                                </ul>
                                <div className="turpe--withouttravaux__container">
                                    Bonjour,
                                    <br />
                                    <br />
                                    Suite à une étude de notre TURPE réalisée par WattValue pour le site {libelle}, RAE {prm}, nous souhaitons modifier nos paramètres TURPE.
                                    <br />
                                    <br />
                                    Les nouveaux paramètres à mettre en place dès que possible sont :<br /><br />
                                    - Puissances souscrites :<br />
                                    {(optimisedPower[0]?.pointe && optimisedPower[0].pointe !== currentPower[0]?.pointe) ? <>Pointe : {optimisedPower[0].pointe} kVA<br /></> : <>Pointe : Pas de changement<br /></>}
                                    {(optimisedPower[0]?.hph && optimisedPower[0].hph !== currentPower[0]?.hph) ? <>HPH : {optimisedPower[0].hph} kVA<br /></> : <>HPH : Pas de changement<br /></>}
                                    {(optimisedPower[0]?.hch && optimisedPower[0].hch !== currentPower[0]?.hch) ? <>HCH : {optimisedPower[0].hch} kVA<br /></> : <>HCH : Pas de changement<br /></>}
                                    {(optimisedPower[0]?.hpe && optimisedPower[0].hpe !== currentPower[0]?.hpe) ? <>HPE : {optimisedPower[0].hpe} kVA<br /></> : <>HPE : Pas de changement<br /></>}
                                    {(optimisedPower[0]?.hce && optimisedPower[0].hce !== currentPower[0]?.hce) ? <>HCE : {optimisedPower[0].hce} kVA<br /></> : <>HCE : Pas de changement<br /></>}
                                    <br />
                                    <br />
                                    - FTA : {fta}
                                    <br />
                                    <br />
                                    Merci de nous tenir informés de la date de mise en place effective de ces nouveaux paramètres par votre gestionnaire de réseau.
                                    <br />
                                    <br />
                                    Cordialement,
                                </div>
                                {(!hasValidatedRecommandationTravaux && showRecommandationTravaux) ?
                                    <>
                                        <Button
                                            cta="Valider la recommandation"
                                            handleSubmit={() => validateReco()}
                                            additionnalClassName="turpe--button__validate"
                                        />
                                        {showErrorMessageReco && <span className="homepage--popup__message homepage--popup__messageerror">{errorMessageReco}</span>}
                                    </>
                                    :
                                    ""
                                }
                            </>
                            :
                            <>
                                <span className="turpe--withtravaux">
                                    En l'état nous ne pouvons pas vous proposer d'optimisation de votre consommation.<br />
                                    Merci de prendre contact avec nous, en cliquant sur le bouton ci-dessous.</span>
                                <Button
                                    cta="Prendre contact avec nous"
                                    link='/new-message'
                                />
                            </>
                        }
                    </>
                }
            </div>
        </>
    )
}

export default TurpeDetail