import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useEffect } from "react"
import ReactGA from 'react-ga4'
import './sass/index.css'
import Login from "./Pages/Login/Login"
import PasswordStep2 from "./Pages/Login/PasswordStep2"
import Dashboard from "./Pages/Dashboard/Dashboard"
import PrivateRoute from "./Auth/PrivateRoute"
import PublicRoute from "./Auth/PublicRoute"
import Confirmation from "./Pages/Login/Confirmation"
import NotFound from "./Pages/NotFound"
import UpdateAccount from "./Pages/Account/UpdateAccount"
import Contributors from "./Pages/Account/Contributors/Contributors"
import AddContributors from "./Pages/Account/Contributors/AddContributors"
import MailBox from "./Pages/Account/MailBox/MailBox"
import NewMessage from "./Pages/Account/MailBox/NewMessage"
import Chat from "./Pages/Account/MailBox/Chat"
import Mandates from "./Pages/Account/Mandates/Mandates"
import WattvalueInvoices from "./Pages/Account/WattvalueInvoices/WattvalueInvoices"
import Sepa from "./Pages/Account/Sepa/Sepa"
import UpdateSepa from "./Pages/Account/Sepa/UpdateSepa"
import GreenEnergy from "./Pages/Elec/GreenEnergy/GreenEnergy"
import InvoiceVerification from "./Pages/Elec/InvoiceVerification/InvoiceVerification"
import UploadInvoice from "./Pages/Elec/InvoiceVerification/UploadInvoice"
import InvoiceVerificationDetail from "./Pages/Elec/InvoiceVerification/InvoiceVerificationDetail"
import InvoiceSiteUpload from "./Pages/Elec/InvoiceVerification/InvoiceSiteUpload"
import Sites from "./Pages/Sites/Sites"
import AddSitesStep1 from "./Pages/AddSites/AddSitesStep1"
import AddSitesStep2 from "./Pages/AddSites/AddSitesStep2"
import AddSitesStep3 from "./Pages/AddSites/AddSitesStep3"
import AddSitesStep4 from "./Pages/AddSites/AddSitesStep4"
import AppelsOffre from "./Pages/AppelsOffre/AppelsOffre"
import AppelsOffreResult from "./Pages/AppelsOffre/AppelsOffreResult"
import Contracts from "./Pages/Contracts/Contracts"
import Conso from "./Pages/Elec/Conso/Conso"
import ConsoDetail from "./Pages/Elec/Conso/ConsoDetail"
import Subscription from "./Pages/Elec/Subscription/Subscription"
import Turpe from "./Pages/Elec/Turpe/Turpe"
import TurpeDetail from "./Pages/Elec/Turpe/TurpeDetail"
import Resources from "./Pages/Resources/Resources"
import Budget from "./Pages/Elec/Budget/Budget"

const TRACKING_ID = "G-89SZ9XSX4E"
ReactGA.initialize(TRACKING_ID)

function App() {

  useEffect(() => {
    ReactGA.send("pageview")
  }, [])

  return (
    <BrowserRouter basename="/">
      <Routes>

        {/* Public */}
        <Route exact path="/" element={
          <PublicRoute>
            <Login />
          </PublicRoute>}></Route>
        <Route exact path="/forgot-password" element={
          <PublicRoute>
            <PasswordStep2 />
          </PublicRoute>}></Route>
        <Route exact path="/recuperation-contributor" element={
          <PublicRoute>
            <PasswordStep2 />
          </PublicRoute>}></Route>
        <Route exact path="/password-fail" element={
          <PublicRoute>
            <Confirmation />
          </PublicRoute>}></Route>
        <Route exact path="/pending-creation" element={
          <PublicRoute>
            <Confirmation />
          </PublicRoute>}></Route>
        <Route exact path="/confirmation-creation" element={
          <PublicRoute>
            <Confirmation />
          </PublicRoute>}></Route>
        <Route exact path="/creation-confirmed" element={
          <PublicRoute>
            <Confirmation />
          </PublicRoute>}></Route>
        <Route exact path="/confirmation-get-password" element={
          <PublicRoute>
            <Confirmation />
          </PublicRoute>}></Route>
        <Route exact path="/mandat-success" element={
          <Confirmation />
        }></Route>
        <Route exact path="/mandat-error" element={
          <Confirmation />
        }></Route>
        <Route exact path="/password-changed" element={
          <PublicRoute>
            <Confirmation />
          </PublicRoute>}></Route>
        <Route exact path="/desinscription" element={
          <Confirmation />
        }></Route>

        {/* Private */}
        <Route exact path="/dashboard" element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>} />
        <Route exact path="/ressources" element={
          <PrivateRoute>
            <Resources />
          </PrivateRoute>} />
        <Route exact path="/account" element={
          <PrivateRoute>
            <UpdateAccount />
          </PrivateRoute>} />
        <Route exact path="/contributors" element={
          <PrivateRoute>
            <Contributors />
          </PrivateRoute>} />
        <Route exact path="/contributors-add" element={
          <PrivateRoute>
            <AddContributors />
          </PrivateRoute>} />
        <Route exact path="/contributors-update" element={
          <PrivateRoute>
            <AddContributors />
          </PrivateRoute>} />
        <Route exact path="/messages" element={
          <PrivateRoute>
            <MailBox />
          </PrivateRoute>} />
        <Route exact path="/new-message" element={
          <PrivateRoute>
            <NewMessage />
          </PrivateRoute>} />
        <Route exact path="/chat" element={
          <PrivateRoute>
            <Chat />
          </PrivateRoute>} />
        <Route exact path="/mandates" element={
          <PrivateRoute>
            <Mandates />
          </PrivateRoute>} />
        <Route exact path="/wattvalue-invoices" element={
          <PrivateRoute>
            <WattvalueInvoices />
          </PrivateRoute>} />
        <Route exact path="/sepa" element={
          <PrivateRoute>
            <Sepa />
          </PrivateRoute>} />
        <Route exact path="/sepa-update" element={
          <PrivateRoute>
            <UpdateSepa />
          </PrivateRoute>} />
        <Route exact path="/green-energy" element={
          <PrivateRoute>
            <GreenEnergy />
          </PrivateRoute>} />
        <Route exact path="/invoice-verification" element={
          <PrivateRoute>
            <InvoiceVerification />
          </PrivateRoute>} />
        <Route exact path="/invoice-site-detail" element={
          <PrivateRoute>
            <InvoiceVerificationDetail />
          </PrivateRoute>} />
        <Route exact path="/invoice-site-upload" element={
          <PrivateRoute>
            <InvoiceSiteUpload />
          </PrivateRoute>} />
        <Route exact path="/invoice-upload" element={
          <PrivateRoute>
            <UploadInvoice />
          </PrivateRoute>} />
        <Route exact path="/gaz-sites" element={
          <PrivateRoute>
            <Sites />
          </PrivateRoute>} />
        <Route exact path="/appels-offre-gaz" element={
          <PrivateRoute>
            <AppelsOffre />
          </PrivateRoute>} />
        <Route exact path="/contrats-gaz" element={
          <PrivateRoute>
            <Contracts />
          </PrivateRoute>} />
        <Route exact path="/appels-offre-gaz-recap" element={
          <PrivateRoute>
            <AppelsOffreResult />
          </PrivateRoute>} />
        <Route exact path="/elec-sites" element={
          <PrivateRoute>
            <Sites />
          </PrivateRoute>} />
        <Route exact path="/appels-offre-elec" element={
          <PrivateRoute>
            <AppelsOffre />
          </PrivateRoute>} />
        <Route exact path="/contrats-elec" element={
          <PrivateRoute>
            <Contracts />
          </PrivateRoute>} />
        <Route exact path="/appels-offre-elec-recap" element={
          <PrivateRoute>
            <AppelsOffreResult />
          </PrivateRoute>} />
        <Route exact path="/conso" element={
          <PrivateRoute>
            <Conso />
          </PrivateRoute>} />
        <Route exact path="/conso-detail" element={
          <PrivateRoute>
            <ConsoDetail />
          </PrivateRoute>} />
        <Route exact path="/add-sites-1" element={
          <PrivateRoute>
            <AddSitesStep1 />
          </PrivateRoute>} />
        <Route exact path="/add-sites-2" element={
          <PrivateRoute>
            <AddSitesStep2 />
          </PrivateRoute>} />
        <Route exact path="/add-sites-3" element={
          <PrivateRoute>
            <AddSitesStep3 />
          </PrivateRoute>} />
        <Route exact path="/add-sites-4" element={
          <PrivateRoute>
            <AddSitesStep4 />
          </PrivateRoute>} />
        <Route exact path="/modify-sites-1" element={
          <PrivateRoute>
            <AddSitesStep1 />
          </PrivateRoute>} />
        <Route exact path="/modify-sites-2" element={
          <PrivateRoute>
            <AddSitesStep2 />
          </PrivateRoute>} />
        <Route exact path="/modify-sites-3" element={
          <PrivateRoute>
            <AddSitesStep3 />
          </PrivateRoute>} />
        <Route exact path="/modify-sites-4" element={
          <PrivateRoute>
            <AddSitesStep4 />
          </PrivateRoute>} />
        <Route exact path="/subscription" element={
          <PrivateRoute>
            <Subscription />
          </PrivateRoute>} />
        <Route exact path="/renouvellement" element={
          <PrivateRoute>
            <Subscription />
          </PrivateRoute>} />
        <Route exact path="/optimisation-turpe" element={
          <PrivateRoute>
            <Turpe />
          </PrivateRoute>} />
        <Route exact path="/optimisation-turpe-detail" element={
          <PrivateRoute>
            <TurpeDetail />
          </PrivateRoute>} />
        <Route exact path="/budget" element={
          <PrivateRoute>
            <Budget />
          </PrivateRoute>} />
        <Route exact path="*" element={
          <PrivateRoute>
            <NotFound />
          </PrivateRoute>} />

      </Routes>
    </BrowserRouter >
  );
}

export default App;
