import { useCallback, useEffect, useState } from "react"
import { useSearchParams, Link } from 'react-router-dom'

import SideBar from "../../../Components/SideBar/SideBar"
import axios from "axios"
import InvoiceVerificationDetailTable from "./InvoiceVerificationDetailTable"
import Info from '../../../img/info.png'

const apiUrl = process.env.REACT_APP_API_URL

function InvoiceVerificationDetail() {
    const [searchParams] = useSearchParams()

    const [site, setSite] = useState({})
    const [cumul, setCumul] = useState({})
    const [detail, setDetail] = useState([])

    const [isDataLoading, setIsDataLoading] = useState(false)

    const fetchData = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/facture/verification/${searchParams.get('id')}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                setSite(response.data.site)
                setCumul(response.data.cumul)
                setDetail(response.data.details)
            })
            .catch(error => {
                if (error && error.response.status && error.response.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [searchParams])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    return (
        <>
            <SideBar
                active="Verifier mes factures"
                openElec
            />
            <div className="homepage--container privatepages--container">
                <div className="privatepages--header__container">
                    <h3 className="privatepages--title privatepages--text">Site {site.libelle ? `: ${site.libelle}` : ""}{site.ville ? ` situé à ${site.ville}` : ""}{site.codePostal ? ` (${site.codePostal})` : ""}</h3>
                    <Link to='/invoice-verification'><span className="privatepages--header__return">{`< Retour à la liste des sites`}</span></Link>
                </div>
                <div className="privatepages--separator"></div>
                {/* Bloc servant à afficher du texte qui peut être renseigné dans le Back Office par WattValue (champ à ajouter dans la base de donnée) */}
                {/* <div className="elec--informationblock">
                    <div>
                        <div className="elec--informationblock__title">
                            <img src={Info} alt="Icone information" className="elec--informationblock__img" />Informations
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                    </div>
                </div> */}
                <div className="table--tablecontainer">
                    <h4 className="elec--title">PRM (RAE ou PDL) {site.prm ? `: ${site.prm}` : ""}</h4>
                    <p className="elec--paragraph">Comparaison des factures{(site.minDateDebut && site.maxDateFin) ? ` du ${new Date(site.minDateDebut).toLocaleDateString()} au ${new Date(site.maxDateFin).toLocaleDateString()}` : ""}.</p>
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            {
                                [cumul].length > 0 &&
                                <>
                                    <h5 className="elec--table__title">Situation cumulée du contrat en cours</h5>
                                    <div className="privatepages--separator elec--separator"></div>
                                    <InvoiceVerificationDetailTable
                                        rows={[cumul]}
                                    />
                                </>
                            }
                            {detail.length > 0 &&
                                <>
                                    <h5 className="elec--table__title elec--table__titlemargintop">Détails de la vérification des factures</h5>
                                    <div className="privatepages--separator elec--separator"></div>
                                    <InvoiceVerificationDetailTable
                                        rows={detail}
                                        isDetail
                                    />
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )

}

export default InvoiceVerificationDetail;