import SideBar from "../../../Components/SideBar/SideBar"
import WattvalueInvoicesTable from "./WattvalueInvoicesTable"
import { useState, useEffect, useCallback } from "react"
import axios from "axios"
import { SearchBar } from "../../../Components/SearchBar"
import { removeAccents } from "../../../Utils/Regex/removeAccents"
import { createDataExcelWattvalueInvoices } from "../../../Utils/TableUtils/createData"

const apiUrl = process.env.REACT_APP_API_URL

function WattvalueInvoices() {
    const [invoices, setInvoices] = useState([])
    const [filteredInvoices, setFilteredInvoices] = useState([])
    const [page, setPage] = useState(0)
    const [searchValue, setSearchValue] = useState("")

    const [isDataLoading, setIsDataLoading] = useState(false)

    const fetchInvoices = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/Facture`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                setInvoices(response.data)
                setFilteredInvoices(response.data)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [])

    useEffect(() => {
        fetchInvoices()
    }, [fetchInvoices])

    const filterInvoices = (query) => {
        if (!query || query === "") {
            setFilteredInvoices(invoices)
            setPage(0)
        }
        else {
            const keys = ['type', 'periode', 'libelle', 'raisonSociale', 'numFac', 'dateSignature', 'montantTTC']
            const results = invoices.filter((row) => (
                keys.some((key) => {
                    const k = row?.[key] ?? ''
                    return (removeAccents(k.toString()).includes(removeAccents(query)))
                }
                )))
            setFilteredInvoices(results)
            setPage(0)
        }
    }

    const getFile = async (documentID, fileName) => {
        axios.get(`${apiUrl}/document/${documentID}`,
            {
                withCredentials: true,
                responseType: 'blob',
            })
            .then((response) => {
                const href = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }))

                const link = document.createElement('a')
                link.href = href

                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(href)
            })
            .catch(error => {
                if (error && error.response.status && error.response.status === 401) {
                    window.location.reload()
                }
            })
    }

    return (
        <>
            <SideBar
                active="Billing"
                openAccount />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Factures WATTVALUE</h3>
                <div className="privatepages--separator"></div>
                <div className="table--tablecontainer">
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            {invoices.length > 0 && <div className="table--searchbarcontainer">
                                <SearchBar
                                    id="invoicestable"
                                    filterData={filterInvoices}
                                    setValue={setSearchValue}
                                    value={searchValue}
                                    additionnalFormClassName="searchbar--form table--form"
                                />
                            </div>}
                            {filteredInvoices.length > 0 ?
                                <WattvalueInvoicesTable
                                    rows={filteredInvoices}
                                    getFile={getFile}
                                    page={page}
                                    setPage={setPage}
                                />
                                :
                                <span>Aucune facture trouvée.</span>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default WattvalueInvoices;