import { useCallback, useEffect, useState } from "react"
import SideBar from "../../../Components/SideBar/SideBar"
import axios from "axios"
import GreenEnergyTable from "./GreenEnergyTable"
import { createDataGreenEnergy } from "../../../Utils/TableUtils/createData"
import Button from "../../../Components/Button"
import Img from '../../../img/green-energy.png'

const apiUrl = process.env.REACT_APP_API_URL

function GreenEnergy() {
    const [showFirstItem, setShowFirstItem] = useState(false)
    const [showSecondItem, setShowSecondItem] = useState(false)
    const [showThirdItem, setShowThirdItem] = useState(false)
    const [showFourthItem, setShowFourthItem] = useState(false)
    const [showFifthItem, setShowFifthItem] = useState(false)
    const [certificates, setCertificates] = useState([])
    const [isDataLoading, setIsDataLoading] = useState(false)

    const [goPercent, setGoPercent] = useState(0)

    const fetchCertificates = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/elec/green`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                const certificates = response.data.map(element => {
                    let periode = ""
                    if (element.dateDebut && element.dateFin) periode = `du ${new Date(element.dateDebut).toLocaleDateString()} au ${new Date(element.dateFin).toLocaleDateString()}`
                    return createDataGreenEnergy(element.annee, element.libelle, periode, element.nombreGO, element.siteID)
                })
                setCertificates(certificates)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [])

    const getGoPercent = useCallback(() => {
        axios.get(`${apiUrl}/Conso/Info/Year/GO`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                response.data?.goEnCours && setGoPercent(response.data.goEnCours)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [])

    useEffect(() => {
        fetchCertificates()
    }, [fetchCertificates])

    useEffect(() => {
        getGoPercent()
    }, [getGoPercent])

    const getAttestation = (id, year) => {
        axios.get(`${apiUrl}/elec/green/certificate?id=${id}&annee=${year}`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
                responseType: 'blob',
            })
            .then((response) => {
                const href = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }))

                const link = document.createElement('a')
                link.href = href

                link.setAttribute('download', "GarantiesOrigine")
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(href)
            })
            .catch(error => {
                if (error && error.response.status && error.response.status === 401) {
                    window.location.reload()
                }
            })
    }

    return (
        <>
            <SideBar
                openElec
                active="Electricite verte" />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Electricité verte</h3>
                <div className="privatepages--separator"></div>
                {((certificates.length === 0 || goPercent === 0) && !isDataLoading) &&
                    <div className="greenenergy--block__container">
                        <div className="greenenergy--block greenenergy--block__left">
                            <span className="greenenergy--title">Garanties d'origine - Un choix utile pour une consommation énergétique plus responsable</span>
                            <ol>
                                <li className="greenenergy--list__item"><span className="greenenergy--list__number">1.</span> Traçabilité et transparence assurées <span className="greenenergy--showmore" onClick={() => setShowFirstItem(!showFirstItem)}>{showFirstItem ? "Réduire -" : "En savoir plus +"}</span></li>
                                {showFirstItem &&
                                    <p className="greenenergy--list__item">
                                        Les garanties d'origine certifient que l'électricité que vous consommez provient de sources de production renouvelables parfaitement identifiables (éolien, solaire, hydraulique etc.), avec une correspondance stricte entre le mois de production et de consommation, en conformité avec la réglementation française.
                                    </p>
                                }
                                <li className="greenenergy--list__item"><span className="greenenergy--list__number">2.</span> Cadre réglementaire solide et mature <span className="greenenergy--showmore" onClick={() => setShowSecondItem(!showSecondItem)}>{showSecondItem ? "Réduire -" : "En savoir plus +"}</span></li>
                                {showSecondItem &&
                                    <p className="greenenergy--list__item">
                                        Incorporées dans le Code de l'énergie en France, les garanties d'origine constituent un outil fiable et performant, offrant aux entreprises une voie simple et reconnue pour s'engager dans la transition énergétique en faveur de la promotion des énergies renouvelables.
                                    </p>
                                }
                                <li className="greenenergy--list__item"><span className="greenenergy--list__number">3.</span> Un choix stratégique pour réduire votre impact environnemental <span className="greenenergy--showmore" onClick={() => setShowThirdItem(!showThirdItem)}>{showThirdItem ? "Réduire -" : "En savoir plus +"}</span></li>
                                {showThirdItem &&
                                    <p className="greenenergy--list__item">
                                        En intégrant des garanties d'origine à votre stratégie énergétique, vous agissez de manière concrète pour réduire l'empreinte carbone de votre entreprise. Cela vous permet de participer activement à la transition énergétique, tout en optimisant la gestion de vos coûts et en répondant aux attentes croissantes en matière de durabilité de vos clients et partenaires.
                                    </p>
                                }
                                <li className="greenenergy--list__item"><span className="greenenergy--list__number">4.</span> Valorisez votre engagement environnemental <span className="greenenergy--showmore" onClick={() => setShowFourthItem(!showFourthItem)}>{showFourthItem ? "Réduire -" : "En savoir plus +"}</span></li>
                                {showFourthItem &&
                                    <p className="greenenergy--list__item">
                                        Affichez un engagement concret en faveur de l'environnement avec des garanties traçables. Positionnez votre entreprise comme un acteur responsable et engagé dans la lutte contre le changement climatique pour créer un avantage concurrentiel et vous différentier.
                                    </p>
                                }
                                <li className="greenenergy--list__item"><span className="greenenergy--list__number">5.</span> Contribuez à un modèle énergétique durable et innovant <span className="greenenergy--showmore" onClick={() => setShowFifthItem(!showFifthItem)}>{showFifthItem ? "Réduire -" : "En savoir plus +"}</span></li>
                                {showFifthItem &&
                                    <p className="greenenergy--list__item">
                                        En choisissant les garanties d'origine, vous participez au développement d'un modèle énergétique basé sur les sources renouvelables. Cette démarche favorise l'innovation dans le secteur de l'énergie verte, encourageant la transition vers un avenir énergétique plus durable et moins dépendant des énergies fossiles. C'est une contribution à long terme vers un réseau électrique plus résilient et respectueux de l'environnement.
                                    </p>
                                }
                            </ol>
                            <p className="greenenergy--list__item">➜ Pour passer à l'électricité verte, utilisez notre messagerie et choisissez "Souscription à un service".<br />Nous reprendrons contact avec vous sous peu pour vous donner les informations nécessaires.
                            </p>
                            <Button
                                cta="Passez à l'électricité verte"
                                link='/new-message?object=souscription' />
                        </div>
                        <div className="greenenergy--block greenenergy--block__right">
                            <img src={Img} alt="Eoliennes" className="greenenergy--block__img" />
                            <span className="greenenergy--block__imgsubtitle">Énergie 100% renouvelable<br />Garanties d'Origine</span>
                        </div>
                    </div>
                }
                <div className="table--tablecontainer">
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            {certificates.length > 0 ?
                                <>
                                    <GreenEnergyTable
                                        rows={certificates}
                                        getFile={getAttestation}
                                    />
                                    <p className="greenenergy--paragraph">
                                        En choisissant l'électricité verte avec WattValue, <span className="greenenergy--paragraph__bold">vous renforcez l'engagement de votre entreprise</span> en matière de développement durable. Ce choix s'aligne directement avec les critères de l'<span className="greenenergy--paragraph__bold">Investissement Socialement Responsable (ISR)</span> et les exigences de la <span className="greenenergy--paragraph__bold">Corporate Sustainability Reporting Directive (CSRD)</span>.
                                        <br /><br />
                                        Chaque mégawattheure d'électricité verte est certifié par des Garanties d'Origine, garantissant la traçabilité de votre consommation d'énergie . Cela contribue non seulement à réduire votre empreinte carbone, mais aussi à valoriser votre <span className="greenenergy--paragraph__bold">engagement auprès de vos parties prenantes</span>.
                                    </p>
                                </>
                                :
                                <span className="greenenergy--list__item greenenergy--maptitle">Liste des installations de production ayant fourni les garanties d'origine sont toutes situées en France.</span>
                            }
                            <iframe
                                src="https://www.google.com/maps/d/embed?mid=1wUmu-Lybx8vb8RjYGE2XwGtYtXs&ehbc=2E312F"
                                style={{ border: 0, borderRadius: "10px" }}
                                allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"
                                width="100%"
                                height="700px"
                                title="wattvalue-map"
                                loading="async"
                            />
                        </>
                    }
                </div>
            </div >
        </>
    )
}

export default GreenEnergy;