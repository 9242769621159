import { useCallback, useEffect, useState } from "react"
import { Link } from 'react-router-dom'

import SideBar from "../../../Components/SideBar/SideBar"
import axios from "axios"
import { createDataElecInvoices } from "../../../Utils/TableUtils/createData"
import InvoiceVerificationTable from "./InvoiceVerificationTable"
import { SearchBar } from "../../../Components/SearchBar"
import FormInput from "../../../Components/Form/FormInput"
import { optionsStatut } from "../../../Utils/DropDownOptions/optionsStatut"
import Button from "../../../Components/Button"
// import Info from '../../../img/info.png'
import { removeAccents } from "../../../Utils/Regex/removeAccents"

const apiUrl = process.env.REACT_APP_API_URL

function InvoiceVerification() {
    const [invoices, setInvoices] = useState([])
    const [filteredInvoices, setFilteredInvoices] = useState([])
    const [filter, setFilter] = useState("")
    const [statut, setStatut] = useState("")
    const [page, setPage] = useState(0)
    const [searchValue, setSearchValue] = useState("")

    const [isDataLoading, setIsDataLoading] = useState(false)

    const fetchInvoices = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/facture/verification`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                const invoices = response.data.map((element) => {
                    const anomalies = `${element.anomalies} anomalie${element.anomalies > 1 ? "s" : ""} sur ${element.total} facture${element.total > 1 ? "s" : ""} analysée${element.total > 1 ? "s" : ""}`
                    return createDataElecInvoices(element.statut, element.site, element.raisonSociale, `${element.adresse ? element.adresse : ""} ${element.codePostal ? element.codePostal : ""} ${element.ville ? element.ville : ""}`, element.prm, element.elecFournisseurNom, element.dateDebutFourniture, element.dateFinNouveauContrat, element.maxDateFacturation, anomalies, `${element.siteID}`)
                })
                setFilteredInvoices(invoices)
                setInvoices(invoices)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [])

    const fetchExcel = (request, fileName) => {
        axios.get(`${apiUrl}/facture/verification/export/${request}`,
            {
                withCredentials: true,
                responseType: 'blob',
            })
            .then((response) => {
                const href = window.URL.createObjectURL(new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))

                const link = document.createElement('a')
                link.href = href

                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(href)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    useEffect(() => {
        fetchInvoices()
    }, [fetchInvoices])

    const filterInvoices = useCallback(() => {
        const keys = ['statut', 'site', 'raisonSociale', 'adresse', 'prm', 'derniereFacture', 'anomalies']
        if ((!filter || filter === "") && (!statut || statut === "" || statut === "Tous")) {
            setFilteredInvoices(invoices)
            setPage(0)
        }
        else if ((!filter || filter === "") && statut) {
            const results = invoices.filter((row) => row.statut === statut)
            setFilteredInvoices(results)
            setPage(0)
        }
        else if ((!statut || statut === "" || statut === "Tous") && filter) {
            const results = invoices.filter((row) => (
                keys.some((key) => {
                    const k = row?.[key] ?? ''
                    return (removeAccents(k.toString()).includes(removeAccents(filter)))
                }
                )))
            setFilteredInvoices(results)
            setPage(0)
        }
        else {
            const results = invoices.filter((row) => (
                keys.some((key) => {
                    const k = row?.[key] ?? ''
                    return (removeAccents(k.toString()).includes(removeAccents(filter)))
                }
                ))).filter((row) => row.statut === statut)
            setFilteredInvoices(results)
            setPage(0)
        }
    }, [filter, invoices, statut])

    useEffect(() => {
        filterInvoices()
    }, [filterInvoices, filter, statut])

    return (
        <>
            <SideBar
                active="Verifier mes factures"
                openElec
            />
            <div className="homepage--container privatepages--container">
                <h3 className="privatepages--title privatepages--text">Vérification de mes factures d'électricité</h3>
                <div className="privatepages--separator"></div>
                {/* Bloc servant à afficher du texte qui peut être renseigné dans le Back Office par WattValue (champ à ajouter dans la base de donnée) */}
                {/* <div className="elec--informationblock">
                    <div>
                        <div className="elec--informationblock__title">
                            <img src={Info} alt="Icone information" className="elec--informationblock__img" />Informations
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                    </div>
                </div> */}
                <div className="table--tablecontainer">
                    {isDataLoading ?
                        <span className="global--loader"></span>
                        :
                        <>
                            {
                                invoices.length > 0 ?
                                    <>
                                        <h4 className="elec--title">Liste des sites inclus à la vérification de facture</h4>
                                        <p className="elec--paragraph">Vous retrouvez ci-dessous l'ensemble des sites pour lesquels les fournisseurs nous transmettent régulièrement les montants qu'ils facturent et que nous vérifions. Si vous souhaitez nous transmettre directement une facture, vous pouvez le faire en <Link to='/invoice-site-upload'><span className="elec--link">cliquant ici</span></Link>.</p>
                                        <p className="elec--paragraph">La vérification de vos factures d'électricité est assurée au mieux mensuellement ou au pas trimestriel. Elle ne peut être complétée qu'après la collecte des données finales de facturation auprès de votre fournisseur. Le statut de la vérification vous informe de l'état d'avancement de cette vérification.</p>
                                        <div className="elec--paragraph__container">
                                            <p className="elec--paragraph elec--paragraph__withbutton">Exporter sur Excel la vérification détaillée des factures de mes sites.</p>
                                            <Button
                                                handleSubmit={() => fetchExcel("details", "detail.xlsx")}
                                                cta="Export (.XLSX)"
                                                additionnalClassName="table--tablecontainer__button" />
                                        </div>
                                        <div className="elec--paragraph__container">
                                            <p className="elec--paragraph elec--paragraph__withbutton">Exporter sur Excel la situation cumulée à date de la vérification des factures de mes sites.</p>
                                            <Button
                                                handleSubmit={() => fetchExcel("cumul", "cumul.xlsx")}
                                                cta="Export (.XLSX)"
                                                additionnalClassName="table--tablecontainer__button" />
                                        </div>
                                        <div className="elec--filter__container">
                                            <SearchBar
                                                additionnalBarClassName="elec--filter__searchbar"
                                                filterData={(value) => {
                                                    setFilter(value)
                                                }}
                                                setValue={setSearchValue}
                                                value={searchValue}
                                                closerIcon
                                            />
                                            <FormInput
                                                type="dropdown"
                                                dropdownClassName="elec--filter__dropdown"
                                                options={optionsStatut}
                                                onChangeInput={(e) => {
                                                    setStatut(e.value)
                                                }}
                                                value={statut}
                                                placeholder="Statut"
                                                isSearchable={false} />
                                        </div>
                                        <div className="privatepages--separator"></div>
                                        {filteredInvoices.length > 0 ?
                                            <InvoiceVerificationTable
                                                rows={filteredInvoices}
                                                page={page}
                                                setPage={setPage} />
                                            :
                                            <span className="elec--emptystate">Aucun site trouvé</span>}
                                    </>
                                    :
                                    <span>Aucun site trouvé</span>
                            }
                        </>
                    }
                </div>
            </div>
        </>
    )

}

export default InvoiceVerification;