import EyeIcon from '../../img/icons/eye.svg'
import EyeIconCrossedOut from '../../img/icons/eye_crossed_out.svg'
import Button from '../Button'
import FormInput from './FormInput'
import { validPassword } from '../../Utils/Regex/validPassword'
import { useLocation } from 'react-router-dom'

function LoginForm(props) {
    const location = useLocation()

    if (props.formStep === "Signin") {
        return (
            <>
                <span className="homepage--popup__formtitle">Créer votre compte</span>
                <form className="homepage--popup__formcontainer">
                    <div className="homepage--popup__multipleinputcontainer homepage--popup__multipleinputcontainermarginbottom homepage--popup__multipleinputcontainermargintop">
                        <label className="homepage--popup__radioinputcontainer" htmlFor="monsieur">M.
                            <input type="radio" defaultChecked="checked" id="monsieur" name="civility" value="Monsieur" onChange={e => props.setCivility(e.target.value)} />
                            <span className="homepage--popup__checkmark"></span>
                        </label>
                        <label className="homepage--popup__radioinputcontainer" htmlFor="madame">Mme.
                            <input type="radio" name="civility" id="madame" value="Madame" onChange={e => props.setCivility(e.target.value)} />
                            <span className="homepage--popup__checkmark"></span>
                        </label>
                    </div>
                    <div className="homepage--popup__multipleinputcontainer">
                        <div className="homepage--popup__insideinputcontainer homepage--popup__insideinputcontainerleft">
                            <FormInput
                                label="Nom*"
                                type="text"
                                id="lastname"
                                hmlFor="lastname"
                                autoComplete="family-name"
                                onChangeInput={value => { props.setLastName(value) }}
                                placeholder="Emilie"
                                value={props.lastname}
                                maxLength={255}
                            />
                        </div>
                        <div className="homepage--popup__insideinputcontainer">
                            <FormInput
                                label="Prénom*"
                                type="text"
                                id="firstname"
                                autoComplete="given-name"
                                onChangeInput={value => { props.setFirstName(value) }}
                                placeholder="Sardou"
                                value={props.firstname}
                                maxLength={255}
                            />
                        </div>
                    </div>
                    <FormInput
                        label="Email*"
                        type="email"
                        id="email"
                        autoComplete="username"
                        onChangeInput={value => {
                            props.setShowErrorMessage(false)
                            props.setEmail(value)
                        }}
                        placeholder="emilie.sardou@gmail.com"
                        value={props.email}
                        maxLength={255}
                    />
                    <div className="homepage--popup__multipleinputcontainer homepage--popup__multipleinputcontainermargintop">
                        <div className="homepage--popup__insideinputcontainer homepage--popup__insideinputcontainerleft">
                            <FormInput
                                label="Téléphone mobile"
                                type="number"
                                id="mobile"
                                onChangeInput={value => {
                                    props.setShowErrorMessage(false)
                                    props.setMobile(value)
                                }}
                                placeholder="06 01 02 03 04"
                                value={props.mobile}
                                maxLength={50}
                            />
                        </div>
                        <div className="homepage--popup__insideinputcontainer">
                            <FormInput
                                label="Téléphone fixe"
                                type="number"
                                id="fixe"
                                onChangeInput={value => {
                                    props.setShowErrorMessage(false)
                                    props.setLandline(value)
                                }}
                                placeholder="02 35 12 65 98"
                                value={props.landline}
                                maxLength={50}
                            />
                        </div>
                    </div>
                    <div className="homepage--popup__multipleinputcontainer homepage--popup__multipleinputcontainermargintop">
                        <div className="homepage--popup__insideinputcontainer homepage--popup__insideinputcontainerleft">
                            <FormInput
                                inputLabel="Fonction*"
                                type="dropdown"
                                id="fonction"
                                onChangeInput={e => { props.setFonction(e.value) }}
                                placeholder="Choisir..."
                                value={props.fonction}
                                isSearchable={false}
                                options={props.optionsFonction}
                            />
                        </div>
                        <div className="homepage--popup__insideinputcontainer">
                            <FormInput
                                label="Raison sociale*"
                                type="text"
                                id="company"
                                onChangeInput={value => { props.setCompany(value) }}
                                placeholder="WattValue"
                                value={props.company}
                                maxLength={255}
                            />
                        </div>
                    </div>
                    <div className="homepage--popup__passwordinputcontainer">
                        <img src={props.inputType === "password" ? EyeIconCrossedOut : EyeIcon} alt="" className={props.inputType === "password" ? "homepage--popup__icon homepage--popup__iconcrossedout" : "homepage--popup__icon"} onClick={() => props.onClickEyeIcon()} />
                        <FormInput
                            label="Mot de passe"
                            type={props.inputType}
                            id="password"
                            onChangeInput={value => { props.handlePasswordClick("password", value) }}
                            placeholder={props.inputType === "password" ? "∗∗∗∗∗∗∗∗∗∗∗∗∗∗" : "15eegegeàç_àde"}
                            value={props.password}
                            maxLength={50}
                            autoComplete="new-password"
                        />
                    </div>
                    <div className="homepage--popup__passwordinputcontainer">
                        <img src={props.inputType === "password" ? EyeIconCrossedOut : EyeIcon} alt="" className={props.inputType === "password" ? "homepage--popup__icon homepage--popup__iconcrossedout" : "homepage--popup__icon"} onClick={() => props.onClickEyeIcon()} />
                        <FormInput
                            label="Mot de passe (confirmation)"
                            type={props.inputType}
                            id="passwordconfirmation"
                            onChangeInput={value => { props.handlePasswordClick("passwordconfirmation", value) }}
                            placeholder={props.inputType === "password" ? "∗∗∗∗∗∗∗∗∗∗∗∗∗∗" : "15eegegeàç_àde"}
                            value={props.passwordConfirmation}
                            additionnalClassName="homepage--popup__inputmarginbottom8"
                            maxLength={50}
                            autoComplete="new-password"
                        />
                    </div>
                    {props.showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror">{props.errorMessage}</span>}
                    <Button
                        cta="Créer un compte"
                        disabled={props.isSubmitSignInDisabled()}
                        handleSubmit={props.handleSubmitSignIn}
                        isLoading={props.isCreateLoading}
                    />
                    <span className="homepage--popup__mandatory">*Champs obligatoires</span>
                    <span className="homepage--popup__noaccount">Déjà un compte ?</span>
                    <span className="homepage--url homepage--url__createaccount" onClick={() => props.handleFormChange("Login")}>Se connecter</span>
                </form>
            </>
        )
    }

    else if (props.formStep === "PasswordStep1") {
        return (
            <>
                <span className="homepage--popup__formtitle">Modification de mot de passe</span>
                <span className="homepage--popup__formsubtitle">Merci de saisir votre email</span>
                <form className="homepage--popup__formcontainer">
                    <FormInput
                        label="Email"
                        type="email"
                        id="email"
                        autoComplete="email"
                        onChangeInput={value => {
                            props.setShowErrorMessage(false)
                            props.setEmail(value)
                        }}
                        placeholder="emilie.sardou@gmail.com"
                        value={props.email}
                        additionnalClassName="homepage--popup__inputmarginbottom33"
                    />
                    {props.showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror">{props.errorMessage}</span>}
                    <Button
                        cta="Envoyer"
                        disabled={props.email === "" || !props.email}
                        handleSubmit={props.handleSubmitPasswordStep1}
                        isLoading={props.isLoading}
                    />
                    <span className="homepage--popup__noaccount">Pas encore de compte ?</span>
                    <span className="homepage--url homepage--url__createaccount"
                        onClick={() => props.handleFormChange("Signin")}
                    >Créer mon compte</span>
                </form>
            </>
        )
    }

    else if (props.formStep === "PasswordStep2") {
        return (
            <>
                <span className="homepage--popup__formtitle">Mot de passe oublié ?</span>
                <form className="homepage--popup__formcontainer">
                    <div className="homepage--popup__passwordinputcontainer">
                        <img src={props.inputType === "password" ? EyeIconCrossedOut : EyeIcon} alt="" className={props.inputType === "password" ? "homepage--popup__icon homepage--popup__iconcrossedout" : "homepage--popup__icon"} onClick={() => props.onClickEyeIcon()} />
                        <FormInput
                            label="Mot de passe"
                            type={props.inputType}
                            id="password"
                            onChangeInput={value => { props.handlePasswordClick("password", value) }}
                            placeholder={props.inputType === "password" ? "∗∗∗∗∗∗∗∗∗∗∗∗∗∗" : "15eegegeàç_àde"}
                            value={props.password}
                            maxLength={50}
                            autoComplete="new-password"
                        />
                    </div>
                    <div className="homepage--popup__passwordinputcontainer">
                        <img src={props.inputType === "password" ? EyeIconCrossedOut : EyeIcon} alt="" className={props.inputType === "password" ? "homepage--popup__icon homepage--popup__iconcrossedout" : "homepage--popup__icon"} onClick={() => props.onClickEyeIcon()} />
                        <FormInput
                            label="Mot de passe (confirmation)"
                            type={props.inputType}
                            id="passwordconfirmation"
                            onChangeInput={value => { props.handlePasswordClick("passwordconfirmation", value) }}
                            placeholder={props.inputType === "password" ? "∗∗∗∗∗∗∗∗∗∗∗∗∗∗" : "15eegegeàç_àde"}
                            value={props.passwordConfirmation}
                            additionnalClassName="homepage--popup__inputmarginbottom33"
                            maxLength={50}
                            autoComplete="new-password"
                        />
                    </div>
                    {props.showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror">{props.errorMessage}</span>}
                    <Button
                        cta="Enregistrer"
                        disabled={!validPassword.test(props.password) || !validPassword.test(props.passwordConfirmation) || props.showErrorMessage}
                        handleSubmit={props.handleSubmitPasswordStep2}
                        isLoading={props.isLoading}
                    />
                </form>
            </>

        )
    }

    else {
        return (
            <>
                <span className="homepage--popup__formtitle">Se connecter</span>
                <form className="homepage--popup__formcontainer">
                    <FormInput
                        label="Email"
                        type="email"
                        id="email"
                        onChangeInput={value => { props.setEmail(value) }}
                        placeholder="seamus.schmidt@gmail.com"
                        value={props.email}
                        autoComplete="email"
                    />
                    <div className="homepage--popup__passwordinputcontainer">
                        <img src={props.inputType === "password" ? EyeIconCrossedOut : EyeIcon} alt="" className={props.inputType === "password" ? "homepage--popup__icon homepage--popup__iconcrossedout" : "homepage--popup__icon"} onClick={() => props.onClickEyeIcon()} />
                        <FormInput
                            label="Mot de passe"
                            type={props.inputType}
                            id="password"
                            onChangeInput={value => { props.handlePasswordClick("passwordlogin", value) }}
                            placeholder={props.inputType === "password" ? "∗∗∗∗∗∗∗∗∗∗∗∗∗∗" : "15eegegeàç_àde"}
                            value={props.password}
                            handleSubmit={props.handleSubmitLogin}
                            autoComplete="current-password"
                        />
                    </div>
                    <span
                        className="homepage--url homepage--url__password"
                        onClick={() => props.handleFormChange("PasswordStep1")}
                    >{location.pathname === "/recuperation-contributor" ? "Créez votre mot de passe" : "Mot de passe oublié ?"}</span>
                    {props.showErrorMessage && <span className="homepage--popup__message homepage--popup__messageerror">{props.errorMessage}</span>}
                    <Button
                        cta="Se connecter"
                        disabled={props.isLoginButtonDisabled}
                        handleSubmit={props.handleSubmitLogin}
                        isLoading={props.isLoading}
                    />
                </form>
                <span className="homepage--popup__noaccount">Pas encore de compte ?</span>
                <span className="homepage--url homepage--url__createaccount"
                    onClick={() => props.handleFormChange("Signin")}
                >Créer mon compte</span>
            </>
        )
    }
}

export default LoginForm;