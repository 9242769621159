import { useState, useEffect, useCallback, Fragment } from "react"
import { useSearchParams, Link } from 'react-router-dom'

import SideBar from '../../Components/SideBar/SideBar'
import Switch, { switchClasses } from '@mui/joy/Switch'
import Button from '../../Components/Button'
import Stats from '../../img/icons/stats.svg'
import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import Elec from '../../img/icons/elec.svg'
import Gaz from '../../img/icons/gaz.svg'
import AppelOffres from '../../img/icons/appel-offres.png'
import axios from "axios"
import { createDataSites } from '../../Utils/TableUtils/createData'
import SitesTable from '../Sites/SitesTable'
import FormInput from '../../Components/Form/FormInput'
import { monthsOptions } from "../../Utils/GraphUtils/monthsOptions"
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label, LineChart, Line } from 'recharts'
import { ConsoCustomTooltip } from "../Elec/Conso/ConsoCustomTooltip"
import { lineColorsEnergy, lineColorsEuros } from "../../Utils/GraphUtils/lineColors"
import { Tooltip as GreyTooltip } from "@mui/material"
import ToolTip from "../../Components/ToolTip"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const apiUrl = process.env.REACT_APP_API_URL

function Dashboard() {
    const [searchParams] = useSearchParams()
    const [page, setPage] = useState(0)

    const [isDataLoading, setIsDataLoading] = useState(false)
    const [isConsoLoading, setIsConsoLoading] = useState(false)
    const [isGraphDataLoading, setIsGraphDataLoading] = useState(true)

    const [firstName, setFirstName] = useState("")

    const [isElec, setIsElec] = useState(true)
    const [isGaz, setIsGaz] = useState(false)

    const [sitesElec, setSitesElec] = useState([])
    const [sitesGaz, setSitesGaz] = useState([])
    const [appelsOffreElec, setAppelsOffreElec] = useState([])
    const [appelsOffreGaz, setAppelsOffreGaz] = useState([])
    const [prmNumber, setPrmNumber] = useState(0)
    const [pceNumber, setPceNumber] = useState(0)
    const [nextAppelOffreElec, setNextAppelOffreElec] = useState(0)
    const [nextAppelOffreGaz, setNextAppelOffreGaz] = useState(0)
    const [goPercent, setGoPercent] = useState(0)
    const [conso, setConso] = useState(0)
    const [consoPrecedente, setConsoPrecedente] = useState(0)
    const [consoDebut, setConsoDebut] = useState(0)
    const [consoFin, setConsoFin] = useState(0)

    const [dropdownOptions, setDropdownOptions] = useState([])
    const [dropdownValue, setDropdownValue] = useState("cumul")
    const [dropdownLabel, setDropdownLabel] = useState("Cumul")
    const [dropdownReleveType, setDropdownReleveType] = useState("Cumul")
    const [dropdownSubscriptionType, setDropdownSubscriptionType] = useState("N/A")

    const [graphData, setGraphData] = useState([])
    const [yearQuery, setYearQuery] = useState()

    const [hasBase, setHasBase] = useState(false)
    const [hasHp, setHasHp] = useState(false)
    const [hasHc, setHasHc] = useState(false)
    const [hasPointe, setHasPointe] = useState(false)
    const [hasPm, setHasPm] = useState(false)
    const [hasHph, setHasHph] = useState(false)
    const [hasHch, setHasHch] = useState(false)
    const [hasHpe, setHasHpe] = useState(false)
    const [hasHce, setHasHce] = useState(false)

    const getSitesElec = useCallback(() => {
        setIsDataLoading(true)
        axios.get(`${apiUrl}/Elec`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                const sites = response?.data?.map(element => createDataSites(element.appelOffre, element.libelle, `${element.adresse ? element.adresse : ""}${element.codePostal ? ` ${element.codePostal}` : ""}${element.ville ? ` ${element.ville}` : ""}`, element.pce, element.prm, element.fournisseur, element.dateDebutFourniture, element.dateFinNouveauContrat, element.prixParMWh, element.consommation, element.facturation, element.prix, element.car, element.isConfirmed, element.etapeValidation, element.id))
                setSitesElec(sites)
                if (response?.data?.length > 0) {
                    getPrmNumber()
                    getAppelOffresElecInfo()
                    getGoPercent()
                    getConso()
                    getElecAppelsOffre()
                    getDrowpdownSites()
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsDataLoading(false))
    }, [])

    const getSitesGaz = useCallback(() => {
        axios.get(`${apiUrl}/Gaz`,
            {
                headers: {
                    'Accept': 'text/plain',
                },
                withCredentials: true,
            })
            .then((response) => {
                const sites = response.data.map(element => createDataSites(element.appelOffre, element.libelle, `${element.adresse ? element.adresse : ""}${element.codePostal ? ` ${element.codePostal}` : ""}${element.ville ? ` ${element.ville}` : ""}`, element.pce, element.prm, element.fournisseur, element.dateDebutFourniture, element.dateFinNouveauContrat, element.prixParMWh, element.consommation, element.facturation, element.prix, element.car, element.isConfirmed, element.etapeValidation, element.id))
                setSitesGaz(sites)
                if (response?.data?.length > 0) {
                    getPceNumber()
                    getAppelOffresGazInfo()
                    getGazAppelsOffre()
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [])

    const checkIsClient = useCallback(() => {
        axios.get(`${apiUrl}/auth/info`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then((response) => {
                if (response.data.isClient) {
                    getFirstNameClient()
                }
                else {
                    getFirstNameContributor()
                }
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }, [])

    const getFirstNameClient = async () => {
        axios.get(`${apiUrl}/Client`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                response?.data?.prenom && setFirstName(response.data.prenom)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const getFirstNameContributor = async () => {
        axios.get(`${apiUrl}/Contact`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                response?.data[0]?.prenom && setFirstName(response.data[0].prenom)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const getPrmNumber = () => {
        axios.get(`${apiUrl}/Elec/Info/Prm`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                setPrmNumber(response.data)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const getPceNumber = () => {
        axios.get(`${apiUrl}/Gaz/Info/Pce`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                setPceNumber(response.data)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const getAppelOffresElecInfo = () => {
        axios.get(`${apiUrl}/Elec/AppelOffre/Ongoing`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                response?.data?.dateFinSouscription && setNextAppelOffreElec(response.data.dateFinSouscription)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const getAppelOffresGazInfo = () => {
        axios.get(`${apiUrl}/Gaz/AppelOffre/Ongoing`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                response?.data?.dateFinSouscription && setNextAppelOffreGaz(response.data.dateFinSouscription)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const getGoPercent = () => {
        axios.get(`${apiUrl}/Conso/Info/Year/GO`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                response.data?.goEnCours && setGoPercent(response.data.goEnCours)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const getConso = () => {
        setIsConsoLoading(true)
        axios.get(`${apiUrl}/Conso/Info/Year/Conso`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                response.data?.consoEnCours?.consommation && setConso(response.data.consoEnCours.consommation)
                response.data?.consoPrecedente?.consommation && setConsoPrecedente(response.data.consoPrecedente.consommation)
                if (response.data?.consoEnCours?.minDate && response.data.consoEnCours.minDate !== "0001-01-01T00:00:00") setConsoDebut(response.data.consoEnCours.minDate)
                if (response.data?.consoEnCours?.maxDate && response.data.consoEnCours.maxDate !== "0001-01-01T00:00:00") setConsoFin(response.data.consoEnCours.maxDate)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
            .finally(() => setIsConsoLoading(false))
    }

    const getElecAppelsOffre = () => {
        axios.get(`${apiUrl}/Elec/AppelOffre`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                let appelsOffreElec = []
                response.data.forEach(appel => {
                    if (appel.sites.length > 0) appelsOffreElec.push(appel)
                })

                setAppelsOffreElec(appelsOffreElec)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const getGazAppelsOffre = () => {
        axios.get(`${apiUrl}/Gaz/AppelOffre`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                let appelsOffreGaz = []
                response.data.forEach(appel => {
                    if (appel.sites.length > 0) appelsOffreGaz.push(appel)
                })
                setAppelsOffreGaz(appelsOffreGaz)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const getDrowpdownSites = () => {
        axios.get(`${apiUrl}/Conso/Site`,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            .then(response => {
                if (response.data?.length === 0) {
                    setIsGraphDataLoading(false)
                }

                let array = []
                array.push({ label: "Cumul", value: "cumul", releveType: "Cumul", souscriptionType: "N/A" })
                response?.data?.forEach(site => {
                    array.push({ label: `${site.libelle} (id ${site.siteID})`, value: site.siteID, releveType: (site.releveType === "Mensuel" || site.souscriptionType === "Renouveler") ? "Mensuel" : "CDC", souscriptionType: site.souscriptionType })
                })

                setDropdownOptions(array)
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    window.location.reload()
                }
            })
    }

    const getGraphData = useCallback(() => {
        if (dropdownValue) {
            setIsGraphDataLoading(true)
            axios.get(`${apiUrl}/Conso/Graph/${dropdownValue}/Mensuel/Data?mois=12`,
                {
                    headers: {
                        'Accept': 'text/plain',
                    },
                    withCredentials: true,
                })
                .then((response) => {
                    let data = [...response.data?.data]
                    data.forEach(element => {
                        let year = new Date(element.debut).getYear().toString()
                        if (year[0] === "1" && year.length === 3) year = year.slice(1)
                        element.yearLabel = `${monthsOptions[new Date(element.debut).getMonth()].moisLabel} ${year ? year : ""}`
                    })
                    setGraphData(data)

                    if (data.some(element => element.base !== 0)) setHasBase(true)
                    if (data.some(element => element.hp !== 0)) setHasHp(true)
                    if (data.some(element => element.hc !== 0)) setHasHc(true)
                    if (data.some(element => element.pointe !== 0)) setHasPointe(true)
                    if (data.some(element => element.pm !== 0)) setHasPm(true)
                    if (data.some(element => element.hph !== 0)) setHasHph(true)
                    if (data.some(element => element.hch !== 0)) setHasHch(true)
                    if (data.some(element => element.hpe !== 0)) setHasHpe(true)
                    if (data.some(element => element.hce !== 0)) setHasHce(true)
                })
                .catch(error => {
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
                .finally(() => setIsGraphDataLoading(false))
        }
    }, [dropdownValue])

    const getCDCInfo = useCallback(() => {
        if (dropdownReleveType === "CDC") {
            axios.get(`${apiUrl}/Conso/Graph/${dropdownValue}/CDC/Mensuel/Info`,
                {
                    headers: {
                        'Accept': 'text/plain',
                    },
                    withCredentials: true,
                })
                .then((response) => {
                    if (response.data?.filtres?.annees?.length > 0) {
                        setYearQuery(response.data.filtres.annees[0])
                    }
                })
                .catch(error => {
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
        }
    }, [dropdownValue, dropdownReleveType])

    const getCDCGraphData = useCallback(() => {
        if (yearQuery && dropdownReleveType === "CDC") {
            axios.get(`${apiUrl}/Conso/Graph/${dropdownValue}/CDC/Mensuel/Data?annee=${yearQuery}&mois=janvier&mois=fevrier&mois=mars&mois=avril&mois=mai&mois=juin&mois=juillet&mois=aout&mois=septembre&mois=octobre&mois=novembre&mois=decembre`,
                {
                    headers: {
                        'Accept': 'text/plain',
                    },
                    withCredentials: true,
                })
                .then((response) => {
                    const graphData = []
                    monthsOptions.forEach(element => {
                        graphData.push(element)
                    })
                    response.data.forEach((year => {
                        year.consommationParMois.forEach((element) => {
                            graphData.forEach(line => {
                                if (element.mois === line.moisNombre) {
                                    line[`energie${year.annee}`] = element["energie"]
                                    line[`euros${year.annee}`] = element["euros"]
                                }
                            })
                        })
                    }))
                    setGraphData(graphData)
                })
                .catch(error => {
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
                .finally(() => setIsGraphDataLoading(false))
        }
    }, [yearQuery, dropdownValue, dropdownReleveType])

    const getGraphCumul = useCallback(() => {
        if (dropdownValue) {
            setIsGraphDataLoading(true)
            axios.get(`${apiUrl}/Conso/Graph/Mensuel`,
                {
                    headers: {
                        'Accept': 'text/plain',
                    },
                    withCredentials: true,
                })
                .then((response) => {
                    let data = [...response.data]
                    data.forEach(element => {
                        let year = new Date(element.date).getYear().toString()
                        if (year[0] === "1" && year.length === 3) year = year.slice(1)
                        element.yearLabel = `${monthsOptions[new Date(element.date).getMonth()].moisLabel} ${year ? year : ""}`
                    })
                    setGraphData(data)
                })
                .catch(error => {
                    if (error?.response?.status === 401) {
                        window.location.reload()
                    }
                })
                .finally(() => setIsGraphDataLoading(false))
        }
    }, [dropdownValue])

    const determineGraphType = useCallback(() => {
        if (dropdownReleveType === "Cumul") {
            getGraphCumul()
        }
        else if (dropdownReleveType === "Mensuel") {
            getGraphData()
        }
        else {
            getCDCInfo()
        }

    }, [dropdownReleveType, getGraphCumul, getGraphData, getCDCInfo])

    useEffect(() => {
        getSitesElec()
        getSitesGaz()
        checkIsClient()
    }, [getSitesGaz, getSitesElec, checkIsClient])

    useEffect(() => {
        determineGraphType()
    }, [determineGraphType, dropdownValue])

    useEffect(() => {
        getCDCGraphData()
    }, [getCDCGraphData, yearQuery])

    return (
        <>
            <SideBar active="Dashboard" />
            <div className="homepage--container privatepages--container">
                {isDataLoading ?
                    <span className="global--loader"></span>
                    :
                    <>
                        <div className="dashboard--header__container">
                            <span className="dashboard--header__name">Bonjour{firstName ? ` ${firstName}` : ""} -</span> Bienvenue sur votre espace client WattValue
                            <div className="addsites--choosetype__switchcontainer dashboard--header__switchcontainer dashboard--header__switch">
                                <span className={`addsites--choosetype__energy ${isGaz ? "addsites--choosetype__energyactive" : ""}`}>Gaz</span>
                                <Switch
                                    sx={{
                                        margin: '0 12px',
                                        '--Switch-thumbSize': '19px',
                                        '--Switch-trackWidth': '45px',
                                        '--Switch-trackHeight': '21px',
                                        '--Switch-trackBackground': '#EFEFEF',
                                        '--Switch-thumbBackground': '#48AB4D',
                                        '&:hover': {
                                            '--Switch-trackBackground': '#EFEFEF',
                                        },
                                        [`&.${switchClasses.checked}`]: {
                                            '--Switch-thumbBackground': '#48AB4D',
                                            '--Switch-trackBackground': '#EFEFEF',
                                            '&:hover': {
                                                '--Switch-trackBackground': '#EFEFEF',
                                            },
                                        },
                                    }}
                                    checked={isElec}
                                    disabled={searchParams.get('id') && searchParams.get('id').length > 0}
                                    onChange={() => {
                                        if (isElec) {
                                            setIsElec(false)
                                            setIsGaz(true)
                                        }
                                        else {
                                            setIsElec(true)
                                            setIsGaz(false)
                                        }
                                    }} />
                                <span className={`addsites--choosetype__energy ${isElec ? "addsites--choosetype__energyactive" : ""}`}>Électricité</span>
                            </div>
                        </div>

                        {(sitesElec.length === 0 && isElec) && <div className="table--tablecontainer mandates--block__container">
                            <p className="dashboard--emptystate">Votre compte ne contient pas encore de site électricité, vous pouvez commencer à créer votre site en cliquant sur le bouton ci-dessous.</p>
                            <Button
                                cta="Créer votre site"
                                additionnalClassName="dashboard--button"
                                link={`/add-sites-1?type=elec`}
                            />
                        </div>}

                        {(sitesGaz.length === 0 && isGaz) && <div className="table--tablecontainer mandates--block__container">
                            <p className="dashboard--emptystate">Votre compte ne contient pas encore de site gaz, vous pouvez commencer à créer votre site en cliquant sur le bouton ci-dessous.</p>
                            <Button
                                cta="Créer votre site"
                                additionnalClassName="dashboard--button"
                                link={`/add-sites-1?type=gaz`}
                            />
                        </div>}

                        {((sitesElec.length > 0 && isElec) || (sitesGaz.length > 0 && isGaz)) &&
                            <>
                                <div className="dashboard--indicators__top">
                                    <div className="dashboard--indicators__left dashboard--indicators__blockcontainer">
                                        {isElec &&
                                            <div className="dashboard--indicators__blockcontainer dashboard--indicators__blockcontainerup dashboard--indicators__blockcontainerleft dashboard--indicators__blockleft">
                                                <div className="dashboard--indicators dashboard--indicators__block dashboard--indicators__blockleft dashboard--indicators__blockleftinside dashboard--indicators__blocklarge">
                                                    <div className="dashboard--positionrelative">
                                                        <ToolTip
                                                            text={"Source : relèves du gestionnaire de réseau d'électricité."}
                                                            type="headcell"
                                                            marginTop="0"
                                                            top={20}
                                                            left={20}>
                                                            <span className="dashboard--indicators__blocktitle">
                                                                Cumul électricité consommée
                                                                <InfoOutlinedIcon sx={{ fontSize: "18px", padding: "0 0 0 5px" }} />
                                                            </span>
                                                        </ToolTip>
                                                        {(!isConsoLoading && consoDebut && consoFin) ? <span className="dashboard--indicators__blocksubtitle">du {new Date(consoDebut).toLocaleDateString()} au {new Date(consoFin).toLocaleDateString()}</span> : ""}
                                                    </div>

                                                    <div className="dashboard--number__container">
                                                        {isConsoLoading ?
                                                            <div className="dashboard--indicators__blocksubtitle dashboard--indicators__loadercontainer">Calcul en cours<div className="global--loadersmall"></div></div>
                                                            :
                                                            <>
                                                                <span className="dashboard--indicators__blocknumber">{new Intl.NumberFormat('fr-FR').format(Math.round(conso))} kWh</span>
                                                                {!isNaN((conso - consoPrecedente) / consoPrecedente * 100) && <div className={`dashboard--number ${((conso - consoPrecedente) < 0) ? "dashboard--number__down" : "dashboard--number__up"}`}>{Math.round((conso - consoPrecedente) / consoPrecedente * 100)}%{((conso - consoPrecedente) < 0) ? <SouthIcon sx={{ color: "#48AB4D", height: 15 }} /> : <NorthIcon sx={{ color: "#FF0000", height: 15 }} />}
                                                                </div>}
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="dashboard--indicators dashboard--indicators__block dashboard--indicators__blocksmall">
                                                    <div className="dashboard--positionrelative">
                                                        <ToolTip
                                                            text={"Pourcentage de la consommation annuelle de tous vos sites actifs couvert par des Garanties d'Origine."}
                                                            type="headcell"
                                                            marginTop="0"
                                                            top={31}
                                                            left={-16}>
                                                            <span className="dashboard--indicators__blocktitle">
                                                                Electricité verte
                                                                <InfoOutlinedIcon sx={{ fontSize: "18px", padding: "0 0 0 5px" }} />
                                                            </span>
                                                        </ToolTip>
                                                    </div>

                                                    <div className="dashboard--number__container">
                                                        <div className="dashboard--indicators__blocknumber">
                                                            <img src={Stats} alt="Stats icon" width={19} height={19} className="dashboard--indicators__blockicon" />{Math.round(goPercent)}%
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="dashboard--indicators__blockcontainerright dashboard--indicators__blockcontainer">
                                            <div className="dashboard--indicators dashboard--indicators__block dashboard--indicators__blockfull">
                                                <div className="dashboard--positionrelative">
                                                    <ToolTip
                                                        text={"Nombre de compteur ayant un contrat de fourniture d'électricité en cours négocié par WattValue."}
                                                        type="headcell"
                                                        marginTop="0"
                                                        top={31}
                                                        left={16}>
                                                        <span className="dashboard--indicators__blocktitle">
                                                            Nombre de compteurs actifs
                                                            <InfoOutlinedIcon sx={{ fontSize: "18px", padding: "0 0 0 5px" }} />
                                                        </span>
                                                    </ToolTip>
                                                    <span className="dashboard--indicators__blocksubtitle">{isElec ? "Électrique" : "Gaz"}</span>
                                                </div>

                                                <div className="dashboard--indicators__blocknumber dashboard--indicators__blockmargintop6">
                                                    <img src={isElec ? Elec : Gaz} alt="Electrique" width={19} height={19} className="dashboard--indicators__blockicon" />{isElec ? prmNumber : pceNumber}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {(nextAppelOffreElec || nextAppelOffreGaz) ?
                                        <div className="dashboard--indicators__right">
                                            <div className="table--tablecontainer mandates--block__container dashboard--indicators__righttop">
                                                <div className="dashboard--positionrelative">
                                                    <ToolTip
                                                        text={"Inscrivez un nouveau site, ou allez voir dans Mes Appels d'Offres si vos sites peuvent être réengagés dans une prochaine négociation."}
                                                        type="headcell"
                                                        marginTop="0"
                                                        top={31}
                                                        left={60}>
                                                        <span className="dashboard--indicators__blocktitle">
                                                            Prochains appels d'offres
                                                            <InfoOutlinedIcon sx={{ fontSize: "18px", padding: "0 0 0 5px" }} />
                                                        </span>
                                                    </ToolTip>
                                                    <span className="dashboard--indicators__blocksubtitle">Date limite d'inscription</span>
                                                </div>

                                                {nextAppelOffreElec ? <div className="dashboard--indicators__blockcontainerappeloffre dashboard--addsite__container">
                                                    <div className="dashboard--indicators__blockcontainerappeloffre dashboard--addsite__date">
                                                        <img alt="Appel d'offres électricité" src={Elec} width={13} height={13} className="dashboard--indicators__blockicon" />
                                                        {new Date(nextAppelOffreElec).toLocaleDateString()}
                                                    </div>
                                                    <Link to='/add-sites-1?type=elec'><span className="dashboard--addsite">Inscrire un site +</span></Link>
                                                </div> : ""}
                                                {nextAppelOffreGaz ? <div className="dashboard--indicators__blockcontainerappeloffre dashboard--addsite__container">
                                                    <div className="dashboard--indicators__blockcontainerappeloffre dashboard--addsite__date">
                                                        <img alt="Appel d'offres gaz" src={Gaz} width={13} height={13} className="dashboard--indicators__blockicon" />
                                                        {new Date(nextAppelOffreGaz).toLocaleDateString()}
                                                    </div>
                                                    <Link to='/add-sites-1?type=gaz'><span className="dashboard--addsite">Inscrire un site +</span></Link>
                                                </div> : ""}

                                            </div>
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                                {isElec &&
                                    <div className="dashboard--indicators__top dashboard--conso__container">
                                        <div className="dashboard--indicators__left dashboard--indicators__blockcontainer dashboard--conso__top dashboard--graph__container">
                                            <div className="table--tablecontainer mandates--block__container dashboard--indicators__righttop">
                                                <div className="dashboard--conso__header dashboard--conso__headercontainer">
                                                    <div className="dashboard--conso__title">
                                                        <span className="privatepages--title">Consommation en électricité sur les 12 derniers mois</span>
                                                        <span className="dashboard--indicators__blocksubtitle">Relevé estimatif par mois</span>
                                                    </div>
                                                    {isGraphDataLoading ?
                                                        ""
                                                        :
                                                        graphData.length > 0 ?
                                                            <div className="dashboard--conso__header">
                                                                {(dropdownSubscriptionType === "Souscrire" || dropdownSubscriptionType === "Renouveler") &&
                                                                    <Button
                                                                        cta="Activer la courbe de charge"
                                                                        additionnalClassName="dashboard--conso__button  dashboard--marginright10"
                                                                        link={`/${dropdownSubscriptionType === "Souscrire" ? "subscription" : "renouvellement"}?siteId=${dropdownValue}`} />
                                                                }
                                                                <FormInput
                                                                    type="dropdown"
                                                                    id="site"
                                                                    placeholder="Choisir un site"
                                                                    onChangeInput={e => {
                                                                        setDropdownValue(e.value)
                                                                        setDropdownLabel(e.label)
                                                                        setDropdownReleveType(e.releveType)
                                                                        setDropdownSubscriptionType(e.souscriptionType)
                                                                    }}
                                                                    value={dropdownLabel}
                                                                    isSearchable
                                                                    options={dropdownOptions}
                                                                />
                                                            </div>
                                                            :
                                                            ""
                                                    }
                                                </div>
                                                {isGraphDataLoading ?
                                                    <span className="global--loader"></span>
                                                    :
                                                    graphData.length > 0 ?
                                                        <>
                                                            {dropdownReleveType === "Mensuel" ?
                                                                <div className="dashboard--legend__container">
                                                                    {hasPointe ? <div className="conso--legend"><div className="conso--legend__mark conso--legend__mark1"></div> Pointe</div> : ""}
                                                                    {hasPm ? <GreyTooltip title="Pointe mobile"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark1"></div> PM</div></GreyTooltip> : ""}
                                                                    {hasHph ? <GreyTooltip title="Heure Pleine Hiver"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark2"></div> HPH</div></GreyTooltip> : ""}
                                                                    {hasHch ? <GreyTooltip title="Heure Creuse Hiver"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark3"></div> HCH</div></GreyTooltip> : ""}
                                                                    {hasHpe ? <GreyTooltip title="Heure Pleine Eté"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark4"></div> HPE</div></GreyTooltip> : ""}
                                                                    {hasHce ? <GreyTooltip title="Heure Creuse Hiver"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark5"></div> HCE</div></GreyTooltip> : ""}
                                                                    {hasBase ? <div className="conso--legend"><div className="conso--legend__mark conso--legend__mark1"></div> Base</div> : ""}
                                                                    {hasHp ? <GreyTooltip title="Heure Pleine"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark2"></div> HP</div></GreyTooltip> : ""}
                                                                    {hasHc ? <GreyTooltip title="Heure Creuse"><div className="conso--legend"><div className="conso--legend__mark conso--legend__mark3"></div> HC</div></GreyTooltip> : ""}
                                                                </div>
                                                                :
                                                                ""
                                                            }
                                                            {dropdownReleveType === "CDC" ?
                                                                <div className="dashboard--legend__container">
                                                                    <div className="conso--legend"><div className="conso--legend__mark conso--legend__markenergy" style={{ backgroundColor: lineColorsEnergy[yearQuery % lineColorsEnergy.length] }}></div> {`Energie ${yearQuery}`}</div>
                                                                    <div className="conso--legend"><div className="conso--legend__mark conso--legend__markenergy" style={{ backgroundColor: lineColorsEuros[yearQuery % lineColorsEuros.length] }}></div> {`Euro ${yearQuery}`}</div>
                                                                </div>
                                                                :
                                                                ""
                                                            }
                                                            {dropdownReleveType === "Cumul" ?
                                                                <div className="dashboard--legend__container">
                                                                    <div className="conso--legend"><div className="conso--legend__mark conso--legend__markenergy" style={{ backgroundColor: "#47ac63" }}></div> {`Consommation`}</div>
                                                                </div>
                                                                :
                                                                ""
                                                            }
                                                            <div className="conso--chart__container">
                                                                <ResponsiveContainer>
                                                                    {(dropdownReleveType === "Mensuel" || dropdownReleveType === "Cumul") ?
                                                                        <BarChart data={graphData} >
                                                                            <CartesianGrid strokeDasharray="2 2" />
                                                                            <XAxis
                                                                                dataKey="yearLabel"
                                                                                style={{
                                                                                    fontSize: '11px',
                                                                                    fontFamily: 'Plus Jakarta Sans',
                                                                                    color: '#52525B'
                                                                                }} />
                                                                            <YAxis
                                                                                style={{
                                                                                    fontSize: '11px',
                                                                                    fontFamily: 'Plus Jakarta Sans',
                                                                                    color: '#52525B'
                                                                                }}>
                                                                                <Label
                                                                                    value="kWh"
                                                                                    position="insideTopLeft"
                                                                                    offset={10}
                                                                                    dx={-15}
                                                                                    dy={10}
                                                                                    style={{
                                                                                        fontSize: '11px',
                                                                                        fontFamily: 'Plus Jakarta Sans',
                                                                                        color: '#52525B'
                                                                                    }}
                                                                                />
                                                                            </YAxis>
                                                                            <Tooltip content={<ConsoCustomTooltip datakey="yearLabel" />} cursor={{ fill: '#EFEFEF' }} />
                                                                            {dropdownReleveType === "Cumul" ?
                                                                                <Bar dataKey="consommation" stackId="a" fill="#47ac63" unit="kWh" />
                                                                                :
                                                                                <>
                                                                                    <Bar dataKey="pointe" stackId="a" fill="#0a6273" unit="kWh" />
                                                                                    <Bar dataKey="base" stackId="a" fill="#0a6273" unit="kWh" />
                                                                                    <Bar dataKey="pm" stackId="a" fill="#0a6273" unit="kWh" />
                                                                                    <Bar dataKey="hph" stackId="a" fill="#007c7e" unit="kWh" />
                                                                                    <Bar dataKey="hp" stackId="a" fill="#007c7e" unit="kWh" />
                                                                                    <Bar dataKey="hch" stackId="a" fill="#009578" unit="kWh" />
                                                                                    <Bar dataKey="hc" stackId="a" fill="#009578" unit="kWh" />
                                                                                    <Bar dataKey="hpe" stackId="a" fill="#47ac63" unit="kWh" />
                                                                                    <Bar dataKey="hce" stackId="a" fill="#8fbe44" unit="kWh" /></>
                                                                            }
                                                                        </BarChart>
                                                                        :
                                                                        <LineChart data={graphData}>
                                                                            <CartesianGrid strokeDasharray="2 2" />
                                                                            <XAxis
                                                                                dataKey="moisLabel"
                                                                                style={{
                                                                                    fontSize: '11px',
                                                                                    fontFamily: 'Plus Jakarta Sans',
                                                                                    color: '#52525B'
                                                                                }} />
                                                                            <YAxis
                                                                                yAxisId="left"
                                                                                style={{
                                                                                    fontSize: '11px',
                                                                                    fontFamily: 'Plus Jakarta Sans',
                                                                                    color: '#52525B'
                                                                                }}>
                                                                                <Label
                                                                                    value="kWh"
                                                                                    position="insideTopLeft"
                                                                                    offset={10}
                                                                                    dx={-15}
                                                                                    dy={10}
                                                                                    style={{
                                                                                        fontSize: '11px',
                                                                                        fontFamily: 'Plus Jakarta Sans',
                                                                                        color: '#52525B'
                                                                                    }}
                                                                                />
                                                                            </YAxis>
                                                                            <YAxis
                                                                                yAxisId="right"
                                                                                orientation="right"
                                                                                style={{
                                                                                    fontSize: '11px',
                                                                                    fontFamily: 'Plus Jakarta Sans',
                                                                                    color: '#52525B'
                                                                                }}>
                                                                                <Label
                                                                                    value="Euros"
                                                                                    position="insideTopLeft"
                                                                                    offset={10}
                                                                                    dx={10}
                                                                                    dy={10}
                                                                                    style={{
                                                                                        fontSize: '11px',
                                                                                        fontFamily: 'Plus Jakarta Sans',
                                                                                        color: '#52525B'
                                                                                    }}
                                                                                />
                                                                            </YAxis>
                                                                            <Tooltip content={<ConsoCustomTooltip datakey="moisLabel" />} cursor={{ fill: '#EFEFEF' }} />
                                                                            <Fragment>
                                                                                <Line strokeWidth={2} yAxisId="left" type="monotone" unit="kWh" name={`${yearQuery}`} dataKey={`energie${yearQuery}`} stroke={lineColorsEnergy[yearQuery % lineColorsEnergy.length]} />
                                                                                <Line strokeWidth={2} yAxisId="right" type="monotone" unit="€" name={`${yearQuery}`} dataKey={`euros${yearQuery}`} stroke={lineColorsEuros[yearQuery % lineColorsEuros.length]} />
                                                                            </Fragment>
                                                                        </LineChart>
                                                                    }
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </>
                                                        :
                                                        <span>Aucune donnée de consommation trouvée</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="dashboard--indicators__right dashboard--conso__bottom">
                                            <div className="table--tablecontainer mandates--block__container dashboard--indicators__righttop dashboard--paddingbottom54">
                                                <span className="privatepages--title">Mes derniers appels d'offres</span>
                                                {appelsOffreElec.map((appel, index) => {
                                                    const resultNone = appel.sites.every(element => element.resultType === "None")

                                                    if (index < 3) {
                                                        return (
                                                            <div key={`appeloffres-elec-${index}`} className="dashboard--appeloffres__container">
                                                                <div className="dashboard--appeloffres__info">
                                                                    <img alt="Appel d'offres" src={AppelOffres} width={35} height={35} className="dashboard--appeloffres__icon" />
                                                                    <div>
                                                                        <div className="dashboard--appeloffres__title">Appel d'offres n°{appel.appelOffresID}</div>
                                                                        {(appel.calendrier?.datePrix && appel.calendrier.datePrix !== "0001-01-01T00:00:00") ?
                                                                            <span className="dashboard--appeloffres__subtitle">{new Date(appel.calendrier.datePrix).toLocaleDateString()}</span>
                                                                            :
                                                                            ""
                                                                        }
                                                                        <span className="dashboard--appeloffres__subtitle">{appel.libelle}</span>
                                                                    </div>
                                                                </div>
                                                                {!resultNone && <Link to={`/appels-offre-${isGaz ? "gaz" : "elec"}`}><span className="dashboard--appeloffres__result">Voir le résultat</span></Link>}
                                                            </div>
                                                        )
                                                    }
                                                    return ""
                                                })}
                                                <Link to={'/appels-offre-elec'}><span className="dashboard--indicators__blocktitle dashboard--seemore">{`Voir tous les Appels d'offres >`}</span></Link>
                                            </div>
                                        </div>
                                    </div>}
                                <div className="dashboard--indicators__top">
                                    <div className={`table--tablecontainer mandates--block__container dashboard--paddingbottom54 ${isGaz ? "dashboard--indicators__left" : ""}`}>
                                        <span className="privatepages--title">Périmètre complet</span>
                                        <SitesTable
                                            rows={isGaz ? sitesGaz : sitesElec}
                                            page={page}
                                            setPage={setPage}
                                            isGaz={isGaz}
                                            isDashboard
                                        />
                                        <Link to={`/${isGaz ? "gaz" : "elec"}-sites`}><span className="dashboard--indicators__blocktitle dashboard--seemore">{`Voir tous les sites >`}</span></Link>
                                    </div>
                                    {isGaz && <div className="table--tablecontainer mandates--block__container dashboard--indicators__righttop dashboard--indicators__right dashboard--paddingbottom54">
                                        <span className="privatepages--title">Mes derniers appels d'offres</span>
                                        {appelsOffreGaz.map((appel, index) => {
                                            const resultNone = appel.sites.every(element => element.resultType === "None")

                                            if (index < 3) {
                                                return (
                                                    <div key={`appeloffres-gaz-${index}`} className="dashboard--appeloffres__container">
                                                        <div className="dashboard--appeloffres__info">
                                                            <img alt="Appel d'offres" src={AppelOffres} width={35} height={35} className="dashboard--appeloffres__icon" />
                                                            <div>
                                                                <div className="dashboard--appeloffres__title">Appel d'offres n°{appel.appelOffresID}</div>
                                                                {(appel.calendrier?.datePrixDefinitif && appel.calendrier.datePrixDefinitif !== "0001-01-01T00:00:00") ?
                                                                    <span className="dashboard--appeloffres__subtitle">{new Date(appel.calendrier.datePrixDefinitif).toLocaleDateString()}</span>
                                                                    :
                                                                    ""
                                                                }
                                                                <span className="dashboard--appeloffres__subtitle">{appel.libelle}</span>
                                                            </div>
                                                        </div>
                                                        {!resultNone && <Link to={`/appels-offre-${isGaz ? "gaz" : "elec"}`}><span className="dashboard--appeloffres__result">Voir le résultat</span></Link>}
                                                    </div>
                                                )
                                            }
                                            return ""
                                        })}
                                        <Link to={`/appels-offre-${isGaz ? "gaz" : "elec"}`}><span className="dashboard--indicators__blocktitle dashboard--seemore">{`Voir tous les Appels d'offres >`}</span></Link>
                                    </div>}
                                </div>
                            </>
                        }
                    </>}
            </div>
        </>
    )
}

export default Dashboard;